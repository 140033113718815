import { useEffect, useState } from "react"
import * as multiChainService from "../blockchain/services/multichain.service"
// import { useSelector } from "react-redux"

export const useTokenMarketCap = () => {

  const [tokenMarketCap, setTokenMarketCap] = useState(0.0)
  const [isLoading, setLoading] = useState(false)

  const currentChain = localStorage.getItem('ever-earn-chain')
        ? localStorage.getItem('ever-earn-chain') : "bsc"
  // const { currentChain } = useSelector((state) => state.chainSelector)

  useEffect(() => {
    async function fetchData() {
    try {
      setLoading(true)
      //get the token main token price in usd
      const tokenMarketCapInUSD = await multiChainService.getTokenMarketCapIUnUSDByTokenAddress(currentChain)
      if (tokenMarketCapInUSD) {
        setTokenMarketCap(tokenMarketCapInUSD)
      } else {
        setTokenMarketCap(0.0)
      }
    } catch (error) {
      setTokenMarketCap(0.0)
      console.error('error while fetching token market cap ', error.message || '')
    } finally {
      setLoading(false)
    }
    }
    fetchData();

  }, [currentChain])


  return { tokenMarketCap, isLoading }
}

export default useTokenMarketCap

