import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Input } from 'antd'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import useUserTokenInfo from '../../hooks/useUserTokenInfo'
import "./Calculator.css"
import { RedoOutlined } from '@ant-design/icons';

function Calculator() {

    const { account } = useWeb3React()
    const [dailyVolumeInUSD, setDailyVolumeInUSD] = useState(0.00)
    const [isVolumeLoading, setIsVolumeLoading] = useState(false)
    const [userTokenAmount, setUserTokenAmount] = useState(0.00)
    const [estimatedDailyEarning, setEstimatedDailyEarning] = useState(0.00)
    const [estimatedWeeklyEarning, setEstimatedWeeklyEarning] = useState(0.00)
    const [estimatedMonthlyEarning, setEstimatedMonthlyEarning] = useState(0.00)
    const [estimatedYearlyEarning, setEstimatedYearlyEarning] = useState(0.00)

    const rewardTrackerTotalSupply = 39 * (10 ** 9)
    const rewardFee = 11 / 100
    const {
        tokenHoldings,
        isLoading: userTokenInfoLoading,
    } = useUserTokenInfo({ userWalletAddress: account })

    const getDailyVolume = async () => {
        setIsVolumeLoading(true)
        const endpoint = `${process.env.REACT_APP_COINGECKO_ENDPOINT}coins/everearn/market_chart?vs_currency=usd&days=1&interval=daily`
        const response = await axios.get(endpoint)
        if (response && response.status === 200) {
            const data = response.data
            const volume = data.total_volumes[0][1]
            setDailyVolumeInUSD(volume ? parseFloat(volume).toFixed(2) : 0.00)
        } else {
            setDailyVolumeInUSD(0.00)
        }
        setIsVolumeLoading(false)
    }
    useEffect(() => {
        getDailyVolume()
    }, [])

    useEffect(() => {
        if (tokenHoldings) {
            setUserTokenAmount(tokenHoldings)
        } else {
            setUserTokenAmount(0.00)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenHoldings])

    useEffect(() => {

        const dailyIncome = (userTokenAmount / rewardTrackerTotalSupply) * (dailyVolumeInUSD * rewardFee)
        const weeklyIncome = (userTokenAmount / rewardTrackerTotalSupply) * (dailyVolumeInUSD * rewardFee) * 7
        const monthlyIncome = (userTokenAmount / rewardTrackerTotalSupply) * (dailyVolumeInUSD * rewardFee) * 30
        const yearlyIncome = (userTokenAmount / rewardTrackerTotalSupply) * (dailyVolumeInUSD * rewardFee) * 365

        setEstimatedDailyEarning(dailyIncome)
        setEstimatedWeeklyEarning(weeklyIncome)
        setEstimatedMonthlyEarning(monthlyIncome)
        setEstimatedYearlyEarning(yearlyIncome)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyVolumeInUSD, userTokenAmount])


    const handleRefreshVolume = () => {
        getDailyVolume()
    }

    return (
        <div className="container p-4">
            <Row>
                <Col sm='12' md='12' lg='12' className="text-start">
                    <h4 className="text-light">Estimated Inputs</h4>


                    <Row>
                        <Col sm="6" md="6" lg="6" className='mb-3'>
                            <div className="ever-earn-card-bg mt-4 p-4">
                                <div className="row">
                                    <h6 className="text-muted">Daily Volume</h6>
                                </div>

                                <Input
                                    onChange={e => setDailyVolumeInUSD(e.target.value)}
                                    value={dailyVolumeInUSD ? dailyVolumeInUSD : 0.00}
                                    disabled={isVolumeLoading}
                                    suffix="USD"
                                    defaultValue="0"
                                    className="nft-input" />

                                {
                                    dailyVolumeInUSD == 0 ? (
                                        <div className='d-flex daily-volume-refresh-container'>
                                            <div className='daily-volume-help-text'>
                                                Daily volume set to zero click refresh button to pull latest data
                                            </div>

                                            <div className='daily-volume-refresh-button' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleRefreshVolume}>
                                                <RedoOutlined style={{ color: '#ffad25' }} />
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }


                            </div>


                        </Col>

                        <Col sm="6" md="6" lg="6" className='mb-3'>
                            <div className="ever-earn-card-bg mt-4 p-4">
                                <div className="row">
                                    <h6 className="text-muted">Token Amount</h6>
                                </div>

                                <Input
                                    onChange={e => setUserTokenAmount(e.target.value)}
                                    suffix="EARN"
                                    disabled={userTokenInfoLoading}
                                    value={userTokenAmount}
                                    defaultValue="0"
                                    className='nft-input' />
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col sm='12' md='12' lg='12' className="text-start">

                    <h4 className="text-light mt-5">Estimated Inputs</h4>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div className="ever-earn-card-bg mt-3 text-center">
                                <div className="row mt-3">
                                    <h6 className="text-muted">ESTIMATED DAILY EARNING</h6>
                                </div>

                                <div className="row mt-3">
                                    <h6 className="text-light-green">
                                        <NumberFormat
                                            value={estimatedDailyEarning ? estimatedDailyEarning : 0.00}
                                            displayType="text"
                                            decimalScale={4}
                                            thousandSeparator={true}
                                            prefix="~$"
                                        />
                                    </h6>
                                </div>
                            </div>
                        </Col>

                        <Col sm="6" md="6" lg="6">
                            <div className="ever-earn-card-bg mt-3 text-center">
                                <div className="row mt-3">
                                    <h6 className="text-muted">ESTIMATED WEEKLY EARNING</h6>
                                </div>

                                <div className="row mt-3">
                                    <h6 className="text-light-green">
                                        <NumberFormat
                                            value={estimatedWeeklyEarning ? estimatedWeeklyEarning : 0.00}
                                            displayType="text"
                                            decimalScale={4}
                                            thousandSeparator={true}
                                            prefix="~$"
                                        />
                                    </h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div className="ever-earn-card-bg mt-3 text-center">
                                <div className="row mt-3">
                                    <h6 className="text-muted">ESTIMATED MONTHLY EARNING</h6>
                                </div>

                                <div className="row mt-3">
                                    <h6 className="text-light-green">
                                        <NumberFormat
                                            value={estimatedMonthlyEarning ? estimatedMonthlyEarning : 0.00}
                                            displayType="text"
                                            decimalScale={4}
                                            thousandSeparator={true}
                                            prefix="~$"
                                        />
                                    </h6>
                                </div>
                            </div>
                        </Col>

                        <Col sm="6" md="6" lg="6">
                            <div className="ever-earn-card-bg mt-3 text-center">
                                <div className="row mt-3">
                                    <h6 className="text-muted">ESTIMATED YEARLY EARNING</h6>
                                </div>

                                <div className="row mt-3">
                                    <h6 className="text-light-green">
                                        <NumberFormat
                                            value={estimatedYearlyEarning ? estimatedYearlyEarning : 0.00}
                                            displayType="text"
                                            decimalScale={4}
                                            thousandSeparator={true}
                                            prefix="~$"
                                        />
                                    </h6>
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}

export default Calculator
