/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
// import * as multiChainService from "../blockchain/services/multichain.service"
import * as web3Service from "../blockchain/web3.service"

export const useTokenLiquidityData = (props) => {

  const { contractAddress } = props
  const [liquidityInfo, setLiquidityInfo] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const { currentChain } = useSelector((state) => state.chainSelector)

  useEffect( () => {

    async function fetchData() {
      try {
        setLoading(true)
        const tokenLiquidityResponse = await web3Service.calculateLiquidityInfoByContractAddress(contractAddress, currentChain)
        setLiquidityInfo(tokenLiquidityResponse)
      } catch (error) {
        setLiquidityInfo(null)
        console.error('error while calculation token liquidity supply ', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData();
    

  }, [])
  return { liquidityInfo, isLoading }
}

export default useTokenLiquidityData

