/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { Image, Button, Spin } from 'antd';
import './NFTTracker.css'
import { useMoralis } from 'react-moralis';
import { configs } from '../../blockchain/web3.config';
import UserIdenticon from './UserIdenticon';
import { getEllipsisTxt } from '../../Helpers/Formatters';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons'
import defaultPreviewImage from '../../images/nft-preview-placeholder.png'
import { getFileTypeFromURI } from '../../Helpers/fileTypeDecoder'
import playButtonImg from '../../images/play-button.png'
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const NFTContainer = (props) => {

  const { data } = props
  const { Moralis, isInitialized } = useMoralis()
  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [isNftImageLoading, setIsNftImageLoading] = useState(false)
  const [nftTokenId, setNftTokenId] = useState('~')
  const [nftTitle, setNftTitle] = useState('~')
  const [nftImage, setNftImage] = useState('')
  const [fileType, setFileType] = useState('')
  const [nftImageWithFiletype, setNftImageWithFiletype] = useState('')
  // const [imagePreview, setImagePreview] = useState(true)
  const [imageHeight, setImageHeight] = useState(300)

  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate("/nft-details", { replace: true, state: { data } });
  }

  useEffect(() => {
    async function fetchData() {
      
      const { nftContractConfig } = configs
      if (isInitialized) {
        setIsNftImageLoading(true)
        const options = { address: data.token_address, token_id: data.token_id, chain: nftContractConfig.moralisChainId }
        const tokenIdMetadata = await Moralis.Web3API.token.getTokenIdMetadata(options)
        setNftTokenId(tokenIdMetadata.token_id)
        if (tokenIdMetadata.metadata) {
          const metadata = JSON.parse(tokenIdMetadata.metadata)
          let processedImageUrl = ''
          if (metadata.image && metadata.image.includes('ipfs://')) {
            const imageUrl = metadata.image.split('ipfs://')
            processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`
          } else {
            processedImageUrl = metadata.image
          }
          setNftImage(processedImageUrl)
          setNftTitle(metadata.name)
          setIsNftImageLoading(false)
        } else {
          setIsNftImageLoading(true)
          if (data.token_uri) {
            const correctedEndpoint = data.token_uri.replace('https://ipfs.moralis.io:2053/', 'https://gateway.moralisipfs.com/')
            const response = await axios.get(correctedEndpoint)
            if (response.status === 200) {
              const metadata = response.data
              let processedImageUrl = ''
              if (metadata.image && metadata.image.includes('ipfs://')) {
                const imageUrl = metadata.image.split('ipfs://')
                processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`
              } else {
                processedImageUrl = metadata.image
              }
              setNftImage(processedImageUrl)
              setNftTitle(metadata.name)
            }
          } else {
            setNftImage('')
            setNftTitle('~')

          }
          setIsNftImageLoading(false)
        }
      }

    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized])

  useEffect( () => {
    async function fetchData() {
      if (nftImage) {
        const filetype = await getFileTypeFromURI(nftImage)
        setFileType(filetype)
      } else {
        setFileType('')
      }
    }
    fetchData();
    
  }, [nftImage])

  useEffect( () => {
    async function fetchData() {
      if (fileType) {
        if (fileType.includes('image')) {
          setNftImageWithFiletype(nftImage)
          //setImagePreview(true)
          setImageHeight(300)
        }
        if (fileType.includes('video')) {
          setNftImageWithFiletype(playButtonImg)
          //setImagePreview(false)
          setImageHeight(100)
        }
      }
    }
    fetchData();
    
  }, [fileType])

  const provideAssetContainer = (assetURI, assetType) => {
    if (fileType.includes('image')) {
      return (
        <Image
          onClick={handleViewDetails}
          src={nftImageWithFiletype}
          style={{ objectFit: 'cover' }}
          height={imageHeight}
          preview={false}
          fallback={defaultPreviewImage}
        />)
    }
    if (fileType.includes('video')) {
      return (
        <div className='video-container' onClick={handleViewDetails}>
          <ReactPlayer
            className='react-player'
            playing={false}
            width='100%'
            height='100%'
            controls={false}
            url={[
              { src: assetURI, type: assetType },
            ]}
          />
        </div>)
    }
    if (fileType.includes('audio')) {
      return (
        <div className='audio-container' onClick={handleViewDetails}>
          <AudioPlayer
            autoPlay={false}
            src={assetURI}
          />
        </div>
      )
    }
  }

  return (

    <div className="nft-container">
      <div className="data-container">

        <div className="profile-details">
          <div className="user-profile-image">
            <UserIdenticon
              size={50}
              walletAddress={data ? data.owner_of : ''}
              customCss={{ width: '50px', height: '50px' }}
            />
          </div>
          <div className="user-wallet-address">
            {getEllipsisTxt(data ? data.owner_of : '', 7)}
          </div>
        </div>

        <div className="nft-asset-container">

          {
            isNftImageLoading ? (
              <div className='image-loader'>
                <Spin indicator={spinnerIcon} />
              </div>
            )
              :
              (<div className="nft-image-container">

                {/* <Image
                style={{ objectFit: 'cover' }}
                height={imageHeight}
                src={nftImageWithFiletype}
                preview={imagePreview}
                fallback={defaultPreviewImage}
              /> */}

                {provideAssetContainer(nftImage, fileType)}

              </div>)
          }

        </div>
        <div className="nft-name">
          {nftTitle}
        </div>
        {/* <div className="nft-description">
          This is funny bunny
        </div> */}
        <div className="nft-token-id">
          Token ID: #{nftTokenId}
        </div>
        <div className="view-button">
          <Button
            className='mint-button'
            style={{ marginTop: '20px' }}
            type="primary"
            size="small"
            onClick={handleViewDetails}
          >
            View Details
          </Button>
        </div>
      </div>
    </div >

  );
};

export default NFTContainer;
