import React, { useEffect, useState } from "react";
import { Input, Button, Image, notification, Tooltip } from 'antd'
import './Staking.css'
import { Row, Col } from 'reactstrap'
import StakingGuideline from './components/StakingGuideline'
import Staking7DaysPool from "./Staking7DaysPool";
import Staking30DaysPool from "./Staking30DaysPool";
import Staking60DaysPool from "./Staking60DaysPool";
import Staking90DaysPool from "./Staking90DaysPool";
import { stakingConfigs } from './services/staking.config'
import { useWeb3React } from '@web3-react/core'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from "react-redux"

function StakingPools() {

    // const currentChain = localStorage.getItem('ever-earn-chain')
    //     ? localStorage.getItem('ever-earn-chain') : "bsc"
    const { currentChain } = useSelector((state) => state.chainSelector)

    const { account } = useWeb3React()
    const [stakingAmountFor7Days, setStakingAmountFor7Days] = useState(0)
    const [stakingAmountFor30Days, setStakingAmountFor30Days] = useState(0)
    const [stakingAmountFor60Days, setStakingAmountFor60Days] = useState(0)
    const [stakingAmountFor90Days, setStakingAmountFor90Days] = useState(0)

    const [rewardSymbol, setRewardSymbol] = useState('')

    useEffect(() => {
        if (currentChain) {
            if (currentChain === 'bsc') {
                setRewardSymbol('BUSD')
            } else {
                setRewardSymbol('USDC')
            }
        }
    }, [currentChain])

    return (
        <div className="p-4">
            <Row>
                <Col sm="12" md="12" lg="12">
                    <div className='p-4 staking-definition-cards'>
                        <div className="d-flex">
                            <InfoCircleOutlined style={{ fontSize: '18px' }} className='staking-payout-icon' />
                            <span className="staking-payout-header">Staking Payouts</span>
                        </div>

                        <Row className="mt-3">
                            <Col sm="12" md="6" lg="6">
                                <div className="">
                                    <span className="glossary">7 Days</span> = 25% APR = 0.4759% for 1 cycle = 28.2% APY.
                                </div>
                                <div className="">
                                    <span className="glossary">30 Days</span> = 30% APR = 2.4658% for 1 cycle = 34.0% APY.
                                </div>

                            </Col>
                            <Col sm="12" md="6" lg="6">
                                <div className="">
                                    <span className="glossary">60 Days</span> = 40% APR = 6.5753% for 1 cycle = 46.5% APY.
                                </div>
                                <div className="">
                                    <span className="glossary">90 Days</span> = 50% APR = 12.3288% for 1 cycle = 59.2% APY.
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6" lg="6" className="mt-4">
                    <Staking7DaysPool
                        walletAddress={account}
                        stakingAmount={stakingAmountFor7Days}
                        setStakingAmount={setStakingAmountFor7Days}
                    />
                </Col>
                <Col sm="12" md="6" lg="6" className="mt-4">
                    <Staking30DaysPool
                        walletAddress={account}
                        stakingAmount={stakingAmountFor30Days}
                        setStakingAmount={setStakingAmountFor30Days}
                    />
                </Col>
                <Col sm="12" md="6" lg="6" className="mt-4">
                    <Staking60DaysPool
                        walletAddress={account}
                        stakingAmount={stakingAmountFor60Days}
                        setStakingAmount={setStakingAmountFor60Days}
                    />
                </Col>
                <Col sm="12" md="6" lg="6" className="mt-4">
                    <Staking90DaysPool
                        walletAddress={account}
                        stakingAmount={stakingAmountFor90Days}
                        setStakingAmount={setStakingAmountFor90Days}
                    />
                </Col>
            </Row>
            <Row className="px-4 pb-5">
                <Col sm="12" md="12" lg="12" className="mt-5">
                    <div>
                        <div className="staking-process-header">Staking Process</div>
                        <ul class="stake-details-label">
                            <li>Investors must hold a minimum of $20 USD worth of EverEarn to participate in {rewardSymbol} Staking.</li>
                            <li>Investors can invest up to 20% of their total EverEarn holding value (USD value), up to a maximum of 1,000 {rewardSymbol} in EACH of the staking pools.</li>
                            <li>Early withdrawal from staking forfeits any earned interest.</li>
                        </ul>
                    </div>

                    <div className="mt-4">
                        <div className="staking-process-header">Staking Definitions</div>
                        <div className="d-flex">
                            <span className="staking-remaining-busd ">APR </span>
                            <span className="stake-details-label ml-2">
                                - Annual Percent Rate. Annual rate of return WITHOUT compounding.
                            </span>
                        </div>
                        <div className="d-flex">
                            <span className="staking-remaining-busd ">APY </span>
                            <span className="stake-details-label ml-2">
                                - Annual Percent Yield. Annual rate of return WITH compounding.
                            </span>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="staking-process-header">Staking Steps</div>
                        <div className="stake-details-label">
                            1) Enter amount of {rewardSymbol} to stake
                        </div>
                        <div className="stake-details-label">
                            2) Click on 'Approve {rewardSymbol} Transfer' (Metamask will open for you to approve a small fee for access to contract and wallet)
                        </div>
                        <div className="stake-details-label">
                            3) Click on 'Stake {rewardSymbol}' (Metamask will open for you to approve a small fee to complete joining of staking)
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default StakingPools
