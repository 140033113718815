import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Card } from 'antd';
import './General.css';
import { useSelector } from "react-redux"

function EverSwap() {

    const { currentChain } = useSelector((state) => state.chainSelector)
    const [isBsc, setIsBsc] = useState('')

    useEffect(() => {
        if (currentChain) {
            if (currentChain === 'bsc') {
                setIsBsc(true)
            } else {
                setIsBsc(false)
            }
        }
    }, [currentChain])
	return (
		<div className="container mt-4">
            <div className='d-flex justify-content-center'>
                <h2 className='todo-header'>Buy EverEarn</h2>
            </div>
            {
                isBsc ? (
                    <div className='d-flex justify-content-center'>
                        <div className='ifram-card'>
                            <iframe width="500" height="600" frameborder="0" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.trade/embed/swap/0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C?padding=20&roundedCorners=10&backgroundColor=transparent&primaryColor=%23efb90d&network=bsc&disableBuyCrypto=false&refId=bQm0Fh&lightMode=false" ></iframe>
                        </div>
                        <div className='ifram-card-mobile'>
                            <iframe style={{borderRadius: '10px', border: 'solid 2px #d2a310'}} width="100%" height="675" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.trade/embed/swap/0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C?padding=20&roundedCorners=10&backgroundColor=transparent&primaryColor=%23efb90d&network=bsc&disableBuyCrypto=false&refId=bQm0Fh&lightMode=false" > </iframe>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-center'>
                        <div className='ifram-card'>
                            <iframe width="500" height="600" frameborder="0" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.trade/embed/swap/0xA87Ed75C257f1ec38393bEA0A83d55Ac2279D79c?padding=20&roundedCorners=10&backgroundColor=transparent&primaryColor=%23efb90d&network=eth&disableBuyCrypto=false&refId=bQm0Fh&lightMode=false" ></iframe>
                        </div>
                        <div className='ifram-card-mobile'>
                            <iframe style={{borderRadius: '10px', border: 'solid 2px #647BE7'}} width="100%" height="675" 
                            allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.trade/embed/swap/0xA87Ed75C257f1ec38393bEA0A83d55Ac2279D79c?padding=20&roundedCorners=10&backgroundColor=transparent&primaryColor=%23efb90d&network=eth&disableBuyCrypto=false&refId=bQm0Fh&lightMode=false" > </iframe>
                        </div>
                    </div>
                )
            }
            
		</div>
	);
}

export default EverSwap;
