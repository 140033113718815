/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from "../blockchain/web3.service"
import axios from "axios"

export const useCommonTokenData = () => {

  const [totalBusdDistributed, setTotalBusdDistributed] = useState(0.0)
  const [tokenMetaData, setTokenMetaData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const fetchTokenDataFromAPI = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_COINGECKO_ENDPOINT}coins/everearn`
      const response = await axios.get(endpoint)
      if (response.status === 200) {
        setTokenMetaData(response.data)
      } else {
        setTokenMetaData()
      }
    } catch (error) {
      setTokenMetaData()
      console.error("ERROR while fetching token data form API ", error)
    }
  }


  useEffect( () => {

    async function fetchData() {
      
      try {
        setLoading(true)
        //get user token holdings by address
        const totalDividendDistributed = await web3Service.getTotalDividendDistributed()
        setTotalBusdDistributed(totalDividendDistributed)
        await fetchTokenDataFromAPI()
  
      } catch (error) {
        setTotalBusdDistributed(0.0)
        setTokenMetaData(null)
        console.error('error while fetching common token info ', error.message || '')
      } finally {
        setLoading(false)
      }
    }
    fetchData();

  }, [])


  return { totalBusdDistributed, tokenMetaData, isLoading }
}

export default useCommonTokenData

