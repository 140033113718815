import React, { useState } from 'react'
import { stakingConfigs } from '../services/staking.config'
import { ethers } from "ethers";
import 'react-toastify/dist/ReactToastify.css';
import EventEmitter from '../../../utils/EventEmitter';
import { Button, notification } from 'antd';
import { useWeb3React } from '@web3-react/core';

export default function EarlyWithdrawButton(props) {

  const { walletAddress, contractAddress } = props
  const [isEmergencyWithdrawLoading, setIsEmergencyWithdrawLoading] = useState(false)
  const { library } = useWeb3React()

  const handleEmergencyWithdraw = async () => {
    try {
      setIsEmergencyWithdrawLoading(true)
      //create the contract instance
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);

      const stakingContractInstanceWithSigner = stakingContractInstance.connect(library.getSigner());

      //call the contract function
      const emergencyWithdrawReceipt = await stakingContractInstanceWithSigner.emergencyWithdraw({ gasLimit: '285000' })
      const result = await emergencyWithdrawReceipt.wait()
      console.log("Emergency withdraw receipt ", result)

      //emit the event to refresh the page
      EventEmitter.emit('refreshEvent', { isRefresh: true })
      notification['success']({
        message: 'Emergency Withdraw Success',
        description: 'You have successfully withdraw your tokens',
      });

      setIsEmergencyWithdrawLoading(false)

    } catch (error) {
      let errorMessage = 'something went wrong while trying to emergency withdraw  your tokens'
      if (error.message) {
        errorMessage = error.message
      }
      if (error.data && error.data.message) {
        errorMessage = error.data.message
      }
      notification['error']({
        message: 'Error',
        description: errorMessage,
      });
      console.error("ERROR: something went wrong while emergency withdraw ", error)
      setIsEmergencyWithdrawLoading(false)
    }
  }
  return (
    <div>

      <Button
        block={true}
        onClick={handleEmergencyWithdraw}
        size="large"
        style={{ background: '#DC3444' }}
        loading={isEmergencyWithdrawLoading}
        className='col-12 ever-earn-primary-button mt-3'
      >
        Early Withdraw (Penalty)
      </Button>
    </div>
  )
}
