import { configs } from "./web3.config"
import { ethers } from 'ethers'

export const getWeb3PRovider = () => {
  const { web3Config } = configs
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3Config.webRpcHttpNode, { name: 'binance', chainId: 56 })
  return web3
}

export const getBnbBalanceOfAddress = async (walletAddress) => {
  try {

    if (walletAddress) {
      const nativeBalance = await window.web3Instance.eth.getBalance(walletAddress)
      const actualBalance = nativeBalance / 10 ** 18
      return actualBalance
    } else {
      return 0.0
    }
  } catch (error) {
    console.error("ERROR while fetching native balance of an address ", error)
    return 0.0
  }
}

export const getWeb3ProviderInstanceByChain = (chainType = 'bsc') => {

  let webHTTPProvider = 'https://nd-148-008-068.p2pify.com/d3a0c1a69ff0ec2258e8d9008085cd88';
  if (chainType === 'bsc') {
    webHTTPProvider = 'https://nd-148-008-068.p2pify.com/d3a0c1a69ff0ec2258e8d9008085cd88';
  }

  if (chainType === 'eth') {
    webHTTPProvider = 'https://nd-393-999-546.p2pify.com/f2899508497796575e495ac91c00b618';
  }

  if (chainType === 'matic') {
    webHTTPProvider = 'https://nd-805-942-089.p2pify.com/ccfee4a7f1c0d3690fac22236b3074c2';
  }

  const provider = new ethers.providers.JsonRpcProvider(webHTTPProvider);
  return provider;

}

export const getNativeCurrencyPrice = async (chainType = 'bsc', blockNumber = 'latest') => {
  const { web3Config } = configs;
  let exchangeRouterContractAbi = null;
  let exchangeRouterAddress = null;
  let provider = null;
  let nativeWrappedTokenAddress = null;
  let usdTokenAddress = null

  if (chainType === 'bsc') {

    try {

      exchangeRouterContractAbi = JSON.parse(web3Config.pancakeSwapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.pancakeSwapContractAddress;
      provider = getWeb3ProviderInstanceByChain(chainType);
      nativeWrappedTokenAddress = web3Config.pricingAddress.bnbTokenAddress;
      usdTokenAddress = web3Config.pricingAddress.usdTokenAddress;

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      let amountOut = 0
      let tokensToSell = ethers.utils.parseEther("1.0")
      amountOut = await exchangeContractInstance.getAmountsOut(tokensToSell.toBigInt(), [
        nativeWrappedTokenAddress,
        usdTokenAddress], { blockTag: blockNumber });
      amountOut = ethers.utils.formatEther(amountOut[1])
      return parseFloat(amountOut);
    } catch (error) {
      console.log("Error while fetching bnb price", error);
      return 0.0
    }

  }

  if (chainType === 'eth') {

    try {

      exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.uniswapContractAddress;
      nativeWrappedTokenAddress = web3Config.pricingAddress.ethTokenAddress;
      usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForEth;
      provider = getWeb3ProviderInstanceByChain(chainType);

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      let amountOut = 0
      let tokensToSell = ethers.utils.parseEther("1.0")
      amountOut = await exchangeContractInstance.getAmountsOut(tokensToSell.toBigInt(), [
        nativeWrappedTokenAddress,
        usdTokenAddress], { blockTag: blockNumber });
      amountOut = amountOut[1] / (10 ** 6)
      return parseFloat(amountOut);
    } catch (error) {
      console.log("Error while fetching eth price", error);
      return 0.0
    }

  }

  if (chainType === 'matic') {
    try {
      exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.quickSwapContractAddress;
      provider = getWeb3ProviderInstanceByChain(chainType);
      nativeWrappedTokenAddress = web3Config.pricingAddress.maticTokenAddress;
      usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForMatic;

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      let amountOut = 0
      let tokensToSell = ethers.utils.parseEther("1.0")
      amountOut = await exchangeContractInstance.getAmountsOut(tokensToSell.toBigInt(), [
        nativeWrappedTokenAddress,
        usdTokenAddress], { blockTag: blockNumber });
      amountOut = amountOut[1] / (10 ** 6)

      return parseFloat(amountOut);
    } catch (error) {
      console.log("Error while fetching matic price", error);
      return 0.0
    }
  }

}

export const getAccountBalance = async (chainType = 'bsc', accountAddress) => {

  try {
    const provider = getWeb3ProviderInstanceByChain(chainType);
    const nativeAccountBalance = await provider.getBalance(accountAddress)
    const actualAccountBalance = ethers.utils.formatEther(nativeAccountBalance)
    return actualAccountBalance || 0.00
  } catch (error) {
    console.log("Error while fetching bnb price", error)
    return 0.00
  }
}

export const calculateNativeCurrencyData = async (chainType = 'bsc', accountAddress, blockNumbersData) => {

  const currentPriceData = await getNativeCurrencyPrice(chainType)
  const last24HourPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last24HourBlock)
  const last7DaysPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last7DaysBlock)
  const last30DaysPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last30DaysBlock)
  let currencySymbol = '';

  if (chainType === 'bsc') {
    currencySymbol = 'BNB'
  }

  if (chainType === 'eth') {
    currencySymbol = 'ETH'
  }

  if (chainType === 'matic') {
    currencySymbol = 'MATIC'
  }

  //calculate price fractionation
  let percentageLast24Hours = 0.00
  if (parseFloat(last24HourPriceData) && parseFloat(last24HourPriceData) > 0) {
    const deltaChangeLast24Hours = parseFloat(currentPriceData) - parseFloat(last24HourPriceData)
    const fluctuationLst24Hours = deltaChangeLast24Hours / parseFloat(last24HourPriceData)
    percentageLast24Hours = fluctuationLst24Hours * 100
  }

  let percentageLast7Days = 0.00
  if (parseFloat(last7DaysPriceData) && parseFloat(last7DaysPriceData) > 0) {
    const deltaChangeLast7Days = parseFloat(currentPriceData) - parseFloat(last7DaysPriceData)
    const fluctuationLst7days = deltaChangeLast7Days / parseFloat(last7DaysPriceData)
    percentageLast7Days = fluctuationLst7days * 100
  }

  let percentageLast30Days = 0.00
  if (parseFloat(last30DaysPriceData) && parseFloat(last30DaysPriceData) > 0) {
    const deltaChangeLast30Days = parseFloat(currentPriceData) - parseFloat(last30DaysPriceData)
    const fluctuationLst30days = deltaChangeLast30Days / parseFloat(last30DaysPriceData)
    percentageLast30Days = fluctuationLst30days * 100

  }

  const accountBalanceInString = await getAccountBalance(chainType, accountAddress)
  const accountBalance = parseFloat(accountBalanceInString)
  const accountBalanceInUSD = parseFloat(accountBalance) * parseFloat(currentPriceData)

  return {
    currentPriceData,
    percentageLast24Hours,
    percentageLast7Days,
    percentageLast30Days,
    accountBalanceInUSD,
    accountBalance,
    currencySymbol
  }
}

export const calculateTokenData = async (chainType = 'bsc', tokenList, blockNumbersData) => {

  const currentPriceData = await getNativeCurrencyPrice(chainType)
  const last24HourPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last24HourBlock)
  const last7DaysPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last7DaysBlock)
  const last30DaysPriceData = await getNativeCurrencyPrice(chainType, blockNumbersData.last30DaysBlock)

  if (tokenList && tokenList.length > 0) {

    return await Promise.allSettled(tokenList.map(token => {
      const tokenDecimals = parseInt(token.decimals)

      return getTokenPriceByTokenAddressWithChain(chainType, token.token_address, tokenDecimals, 'latest', currentPriceData)
        .then(currentPriceData => {
          return getTokenPriceByTokenAddressWithChain(chainType, token.token_address, tokenDecimals, blockNumbersData.last24HourBlock, last24HourPriceData)
            .then(las24HoursPriceData => {
              return getTokenPriceByTokenAddressWithChain(chainType, token.token_address, tokenDecimals, blockNumbersData.last7DaysBlock, last7DaysPriceData)
                .then(last7DaysPriceData => {
                  return getTokenPriceByTokenAddressWithChain(chainType, token.token_address, tokenDecimals, blockNumbersData.last30DaysBlock, last30DaysPriceData)
                    .then(last30DaysPriceData => {
                      return {
                        tokenData: token,
                        currentPriceData: currentPriceData,
                        las24HoursPriceData: las24HoursPriceData,
                        last7DaysPriceData: last7DaysPriceData,
                        last30DaysPriceData: last30DaysPriceData,
                      }
                    })
                })
            })
        })
    }))
  }
}

export const processDataForRendering = (rawDataSet) => {

  const tableDataSet = []
  let tokenTotalAmount = 0.0


  if (rawDataSet && rawDataSet.length > 0) {

    rawDataSet.forEach(tokenData => {

      if (tokenData.status === 'fulfilled') {
        //calculate actual token amount
        const tokenMetadata = tokenData.value.tokenData
        const tokenBalance = parseInt(tokenMetadata.balance || 0)
        const tokenDecimals = parseInt(tokenMetadata.decimals || 1)
        const actualTokenAmount = tokenBalance / (10 ** tokenDecimals)

        //get current token price
        const tokenPriceData = tokenData.value
        const currentPrice = tokenPriceData.currentPriceData.tokenPriceInUSD || 0.00

        const totalTokenPrice = parseFloat(actualTokenAmount) * parseFloat(currentPrice)

        tokenTotalAmount = tokenTotalAmount + totalTokenPrice

        //last 24 hours pricing data
        const last24HoursPrice = tokenPriceData.las24HoursPriceData.tokenPriceInUSD || 0.00
        const last7DaysPrice = tokenPriceData.last7DaysPriceData.tokenPriceInUSD || 0.00
        const last30DaysPrice = tokenPriceData.last30DaysPriceData.tokenPriceInUSD || 0.00

        //calculate price fractionation
        let percentageLast24Hours = 0.00
        if (parseFloat(last24HoursPrice) && parseFloat(last24HoursPrice) > 0) {
          const deltaChangeLast24Hours = parseFloat(currentPrice) - parseFloat(last24HoursPrice)
          const fluctuationLst24Hours = deltaChangeLast24Hours / parseFloat(last24HoursPrice)
          percentageLast24Hours = fluctuationLst24Hours * 100
        }

        if (parseFloat(last24HoursPrice) && parseFloat(last24HoursPrice) > 0) {
          const deltaChangeLast24Hours = parseFloat(currentPrice) - parseFloat(last24HoursPrice)
          const fluctuationLst24Hours = deltaChangeLast24Hours / parseFloat(last24HoursPrice)
          percentageLast24Hours = fluctuationLst24Hours * 100
        }

        let percentageLast7Days = 0.00
        if (parseFloat(last7DaysPrice) && parseFloat(last7DaysPrice) > 0) {
          const deltaChangeLast7Days = parseFloat(currentPrice) - parseFloat(last7DaysPrice)
          const fluctuationLst7days = deltaChangeLast7Days / parseFloat(last7DaysPrice)
          percentageLast7Days = fluctuationLst7days * 100
        }

        let percentageLast30Days = 0.00
        if (parseFloat(last30DaysPrice) && parseFloat(last30DaysPrice) > 0) {
          const deltaChangeLast30Days = parseFloat(currentPrice) - parseFloat(last30DaysPrice)
          const fluctuationLst30days = deltaChangeLast30Days / parseFloat(last30DaysPrice)
          percentageLast30Days = fluctuationLst30days * 100
        }

        const payload = {
          tokenData: tokenMetadata,
          currentTokenPrice: currentPrice,
          actualTokenAmount: actualTokenAmount,
          totalTokenBalance: totalTokenPrice,
          percentageLast24Hours: percentageLast24Hours,
          percentageLast7Days: percentageLast7Days,
          percentageLast30Days: percentageLast30Days

        }
        tableDataSet.push(payload)
      }

    })
  }

  return {
    tableDataSet,
    tokenTotalAmount
  }
}


export const executeClaimRewards = async (signer) => {
  const { web3Config } = configs
  try {
    const provider = getWeb3PRovider()
    if (provider) {
      const dividendTrackerContractInstance = new ethers.Contract(
        web3Config.contractWallets.dividendTrackerAddress,
        JSON.parse(web3Config.dividendTrackerABI),
        signer
      )
      const claimDividendTxReceipt = await dividendTrackerContractInstance.claimDividend()
      const result = await claimDividendTxReceipt.wait()
      return result
    }
  } catch (error) {
    console.error("ERROR while executing claim rewards function ", error)
  }
}

export const getDividendHoldersCount = async (dividendTrackerAddress) => {
  const { web3Config } = configs
  try {

    if (dividendTrackerAddress) {
      const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      const dividendTrackerInstance = await new window.web3Instance.eth.Contract(dividendTrackerABI, dividendTrackerAddress)
      const dividendHoldersCount = await dividendTrackerInstance.methods.getNumberOfTokenHolders().call()
      return dividendHoldersCount
    } else {
      return 0
    }

  } catch (error) {
    console.error("ERROR while fetching dividend holders count ", error)
    return 0
  }
}

export const getBnbPrice = async () => {
  const { web3Config } = configs
  const contractAbi = JSON.parse(web3Config.pancakeSwapContractAbi)
  const pcsContract = await new window.web3Instance.eth.Contract(contractAbi, web3Config.pricingAddress.pancakeSwapContractAddress)

  const bnbToSell = window.web3Instance.utils.toWei('1', 'ether')
  let amountOut
  try {
    amountOut = await pcsContract.methods.getAmountsOut(bnbToSell, [
      web3Config.pricingAddress.bnbTokenAddress,
      web3Config.pricingAddress.usdTokenAddress
    ])
      .call()
    amountOut = window.web3Instance.utils.fromWei(amountOut[1])
  } catch (error) {
    amountOut = '0'
  }
  return parseFloat(amountOut)
}


const setTokenDecimals = (number, decimals) => {
  number = number.toString()
  const numberAbs = number.split('.')[0]
  let numberDecimals = number.split('.')[1] ? number.split('.')[1] : ''
  while (numberDecimals.length < decimals) {
    numberDecimals += "0"
  }
  return numberAbs + numberDecimals
}

export const mintNFT = async (signer, account, metaDataURI) => {
  const { nftContractConfig } = configs
  const provider = getWeb3PRovider()
  if (provider) {
    const nftMintingContractInstance = new ethers.Contract(
      nftContractConfig.contractAddress,
      JSON.parse(nftContractConfig.contractABI),
      signer
    )
    let transactionResponse = await nftMintingContractInstance.safeMint(account, metaDataURI, {
      from: account,
      gasLimit: nftContractConfig.estimatedGasFeeLimit.toString(),
      value: nftContractConfig.constInWeiForSingleMint.toString(),
    });

    const receipt = await transactionResponse.wait();
    return receipt
  }
}

export const getTokenPriceByTokenAddressWithChain = async (chainType = 'bsc', tokenAddress, tokenDecimal, blockNumber = 'latest', nativeCurrencyPriceInUSD) => {
  const { web3Config } = configs;

  let exchangeRouterContractAbi = null;
  let exchangeRouterAddress = null;
  let provider = null;
  let nativeWrappedTokenAddress = null;
  // let usdTokenAddress = null

  if (chainType === 'bsc') {

    try {

      exchangeRouterContractAbi = JSON.parse(web3Config.pancakeSwapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.pancakeSwapContractAddress;
      provider = getWeb3ProviderInstanceByChain(chainType);
      nativeWrappedTokenAddress = web3Config.pricingAddress.bnbTokenAddress;
      // usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForBsc;

      const tokenToSell = setTokenDecimals(1, tokenDecimal);
      let tokenPrice = 0;

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      tokenPrice = await exchangeContractInstance.getAmountsOut(tokenToSell, [tokenAddress, nativeWrappedTokenAddress], { blockTag: blockNumber });
      tokenPrice = ethers.utils.formatEther(tokenPrice[1]);
      const tokenPriceInUSD = nativeCurrencyPriceInUSD * tokenPrice;
      return {
        tokenAddress: tokenAddress,
        tokenPriceInUSD: tokenPriceInUSD
      }
    } catch (error) {
      console.log("Error while fetching bsc token price", error);
      return 0.0
    }

  }

  if (chainType === 'eth') {

    try {

      exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.uniswapContractAddress;
      nativeWrappedTokenAddress = web3Config.pricingAddress.ethTokenAddress;
      // usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForEth;
      provider = getWeb3ProviderInstanceByChain(chainType);

      const tokenToSell = setTokenDecimals(1, tokenDecimal);
      let tokenPrice = 0;

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      tokenPrice = await exchangeContractInstance.getAmountsOut(tokenToSell, [tokenAddress, nativeWrappedTokenAddress], { blockTag: blockNumber });
      tokenPrice = ethers.utils.formatEther(tokenPrice[1]);
      const tokenPriceInUSD = nativeCurrencyPriceInUSD * tokenPrice;
      return {
        tokenAddress: tokenAddress,
        tokenPriceInUSD: tokenPriceInUSD
      }
    } catch (error) {
      console.log("Error while fetching eth price", error);
      return 0.0
    }

  }

  if (chainType === 'matic') {
    try {
      exchangeRouterContractAbi = JSON.parse(web3Config.quickSwapContractAbi);
      exchangeRouterAddress = web3Config.pricingAddress.quickSwapContractAddress;
      nativeWrappedTokenAddress = web3Config.pricingAddress.maticTokenAddress;
      // usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForEth;
      provider = getWeb3ProviderInstanceByChain(chainType);

      const tokenToSell = setTokenDecimals(1, tokenDecimal);
      let tokenPrice = 0;

      const exchangeContractInstance = new ethers.Contract(
        exchangeRouterAddress,
        exchangeRouterContractAbi,
        provider
      );

      tokenPrice = await exchangeContractInstance.getAmountsOut(tokenToSell, [tokenAddress, nativeWrappedTokenAddress], { blockTag: blockNumber });
      tokenPrice = ethers.utils.formatEther(tokenPrice[1]);
      const tokenPriceInUSD = nativeCurrencyPriceInUSD * tokenPrice;
      return {
        tokenAddress: tokenAddress,
        tokenPriceInUSD: tokenPriceInUSD
      }
    } catch (error) {
      console.log("Error while fetching eth price", error);
      return 0.0
    }
  }
}


export const getTokenPriceByTokenAddress = async (tokenAddress) => {
  const { web3Config } = configs

  try {
    const BNBTokenAddress = web3Config.pricingAddress.bnbTokenAddress
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenAddress)
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    const tokenToSell = setTokenDecimals(1, tokenDecimals)

    let tokenPrice = 0
    const contractAbi = JSON.parse(web3Config.pancakeSwapContractAbi)
    const pcsFactoryAddress = web3Config.pricingAddress.pancakeSwapContractAddress.toLowerCase()
    const pcsContract = await new window.web3Instance.eth.Contract(contractAbi, pcsFactoryAddress)
    tokenPrice = await pcsContract.methods.getAmountsOut(tokenToSell, [tokenAddress, BNBTokenAddress]).call()
    tokenPrice = window.web3Instance.utils.fromWei(tokenPrice[1])
    return tokenPrice
  } catch (error) {
    return 0.0
  }
}

export const getTokenPriceInUSD = async () => {
  const tokenContractAddress = process.env.REACT_APP_MAIN_TOKEN_ADDRESS
  try {
    const bnbPriceInUSD = await getBnbPrice()
    const tokenPrice = await getTokenPriceByTokenAddress(tokenContractAddress)
    const tokenPrinceInUSD = bnbPriceInUSD * tokenPrice
    return tokenPrinceInUSD
  } catch (error) {
    return 0
  }
}

export const getERC20TokenPriceInUSDByAddress = async (tokenAddress) => {
  try {
    if (tokenAddress) {
      const bnbPriceInUSD = await getBnbPrice()
      const tokenPrice = await getTokenPriceByTokenAddress(tokenAddress)
      const tokenPrinceInUSD = bnbPriceInUSD * tokenPrice
      return tokenPrinceInUSD
    } else {
      return 0.0
    }
  } catch (error) {
    return 0.0
  }
}

export const getERC20TokenBalanceByWalletAddress = async (tokenAddress, walletAddress) => {
  const { web3Config } = configs
  try {

    if (walletAddress && tokenAddress) {
      const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
      const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenAddress)
      const tokenBalance = await erc20ContractInstance.methods.balanceOf(walletAddress).call()

      //get the decimal value of the token
      const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
      const actualTokenBalance = tokenBalance / (10 ** tokenDecimals)
      return actualTokenBalance
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR: while fetching erc 20 token balance ", error)
    return 0
  }
}

export const getUserDividendsEarningByWalletAddress = async (walletAddress) => {

  const { web3Config } = configs
  try {

    if (walletAddress) {
      const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      const dividendTackerAddress = web3Config.contractWallets.dividendTrackerAddress
      const dividendTrackerInstance = await new window.web3Instance.eth.Contract(dividendTrackerABI, dividendTackerAddress)
      const sharesDetails = await dividendTrackerInstance.methods.shares(walletAddress).call()
      const earnedValue = sharesDetails[2]

      //create instance of dividend token 
      const dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
      const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, dividendTokenAddress)

      //get the decimal value of the token
      const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
      const actualEarnTokenValue = earnedValue / (10 ** tokenDecimals)
      return actualEarnTokenValue
    } else {
      return 0.0
    }


  } catch (error) {
    console.error("ERROR: while fetching user dividend earning ", error)
    return 0.0
  }
}

export const getUserUnpaidDividendsEarningByWalletAddress = async (walletAddress) => {

  const { web3Config } = configs
  try {
    if (walletAddress) {
      const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      const dividendTackerAddress = web3Config.contractWallets.dividendTrackerAddress
      const dividendTrackerInstance = await new window.web3Instance.eth.Contract(dividendTrackerABI, dividendTackerAddress)
      const unpaidEarning = await dividendTrackerInstance.methods.getUnpaidEarnings(walletAddress).call()

      //create instance of dividend token 
      const dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
      const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, dividendTokenAddress)

      //get the decimal value of the token
      const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
      const actualUnpaidEarning = unpaidEarning / (10 ** tokenDecimals)
      return actualUnpaidEarning
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR: while fetching user unpaid dividend earning ", error)
    return 0.0
  }
}

export const getTotalDividendDistributed = async () => {

  const { web3Config } = configs
  try {
    const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
    const dividendTackerAddress = web3Config.contractWallets.dividendTrackerAddress
    const dividendTrackerInstance = await new window.web3Instance.eth.Contract(dividendTrackerABI, dividendTackerAddress)
    const totalDividendDistributed = await dividendTrackerInstance.methods.totalDistributed().call()

    //create instance of dividend token 
    const dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, dividendTokenAddress)

    //get the decimal value of the token
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    const actualTotalDividendDistributed = totalDividendDistributed / (10 ** tokenDecimals)
    return actualTotalDividendDistributed

  } catch (error) {
    console.error("ERROR: while fetching user total dividend distributed ", error)
    return 0.0
  }
}


export const getTokenBalanceByUserWalletAddress = async (userWalletAddress) => {
  const { web3Config } = configs
  try {

    if (userWalletAddress) {
      const tokenContractAddress = web3Config.contractWallets.main
      const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
      const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
      const tokenBalance = await erc20ContractInstance.methods.balanceOf(userWalletAddress).call()

      //get the decimal value of the token
      const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
      const actualTokenBalance = tokenBalance / (10 ** tokenDecimals)
      return actualTokenBalance
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR while fetching user token balance ", error)
    return 0.0
  }
}

export const getTotalDividendsPaidInBNB = async () => {
  const { web3Config } = configs
  try {
    const tokenContractAddress = process.env.REACT_APP_MAIN_TOKEN_ADDRESS
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
    const totalDividendsAmount = await erc20ContractInstance.methods.totalDistributedRewards().call()
    const actualBnbAmount = totalDividendsAmount / (10 ** 18)


    //get the bnb price 
    // const bnbPrice = await getBnbPrice();
    //const bnbPrice = 1;
    const rewardPriceInUSD = actualBnbAmount
    return {
      rewardAmountInBNB: actualBnbAmount,
      rewardAmountInUSD: rewardPriceInUSD
    }
  } catch (error) {
    return {
      rewardAmountInBNB: 0.0,
      rewardAmountInUSD: 0.0
    }
  }
}


export const getTokenDividendInfoByUserWalletAddress = async (userWalletAddress) => {
  const { web3Config } = configs
  try {
    const tokenContractAddress = web3Config.contractWallets.main
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
    const tokenDividendInfo = await erc20ContractInstance.methods.getHolderDetails(userWalletAddress).call()
    const paidAmountInWei = tokenDividendInfo[2]
    const totalPaidAmount = parseFloat(window.web3Instance.utils.fromWei(paidAmountInWei))
    const lastPaidAtTimestamp = parseInt(tokenDividendInfo[0])

    return {
      totalPaidAmount,
      lastPaidAtTimestamp
    }
  } catch (error) {
    return {
      totalPaidAmount: 0,
      lastPaidAtTimestamp: null
    }
  }
}

export const getTokenDecimals = async (contractAddress) => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, contractAddress)
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    return parseInt(tokenDecimals)
  } catch (error) {
    console.error("ERROR : while fetching token decimals ", error)
    return 1
  }
}

export const calculateLiquidityInfoByContractAddress = async (contractAddress) => {

  const { web3Config } = configs
  try {

    //find the liquidity pair pairs
    const liquidityPairAddress = await getPairAddressByContractAddress(contractAddress)
    const liquidityContractAbi = JSON.parse(web3Config.lpTokenContractAbi)
    const liquidityPoolDataList = []
    let totalLiquidityPoolTokenBalanceInUSD = 0
    let totalLiquidityPoolBalanceInUSDOtherPair = 0
    let totalLiquidityCumulativeBalanceInUSD = 0
    //check liquidity pool availability and get the balance of each pool
    if (liquidityPairAddress.BUSDPairAddress !== '') {

      const busdLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.BUSDPairAddress)
      const reserveData = await busdLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let busdBalance = 0
      let busdTokenAddress = ''
      const tokenOne = await busdLiquidityInstance.methods.token0().call()
      const tokenTwo = await busdLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        busdBalance = reserveData[1]
        busdTokenAddress = tokenTwo
      } else {
        busdTokenAddress = tokenOne
        tokenBalance = reserveData[1]
        busdBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const busdTokenDecimals = await getTokenDecimals(busdTokenAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const busdPriceInUSD = await getERC20TokenPriceInUSDByAddress(busdTokenAddress)
      const actualBusdPoolBalance = parseInt(busdBalance) / (10 ** busdTokenDecimals)
      const busdPoolBalanceInUSD = actualBusdPoolBalance * busdPriceInUSD

      const payload = {
        pairName: 'BUSDPair',
        pairAddress: liquidityPairAddress.BUSDPairAddress,
        tokenAmount: actualTokenBalance,
        busdAmount: actualBusdPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        busdBalanceInUSD: busdPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + busdPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }


    if (liquidityPairAddress.WBNBPairAddress !== '') {

      const wBnbLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.WBNBPairAddress)
      const reserveData = await wBnbLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let wBnbBalance = 0
      let wBnbAddress = ''
      const tokenOne = await wBnbLiquidityInstance.methods.token0().call()
      const tokenTwo = await wBnbLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        wBnbBalance = reserveData[1]
        wBnbAddress = tokenTwo
      } else {
        wBnbAddress = tokenOne
        tokenBalance = reserveData[1]
        wBnbBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const wBnbTokenDecimals = await getTokenDecimals(wBnbAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const wBnbPriceInUSD = await getBnbPrice()
      const actualWbnbPoolBalance = parseInt(wBnbBalance) / (10 ** wBnbTokenDecimals)
      const wBnbPoolBalanceInUSD = actualWbnbPoolBalance * wBnbPriceInUSD

      const payload = {
        pairName: 'WBNBPair',
        pairAddress: liquidityPairAddress.WBNBPairAddress,
        tokenAmount: actualTokenBalance,
        wbnbAmount: actualWbnbPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        wBnbBalanceInUSD: wBnbPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + wBnbPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }

    if (liquidityPairAddress.USDTPairAddress !== '') {

      const usdtLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.USDTPairAddress)
      const reserveData = await usdtLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let usdtBalance = 0
      let usdtAddress = ''
      const tokenOne = await usdtLiquidityInstance.methods.token0().call()
      const tokenTwo = await usdtLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        usdtBalance = reserveData[1]
        usdtAddress = tokenTwo
      } else {
        usdtAddress = tokenOne
        tokenBalance = reserveData[1]
        usdtBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const usdtTokenDecimals = await getTokenDecimals(usdtAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const usdtPriceInUSD = await await getERC20TokenPriceInUSDByAddress(usdtAddress)
      const actualUsdtPoolBalance = parseInt(usdtBalance) / (10 ** usdtTokenDecimals)
      const usdtPoolBalanceInUSD = actualUsdtPoolBalance * usdtPriceInUSD

      const payload = {
        pairName: 'USDTPair',
        pairAddress: liquidityPairAddress.USDTPairAddress,
        tokenAmount: actualTokenBalance,
        usdAmount: actualUsdtPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        wBnbBalanceInUSD: usdtPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + usdtPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }

    totalLiquidityCumulativeBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + totalLiquidityPoolBalanceInUSDOtherPair

    return {
      liquidityPoolDataList,
      totalLiquidityPoolTokenBalanceInUSD,
      totalLiquidityPoolBalanceInUSDOtherPair,
      totalLiquidityCumulativeBalanceInUSD: totalLiquidityCumulativeBalanceInUSD,

    }

  } catch (error) {
    console.error("ERROR : while calculating liquidity data ", error)
    return {
      liquidityPoolDataList: [],
      totalLiquidityPoolTokenBalanceInUSD: 0,
      totalLiquidityPoolBalanceInUSDOtherPair: 0,
      totalLiquidityCumulativeBalanceInUSD: 0,
    }
  }
}

export const getClaimableDividendAmount = async (userWalletAddress) => {
  const { web3Config } = configs
  try {
    const tokenContractAddress = web3Config.contractWallets.main
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
    const tokenDividendInfo = await erc20ContractInstance.methods.getAccountDividendsInfo(userWalletAddress).call()
    const claimableAmount = tokenDividendInfo[3]
    const actualClaimableAmount = parseFloat(window.web3Instance.utils.fromWei(claimableAmount))
    return actualClaimableAmount
  } catch (error) {
    return 0.0
  }
}


export const getLatestDividendTrackerEventsByEventName = async () => {
  const { web3Config } = configs
  try {
    const dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
    const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
    const dividendTrackerContractInstance = await new window.web3Instance.eth.Contract(dividendTrackerABI, dividendTrackerAddress)
    // const toBlock = await window.web3Instance.eth.getBlockNumber()
    // const fromBlock = toBlock - 50

    const pastEvents = await dividendTrackerContractInstance.get_transaction()

    return pastEvents

  } catch (error) {
    return []
  }
}


export const getPairAddressByContractAddress = async (contractAddress) => {

  const { web3Config } = configs

  let BUSDPairAddress = ''
  let WBNBPairAddress = ''
  let USDTPairAddress = ''

  try {
    if (contractAddress) {
      const pcsFactoryContractABI = JSON.parse(web3Config.pancakeSwapFactoryContractAbi)
      const pcsFactoryAddress = web3Config.pricingAddress.pancakeSwapFactoryAddress
      const pcsFactoryContractInstance = await new window.web3Instance.eth.Contract(pcsFactoryContractABI, pcsFactoryAddress)

      //get the numbers of pairs 
      const BUSDAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
      const WBNBAdrress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
      const USDTAddress = '0x55d398326f99059fF775485246999027B3197955'

      const busdPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, BUSDAddress).call()
      const wbnbPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, WBNBAdrress).call()
      const usdtPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, USDTAddress).call()

      if (busdPairResponse !== '0x0000000000000000000000000000000000000000') {
        BUSDPairAddress = busdPairResponse
      }

      if (wbnbPairResponse !== '0x0000000000000000000000000000000000000000') {
        WBNBPairAddress = wbnbPairResponse
      }

      if (usdtPairResponse !== '0x0000000000000000000000000000000000000000') {
        USDTPairAddress = usdtPairResponse
      }

      return {
        BUSDPairAddress,
        WBNBPairAddress,
        USDTPairAddress
      }
    } else {
      return {
        BUSDPairAddress,
        WBNBPairAddress,
        USDTPairAddress
      }
    }

  } catch (error) {
    console.error("ERROR while fetching token circulation supply : ", error)
    return {
      BUSDPairAddress,
      WBNBPairAddress,
      USDTPairAddress
    }
  }
}


export const getTokenCirculationSupplyByContractAddress = async (contractAddress) => {

  const { web3Config } = configs
  let circulationSupply = 0
  try {
    if (contractAddress) {
      const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
      const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, contractAddress)
      const decimals = await erc20ContractInstance.methods.decimals().call()
      const contractDecimals = parseInt(decimals)
      const totalSupply = await erc20ContractInstance.methods.totalSupply().call()
      const deadAddressFrom1 = '0x000000000000000000000000000000000000dEaD'
      const zeroAddress = '0x0000000000000000000000000000000000000000'

      const pairAddressList = await getPairAddressByContractAddress(contractAddress)
      let busdPairAmount = 0
      let usdtPairAmount = 0
      let wbnbPairAmount = 0

      if (pairAddressList.BUSDPairAddress !== '') {
        busdPairAmount = await erc20ContractInstance.methods.balanceOf(pairAddressList.BUSDPairAddress).call()
      }

      if (pairAddressList.USDTPairAddress !== '') {
        usdtPairAmount = await erc20ContractInstance.methods.balanceOf(pairAddressList.USDTPairAddress).call()
      }

      if (pairAddressList.WBNBPairAddress !== '') {
        wbnbPairAmount = await erc20ContractInstance.methods.balanceOf(pairAddressList.WBNBPairAddress).call()
      }

      const totalPairListAmount = parseInt(busdPairAmount) + parseInt(usdtPairAmount) + parseInt(wbnbPairAmount)
      const deadAddressFrom1Amount = await erc20ContractInstance.methods.balanceOf(deadAddressFrom1).call()
      const zeroAddressAmount = await erc20ContractInstance.methods.balanceOf(zeroAddress).call()

      const totalTokenWithoutCirculation = parseInt(deadAddressFrom1Amount) + parseInt(zeroAddressAmount)
      const circulationSupplyUnformatted = parseInt(totalSupply) - totalTokenWithoutCirculation
      circulationSupply = circulationSupplyUnformatted / (10 ** contractDecimals)
      return circulationSupply
    } else {
      return circulationSupply
    }

  } catch (error) {
    console.error("ERROR while fetching token circulation supply : ", error)
    return circulationSupply
  }
}

export const getTheBlockDetails = async (blockNumber) => {
  try {
    const blockDetails = await window.web3Instance.eth.getBlock(blockNumber)
    return blockDetails
  } catch (error) {
    return null
  }
}

export const getTotalDividendPaidByAddress = async (tokenAddress, dividendTokenAddress) => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenAddress)
    // console.log('totalDividendsAmount', erc20ContractInstance.methods.totalDistributedRewards().call())
    // const totalDividendsAmount = await erc20ContractInstance.methods.totalDistributedRewards().call()
    const totalDividendsAmount = 10000000
    console.log('2');

    //get the dividend token contract details
    const dividendTokenContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, dividendTokenAddress)
    console.log('dividendTokenContractInstance', dividendTokenContractInstance);
    const dividendTokenDecimals = await dividendTokenContractInstance.methods.decimals().call()
    const dividendTokenSymbol = await dividendTokenContractInstance.methods.symbol().call()
    const dividendTokenName = await dividendTokenContractInstance.methods.name().call()



    const actualDividendAmount = totalDividendsAmount / (10 ** dividendTokenDecimals)

    console.log('actualDividendAmount', actualDividendAmount)

    //get dividend token price
    const dividendTokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(dividendTokenAddress)
    const totalDistributedDividendAmountInUSD = dividendTokenPriceInUSD * actualDividendAmount

    return {
      dividendTokenSymbol,
      dividendTokenName,
      dividendTokenPriceInUSD,
      totalDistributedDividendAmountInUSD,
      totalDividendsAmount
    }
  } catch (error) {
    return {
      dividendTokenSymbol: '',
      dividendTokenName: '',
      dividendTokenPriceInUSD: 0.0,
      totalDistributedDividendAmountInUSD: 0.0,
      totalDividendsAmount: 0.0
    }
  }
}


export const getTokenBurnedData = async (contractAddress) => {
  const { web3Config } = configs

  try {



    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, contractAddress)
    const totalSupplyUnformatted = await erc20ContractInstance.methods.totalSupply().call()
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    const tokenPrice = await getERC20TokenPriceInUSDByAddress(contractAddress)
    const tokenTotalSupply = totalSupplyUnformatted / (10 ** tokenDecimals)


    const burnAddressBalance1 = await erc20ContractInstance.methods
      .balanceOf('0x000000000000000000000000000000000000dead')
      .call()
    const burnAddressBalance2 = await erc20ContractInstance.methods
      .balanceOf('0x0000000000000000000000000000000000000000')
      .call()

    const totalBurnedTokenBalance =
      parseFloat(burnAddressBalance1) + parseFloat(burnAddressBalance2)
    const actualBurnedTokenBalance =
      totalBurnedTokenBalance / (10 ** tokenDecimals)

    const tokenBurnedAmount = actualBurnedTokenBalance
    const tokenBurnedPercentage = (tokenBurnedAmount / tokenTotalSupply) * 100

    const burnedTokenBalanceInUSD = tokenPrice * actualBurnedTokenBalance

    return {
      burnedTokenBalanceInUSD,
      actualBurnedTokenBalance,
      tokenBurnedPercentage
    }
  } catch (error) {

    console.error("ERROR while fetching burned token data ", error)
    return {
      burnedTokenBalanceInUSD: 0,
      actualBurnedTokenBalance: 0,
      tokenBurnedPercentage: 0
    }
  }
}

export const getMainTokenMetaDataInfo = async () => {
  const { web3Config } = configs
  let mainTokenAddress = ''
  let dividendTrackerAddress = ''
  let dividendTokenAddress = ''
  let tokenName = ''
  let tokenSymbol = ''
  let tokenTotalSupply = 0.0
  let tokenDecimals = 0
  let tokenBurnedAmount = 0.0
  let tokenBurnedPercentage = 0.0
  let tokenCirculationSupply = 0.0
  let tokenMarketCap = 0.0
  let tokenPrice = 0.0
  try {

    mainTokenAddress = web3Config.contractWallets.main
    dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
    dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress

    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainTokenAddress)
    tokenName = await erc20ContractInstance.methods.name().call()
    tokenSymbol = await erc20ContractInstance.methods.symbol().call()
    const totalSupplyUnformatted = await erc20ContractInstance.methods.totalSupply().call()
    tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    tokenPrice = await getERC20TokenPriceInUSDByAddress(mainTokenAddress)
    tokenTotalSupply = totalSupplyUnformatted / (10 ** tokenDecimals)


    const burnAddressBalance1 = await erc20ContractInstance.methods
      .balanceOf('0x000000000000000000000000000000000000dead')
      .call()
    const burnAddressBalance2 = await erc20ContractInstance.methods
      .balanceOf('0x0000000000000000000000000000000000000000')
      .call()

    const totalBurnedTokenBalance =
      parseFloat(burnAddressBalance1) + parseFloat(burnAddressBalance2)
    const actualBurnedTokenBalance =
      totalBurnedTokenBalance / (10 ** tokenDecimals)

    tokenBurnedAmount = actualBurnedTokenBalance
    tokenBurnedPercentage = (tokenBurnedAmount / tokenTotalSupply) * 100

    tokenCirculationSupply = tokenTotalSupply - tokenBurnedAmount
    tokenMarketCap = tokenCirculationSupply * tokenPrice

    return {
      mainTokenAddress,
      dividendTrackerAddress,
      dividendTokenAddress,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenDecimals,
      tokenBurnedAmount,
      tokenBurnedPercentage,
      tokenCirculationSupply,
      tokenMarketCap,
      tokenPrice
    }
  } catch (error) {
    return {
      mainTokenAddress,
      dividendTrackerAddress,
      dividendTokenAddress,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenDecimals,
      tokenBurnedAmount,
      tokenBurnedPercentage,
      tokenCirculationSupply,
      tokenMarketCap,
      tokenPrice
    }
  }
}

export const getBuyAndSellFee = async (contractAddress) => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, contractAddress)
    const sellFee = await erc20ContractInstance.methods.getTotalFee(true).call()
    const buyFee = await erc20ContractInstance.methods.getTotalFee(false).call()

    return {
      buyFee: (buyFee / 100),
      sellFee: (sellFee / 100),
    }
  } catch (error) {
    console.error("ERROR : while fetching buy and sell fee", error)
    return {
      buyFee: 0.0,
      sellFee: 0.0,
    }
  }
}

export const getBuyRewardFeeOfDividendTracker = async () => {
  const { web3Config } = configs
  try {
    const tokenContractAddress = web3Config.contractWallets.main
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
    const rewardsFee = await erc20ContractInstance.methods.buyTotalFees().call()
    return parseInt(rewardsFee)
  } catch (error) {
    return 0
  }
}

export const getSellRewardFeeOfDividendTracker = async () => {
  const { web3Config } = configs
  try {
    const tokenContractAddress = web3Config.contractWallets.main
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenContractAddress)
    const rewardsFee = await erc20ContractInstance.methods.sellTotalFees().call()
    return parseInt(rewardsFee)
  } catch (error) {
    return 0
  }
}

export const getTotalSupplyOfDividendTracker = async (dividendTrackerAddress) => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, dividendTrackerAddress)
    const totalSupply = await erc20ContractInstance.methods.totalSupply().call()

    //get the decimal value of the token
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    const actualTotalSupply = totalSupply / (10 ** tokenDecimals)
    return actualTotalSupply
  } catch (error) {
    return 0
  }
}

export const getSwapRewardFee = async () => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const mainContractAddress = web3Config.contractWallets.main
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainContractAddress)
    const rewardSwap = await erc20ContractInstance.methods.rewardSwap().call()
    const totalSwap = await erc20ContractInstance.methods.totalSwap().call()

    //calculate swap reward fee percentage
    const rewardFee = (parseFloat(rewardSwap) / parseFloat(totalSwap)) * 100
    return rewardFee
  } catch (error) {
    return 0
  }
}

export const getTheTotalShareOfDividendTracker = async () => {
  const { web3Config } = configs
  try {
    const dividendTrackerAbi = JSON.parse(web3Config.dividendTrackerABI)
    const dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
    const dividendTrackerInstance = await new window.web3Instance.eth.Contract(dividendTrackerAbi, dividendTrackerAddress)
    const totalShares = await dividendTrackerInstance.methods.totalShares().call()
    return parseFloat(totalShares) / (10 ** 9)
  } catch (error) {
    return 0
  }
}

export const getTotalSwapFee = async () => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const mainContractAddress = web3Config.contractWallets.main
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainContractAddress)
    const totalSwap = await erc20ContractInstance.methods.totalSwap().call()
    return parseFloat(totalSwap)
  } catch (error) {
    return 0
  }
}

export const getRewardSwapFee = async () => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const mainContractAddress = web3Config.contractWallets.main
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainContractAddress)
    const totalSwap = await erc20ContractInstance.methods.rewardSwap().call()
    return parseFloat(totalSwap)
  } catch (error) {
    return 0
  }
}

export const getRewardsInfoDetails = async () => {
  const { web3Config } = configs
  let rewardsTrackerContractAddress = ''
  let totalRewardsDistributed = 0.0
  let totalRewardsDistributedInUSD = 0.0
  let rewardsCycleInHours = 0
  let totalRewardsHolders = 0
  let currentProcessingIndex = 0
  let pendingRewardsHoldersCount = 0
  let completionRatioPercentage = 0
  const lastProcessTimestamp = 0
  try {

    rewardsTrackerContractAddress = web3Config.contractWallets.dividendTrackerAddress
    const mainTokenAddress = web3Config.contractWallets.main
    const dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainTokenAddress)

    const dividendTokenContractABI = JSON.parse(web3Config.mainContractAbi)
    const dividendTokenContractInstance = await new window.web3Instance.eth.Contract(dividendTokenContractABI, dividendTokenAddress)

    const dividedTokenDecimals = await dividendTokenContractInstance.methods.decimals().call()

    const totalDividendDistributedUnformatted = await erc20ContractInstance.methods.totalDistributedRewards().call()
    console.log('totalDividendDistributedUnformatted', totalDividendDistributedUnformatted)
    totalRewardsDistributed = totalDividendDistributedUnformatted / (10 ** dividedTokenDecimals)

    const dividendTokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(dividendTokenAddress)
    totalRewardsDistributedInUSD = totalRewardsDistributed * dividendTokenPriceInUSD

    rewardsCycleInHours = 1

    totalRewardsHolders = await erc20ContractInstance.methods.getNumberOfTokenHolders().call()
    currentProcessingIndex = await erc20ContractInstance.methods.getLastProcessedIndex().call()
    pendingRewardsHoldersCount = totalRewardsHolders - currentProcessingIndex
    completionRatioPercentage = (currentProcessingIndex / totalRewardsHolders) * 100

    return {
      rewardsTrackerContractAddress,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      rewardsCycleInHours,
      totalRewardsHolders,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      lastProcessTimestamp
    }
  } catch (error) {
    return {
      rewardsTrackerContractAddress,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      rewardsCycleInHours,
      totalRewardsHolders,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      lastProcessTimestamp
    }
  }
}

export const getUserRewardsDetails = async (userWalletAddress = null) => {

  const { web3Config } = configs
  let rewardsTrackerContractAddress = ''
  let rewardsTokenSymbol = ''
  let rewardTokenPendingBalance = 0.0
  let rewardTokenPendingBalanceInUSD = 0.0
  let totalRewardsDistributed = 0
  let totalRewardsDistributedInUSD = 0
  let currentProcessingIndex = 0
  let pendingRewardsHoldersCount = 0
  let completionRatioPercentage = 0
  let userLastPayoutTime = 0
  let userRewardsTotal = 0.0
  let userRewardsTotalInUSD = 0.0
  let userClaimableTokenAmount = 0.0
  let userClaimableTokenAmountInUSD = 0.0
  let totalRewardsHolders = 0
  let userIndex = 0

  try {

    rewardsTrackerContractAddress = web3Config.contractWallets.dividendTrackerAddress
    const mainTokenAddress = web3Config.contractWallets.main
    const dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, mainTokenAddress)

    const dividendTokenContractABI = JSON.parse(web3Config.mainContractAbi)
    const dividendTokenContractInstance = await new window.web3Instance.eth.Contract(dividendTokenContractABI, dividendTokenAddress)

    const dividedTokenDecimals = await dividendTokenContractInstance.methods.decimals().call()
    rewardsTokenSymbol = await dividendTokenContractInstance.methods.symbol().call()

    const totalDividendDistributedUnformatted = await erc20ContractInstance.methods.totalDistributedRewards().call()
    totalRewardsDistributed = totalDividendDistributedUnformatted / (10 ** dividedTokenDecimals)

    const dividendTokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(dividendTokenAddress)
    totalRewardsDistributedInUSD = totalRewardsDistributed * dividendTokenPriceInUSD

    //get the balance of dividend tracker
    const pendingTokenBalanceUnformatted = await dividendTokenContractInstance.methods.balanceOf(rewardsTrackerContractAddress).call()
    rewardTokenPendingBalance = pendingTokenBalanceUnformatted / (10 ** dividedTokenDecimals)

    rewardTokenPendingBalanceInUSD = rewardTokenPendingBalance * dividendTokenPriceInUSD

    if (userWalletAddress) {
      const userDividendTokenInfo = await erc20ContractInstance.methods.getHolderDetails(userWalletAddress).call()
      userIndex = parseInt(userDividendTokenInfo[3])
      if (userDividendTokenInfo[1] === 0) {
        userClaimableTokenAmount = 0
      } else {
        userClaimableTokenAmount = userDividendTokenInfo[1] / (10 ** dividedTokenDecimals)
      }

      userClaimableTokenAmountInUSD = userClaimableTokenAmount * dividendTokenPriceInUSD
      userRewardsTotal = userDividendTokenInfo[2] / (10 ** dividedTokenDecimals)
      userRewardsTotalInUSD = userRewardsTotal * dividendTokenPriceInUSD
      userLastPayoutTime = parseInt(userDividendTokenInfo[0])
    }

    totalRewardsHolders = await erc20ContractInstance.methods.getNumberOfTokenHolders().call()

    currentProcessingIndex = await erc20ContractInstance.methods.getLastProcessedIndex().call()
    currentProcessingIndex = parseInt(currentProcessingIndex)
    pendingRewardsHoldersCount = totalRewardsHolders - currentProcessingIndex
    completionRatioPercentage = (currentProcessingIndex / totalRewardsHolders) * 100

    return {
      rewardsTokenSymbol,
      rewardTokenPendingBalance,
      rewardTokenPendingBalanceInUSD,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      userLastPayoutTime,
      userRewardsTotal,
      userRewardsTotalInUSD,
      userClaimableTokenAmount,
      userClaimableTokenAmountInUSD,
      userIndex
    }
  } catch (error) {

    console.log('error', error)
    return {
      rewardsTokenSymbol,
      rewardTokenPendingBalance,
      rewardTokenPendingBalanceInUSD,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      userLastPayoutTime,
      userRewardsTotal,
      userRewardsTotalInUSD,
      userClaimableTokenAmount,
      userClaimableTokenAmountInUSD,
      userIndex
    }
  }
}

export const getTokenMarketCapIUnUSDByTokenAddress = async (tokenAddress) => {
  const { web3Config } = configs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, tokenAddress)
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()

    const tokenTotalSupplyUnformatted = await erc20ContractInstance.methods.totalSupply().call()
    const tokenTotalSupply = tokenTotalSupplyUnformatted / (10 ** tokenDecimals)

    const burnAddressBalance1 = await erc20ContractInstance.methods
      .balanceOf('0x000000000000000000000000000000000000dead')
      .call()
    const burnAddressBalance2 = await erc20ContractInstance.methods
      .balanceOf('0x0000000000000000000000000000000000000000')
      .call()

    const totalBurnedTokenBalance =
      parseFloat(burnAddressBalance1) + parseFloat(burnAddressBalance2)
    const actualBurnedTokenBalance =
      totalBurnedTokenBalance / (10 ** tokenDecimals)

    const circulationSupply = tokenTotalSupply - actualBurnedTokenBalance

    const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(tokenAddress)
    const marketCapInUSD = circulationSupply * tokenPriceInUSD
    return marketCapInUSD

  } catch (error) {
    return 0.0
  }
}