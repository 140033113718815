import { useEffect, useState } from "react"
// import * as web3Service from "../blockchain/web3.service"
import * as multiChainService from "../blockchain/services/multichain.service"

export const useRewardsInfo = () => {

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  const [rewardsInfo, setRewardsInfo] = useState({})
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {
    async function fetchData() {
      try {

        setLoading(true)
        //get the token main token price in usd
        const rewardsInfoResponse = await multiChainService.getRewardsInfoDetails(currentChain)
        if (rewardsInfoResponse) {
          setRewardsInfo(rewardsInfoResponse)
        } else {
          setRewardsInfo({})
        }
      } catch (error) {
        setRewardsInfo({})
        console.error('error while fetching rewards info ', error.message || '')
      } finally {
        setLoading(false)
      }
    }
    fetchData();

  }, [currentChain])


  return { rewardsInfo, isLoading }
}

export default useRewardsInfo

