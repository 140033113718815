import { useEffect, useState } from "react"
import * as multiChainService from "../blockchain/services/multichain.service"

export const useTokenDividendInfo = () => {

  const [tokenDividendInfo, setTokenDividendInfo] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {

    async function fetchData() {

      try {

        setLoading(true)
        //get the token main token price in usd
        const tokenDividendInfo = await multiChainService.getTotalDividendPaidByAddress(currentChain)
        if (tokenDividendInfo) {
          console.log('getTotalDividendPaidByAddress', tokenDividendInfo);
          setTokenDividendInfo(tokenDividendInfo)
        } else {
          setTokenDividendInfo(null)
        }
      } catch (error) {
        setTokenDividendInfo(null)
        console.error('error while fetching token dividend info ', error.message || '')
      } finally {
        setLoading(false)
      }

    }
    fetchData();

  }, [currentChain])


  return { tokenDividendInfo, isLoading }
}

export default useTokenDividendInfo

