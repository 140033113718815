import { tokenValueTxt } from "../../Helpers/Formatters"

// ** Third Party Components
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
// ** Reactstrap Imports
import { Card, CardBody, Spinner } from 'reactstrap'

// ** Default Options
// import { areaChartOptions } from './ChartOptions'

export default function CommonUserStatWidget(props) {
 
  const { icon, color, statTitle, className, statsMargin, value, symbol, isLoading } = props
  return (
    <Card className="ever-earn-card-bg mobile-top-margin">
      <div className={className}>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            {isLoading ? (
              <div className='text-center'>
                <LoadingOutlined className="spin-color" />
              </div>
            ) : (
              <h4
                className={classnames('fw-bolder text-light-green', {
                  'mb-0': !statsMargin,
                  [statsMargin]: statsMargin
                })}
              >
                { symbol ? `${symbol } ${ tokenValueTxt(value, 0, '')}` : value }
              
              </h4>
            )}

            <p className='card-text'>{statTitle}</p>
          </div>
          <div className={`avatar avatar-stats p-50 m-0 ${color ? `bg-light-${color}` : 'bg-light-primary'}`}>
            <div className='avatar-content'>{icon}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

// ** PropTypes
CommonUserStatWidget.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  symbol: PropTypes.string.isRequired,
  statTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  statsMargin: PropTypes.oneOf(['mb-0', 'mb-25', 'mb-50', 'mb-75', 'mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5'])
}

