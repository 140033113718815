/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str, n ) => {
    if (str) {
      return `${str.slice(0, n)}...${str.slice(str.length - n)}`
    }
    return ""
}

export const n12 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 12,
  maximumFractionDigits: 12
})
export const n10 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 10,
  maximumFractionDigits: 10
})

export const n2 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})
export const tokenValue = (value, decimals) => (decimals ? value / Math.pow(10, decimals) : value)

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */

export const tokenValueTxt = (value, decimals, symbol) => `${n2.format(tokenValue(value, decimals))} ${symbol}`