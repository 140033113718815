import React, { useEffect, useState } from 'react'
// import { useSelector } from "react-redux"
import DashboardBSC from './DashboardBSC'
import RewardDashboard from './RewardDashboard'
import { useThemeSwitcher } from "react-css-theme-switcher";

function Dashboard() {

    const { switcher, currentTheme, themes } = useThemeSwitcher();
    const currentChain = localStorage.getItem('ever-earn-chain')
        ? localStorage.getItem('ever-earn-chain') : "bsc"

    const [isBscDashboard, setIsBscDashboard] = useState(false)

    useEffect(() => {
        if (currentChain) {
            console.log('currentChainDash', currentChain);
            if (currentChain === 'bsc') {
                setIsBscDashboard(true)
            } else {
                setIsBscDashboard(false)
            }
        }
    }, [currentChain])

    return (
        <>
            {
                isBscDashboard ? (
                    <DashboardBSC />
                ) : (
                    <RewardDashboard />
                )
            }
        </>
    )
}

export default Dashboard