import { useEffect, useState } from "react"
import * as multiChainService from "../../blockchain/services/multichain.service"

export const useTokenPrice = () => {

  const [tokenPrice, setTokenPrice] = useState(0.0)
  const [isLoading, setLoading] = useState(false)


  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        //get the token main token price in usd
        const tokenPriceInUSD = await multiChainService.getTokenPriceInUSDByTokenAddress(currentChain)
        if (tokenPriceInUSD) {
          setTokenPrice(tokenPriceInUSD)
        } else {
          setTokenPrice(0.0)
        }
      } catch (error) {
        setTokenPrice(0.0)
        console.error('error while fetching token price ', error.message || '')
      } finally {
        setLoading(false)
      }

    }
    fetchData();

  }, [currentChain])


  return { tokenPrice, isLoading }
}

export default useTokenPrice

