import React, { useState, useEffect } from 'react'
import * as web3Service from '../services/staking.service'
import EventEmitter from '../../../utils/EventEmitter'
import { Card } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useSelector } from "react-redux"

export default function StakingApyWidget(props) {

  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const { contractAddress, days } = props

  const [isRemainingPoolValueLoading, setIsRemainingPoolValueLoading] = useState(false)
  const [remainingPoolValue, setRemainingPoolValue] = useState(0)
  const [isAprDataLoading, setIsAprDataLoading] = useState(false)
  const [aprValue, setAprValue] = useState(0.0)

  const { currentChain } = useSelector((state) => state.chainSelector)
  const [rewardSymbol, setRewardSymbol] = useState('')

  useEffect(() => {
      if (currentChain) {
          if (currentChain === 'bsc') {
              setRewardSymbol('BUSD')
          } else {
              setRewardSymbol('USDC')
          }
      }
  }, [currentChain])

  const [logs, setLogs] = useState(null)

  useEffect(() => {
    const onNewLogs = (eventData) => {
      setLogs(eventData)
    }

    const listener = EventEmitter.addListener('refreshEvent', onNewLogs)

    return () => {
      listener.remove()
    }
  }, [])


  const loadAprData = async () => {
    setIsAprDataLoading(true)
    const aprValue = await web3Service.calculateAprDataUsingContractAddress(contractAddress)
    setAprValue(aprValue.toFixed(2))
    setIsAprDataLoading(false)
  }

  const getRemainingPoolValue = async () => {
    setIsRemainingPoolValueLoading(true)
    const remainingPoolValue = await web3Service.calculateRemainingPoolValueByContractAddress(contractAddress)
    setRemainingPoolValue(remainingPoolValue || 0.0)
    setIsRemainingPoolValueLoading(false)
  }

  useEffect(() => {
    loadAprData()
    getRemainingPoolValue()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadAprData()
    getRemainingPoolValue()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs])


  return (
    <div>
      <div className='d-flex'>
        <span className='staking-remaining-busd'>
          Remaining {rewardSymbol} value in the pool : 
        </span>
        <span className="apy-remaining-pool-value">
          <NumberFormat
              value={remainingPoolValue ? remainingPoolValue : 0.0}
              displayType="text"
              decimalScale={3}
              thousandSeparator={true}
          />
        </span>
      </div>
    </div>
  )
}
