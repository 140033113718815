import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentChain: 'BSC',
  isChangedChain: false
};

export const ChainSelectorSlice = createSlice({
  name: "chainSelector",
  initialState,
  reducers: {
    handleChainSelect: (state, { payload }) => {
      localStorage.setItem('ever-earn-chain', payload)
      state.currentChain = payload
    },
    changedChain: (state) => {
      state.isChangedChain = true
  },
  }
})

// Action creators are generated for each case reducer function
export const { handleChainSelect, changedChain } = ChainSelectorSlice.actions;

export default ChainSelectorSlice.reducer;