import { useEffect, useState } from "react"
import * as multiChainService from "../blockchain/services/multichain.service"

export const useTokenMetadataInfo = () => {

  const [tokenMetaDataInfo, setTokenMetaDataInfo] = useState({})
  const [isLoading, setLoading] = useState(false)

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {

    async function fetchData() {
      try {

        setLoading(true)
        //get the token main token price in usd
        const tokenMetadataInfoResponse = await multiChainService.getMainTokenMetaDataInfo(currentChain)
        if (tokenMetadataInfoResponse) {
          setTokenMetaDataInfo(tokenMetadataInfoResponse)
        } else {
          setTokenMetaDataInfo({})
        }
      } catch (error) {
        setTokenMetaDataInfo({})
        console.error('error while fetching token metadata ', error.message || '')
      } finally {
        setLoading(false)
      }

    }
    fetchData();

  }, [])


  return { tokenMetaDataInfo, isLoading }
}

export default useTokenMetadataInfo

