import { multiChainConfigs } from "../configs/multichain.configs"
import { BigNumber, ethers, utils } from "ethers"

export const getWeb3PRovider = (chainType = 'bsc') => {
  const { web3Config } = multiChainConfigs
  let rpcURL = ''
  switch (chainType) {
    case 'bsc':
      rpcURL = web3Config.webRpcHttpNodeForBsc
      break;
    case 'eth':
      rpcURL = web3Config.webRpcHttpNodeForEth
      break;
    default:
      rpcURL = web3Config.webRpcHttpNodeForBsc
  }
  const web3 = new ethers.providers.JsonRpcProvider(rpcURL)
  return web3
}

export const getNativeCurrencyPriceInUSD = async (chainType = 'bsc', blockNumber = 'latest') => {

  try {
    const { web3Config } = multiChainConfigs
    let exchangeRouterContractAbi = null
    let exchangeRouterAddress = null
    const provider = getWeb3PRovider(chainType)
    let nativeWrappedTokenAddress = null
    let usdTokenAddress = null
    let nativeCurrencyDecimals = 1

    switch (chainType) {
      case 'bsc':
        exchangeRouterContractAbi = JSON.parse(web3Config.pancakeSwapContractAbi)
        exchangeRouterAddress = web3Config.pricingAddress.pancakeSwapContractAddress
        nativeWrappedTokenAddress = web3Config.pricingAddress.bnbTokenAddress
        usdTokenAddress = web3Config.pricingAddress.usdTokenAddress
        nativeCurrencyDecimals = 18
        break;
      case 'eth':
        exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi)
        exchangeRouterAddress = web3Config.pricingAddress.uniswapContractAddress
        nativeWrappedTokenAddress = web3Config.pricingAddress.ethTokenAddress
        usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForEth
        nativeCurrencyDecimals = 6
        break;
      case 'matic':
        exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi)
        exchangeRouterAddress = web3Config.pricingAddress.quickSwapContractAddress
        nativeWrappedTokenAddress = web3Config.pricingAddress.maticTokenAddress
        usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForMatic
        nativeCurrencyDecimals = 18
        break;
      default:
        exchangeRouterContractAbi = JSON.parse(web3Config.uniswapContractAbi)
        exchangeRouterAddress = web3Config.pricingAddress.quickSwapContractAddress
        nativeWrappedTokenAddress = web3Config.pricingAddress.maticTokenAddress
        usdTokenAddress = web3Config.pricingAddress.usdTokenAddressForMatic
        nativeCurrencyDecimals = 18
        break;
    }

    const exchangeContractInstance = new ethers.Contract(
      exchangeRouterAddress,
      exchangeRouterContractAbi,
      provider
    )

    let amountOut = 0
    let tokensToSell = ethers.utils.parseEther("1.0")
    amountOut = await exchangeContractInstance.getAmountsOut(tokensToSell.toBigInt(), [nativeWrappedTokenAddress, usdTokenAddress], { blockTag: blockNumber })
    amountOut = ethers.utils.formatUnits(amountOut[1], nativeCurrencyDecimals)
    return amountOut.toString()
  } catch (error) {
    console.log("ERROR while fetching native token price ", error)
    console.log("ERROR chain type ", chainType)
    return 0.0
  }
}

export const getTokenPriceInUSDByTokenAddress = async (chainType = 'bsc', tokenAddress = null) => {
  const { web3Config } = multiChainConfigs
  let nativeCurrencyTokenAddress = null
  let dexFactoryAddress = null
  let dexFactoryBI = null
  let primaryTokenAddress = null
  const erc20ContractABI = JSON.parse(web3Config.mainContractAbi)
  const provider = getWeb3PRovider(chainType)
  let tokenPrice = 0
  const nativeCurrencyPrice = await getNativeCurrencyPriceInUSD(chainType)
  try {
    switch (chainType) {
      case 'bsc':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.bnbTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.pancakeSwapContractAddress
        dexFactoryBI = web3Config.uniswapContractAbi
        primaryTokenAddress = tokenAddress ? tokenAddress : web3Config.contractWallets.main
        break;
      case 'eth':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.ethTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.uniswapContractAddress
        dexFactoryBI = web3Config.uniswapContractAbi
        primaryTokenAddress = tokenAddress ? tokenAddress : web3Config.ETHcontractWallets.main
        break;
      case 'matic':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.maticTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.quickSwapContractAddress
        dexFactoryBI = web3Config.quickSwapContractAbi
        primaryTokenAddress = tokenAddress ? tokenAddress : web3Config.contractWallets.main
        break;
      default:
        nativeCurrencyTokenAddress = web3Config.pricingAddress.bnbTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.pancakeSwapContractAddress
        dexFactoryBI = web3Config.pancakeSwapFactoryContractAbi
        primaryTokenAddress = tokenAddress ? tokenAddress : web3Config.contractWallets.main
        break;
    }
    const erc20ContractInstance = new ethers.Contract(primaryTokenAddress, erc20ContractABI, provider)
    const tokenDecimals = await erc20ContractInstance.decimals()
    const tokenToSell = utils.parseUnits("1", parseInt(tokenDecimals))

    const dexContractInstance = new ethers.Contract(dexFactoryAddress, dexFactoryBI, provider)
    const tokenAmountResponse = await dexContractInstance.getAmountsOut(tokenToSell, [primaryTokenAddress, nativeCurrencyTokenAddress])
    // no getAmountsOut in pancakeSwapFactoryContractAbi
    const formattedTokenPriceResponse = utils.formatEther(tokenAmountResponse[1].toString())
    tokenPrice = parseFloat(formattedTokenPriceResponse.toString()) * parseFloat(nativeCurrencyPrice)
    console.log('tokenPrice', tokenPrice);
    return tokenPrice
  } catch (error) {
    console.log("while fetching token price in USD ", error)
    console.log("chain type ", chainType)
    return 0.0
  }
}

export const getTokenMarketCapIUnUSDByTokenAddress = async (chainType) => {
  const { web3Config } = multiChainConfigs
  let nativeCurrencyTokenAddress = null
  let contractABI = null

  const provider = getWeb3PRovider(chainType)
  try {
    switch (chainType) {
      case 'bsc':
        nativeCurrencyTokenAddress = web3Config.contractWallets.main
        contractABI = JSON.parse(web3Config.mainContractAbi)
        break;
      case 'eth':
        nativeCurrencyTokenAddress = web3Config.ETHcontractWallets.main
        contractABI = JSON.parse(web3Config.ETHContractAbi)
        break;
      case 'matic':
        nativeCurrencyTokenAddress = web3Config.contractWallets.main
        contractABI = JSON.parse(web3Config.mainContractAbi)
        break;
      default:
        nativeCurrencyTokenAddress = web3Config.contractWallets.main
        contractABI = JSON.parse(web3Config.mainContractAbi)
        break;
    }

    const erc20ContractInstance = new ethers.Contract(nativeCurrencyTokenAddress, contractABI, provider)
    const tokenDecimals = await erc20ContractInstance.decimals()
    const tokenTotalSupplyUnformatted = await erc20ContractInstance.totalSupply()
    const tokenTotalSupply = utils.formatUnits(tokenTotalSupplyUnformatted, parseInt(tokenDecimals.toString()))

    const burnAddressBalance1 = await erc20ContractInstance.balanceOf('0x000000000000000000000000000000000000dead')
    const burnAddressBalance2 = await erc20ContractInstance.balanceOf('0x0000000000000000000000000000000000000000')

    const totalBurnedTokenBalance = burnAddressBalance1.add(burnAddressBalance2)
    const actualBurnedTokenBalance = utils.formatUnits(totalBurnedTokenBalance, parseInt(tokenDecimals.toString()))
    const circulationSupply = parseFloat(tokenTotalSupply) - parseFloat(actualBurnedTokenBalance)
    const tokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(chainType)
    const marketCapInUSD = circulationSupply * tokenPriceInUSD
    return marketCapInUSD

  } catch (error) {
    console.log("while calculating token market cap value in usd ", error)
    console.log("chain type ", chainType)
    return 0.0
  }
}

export const getERC20TokenPriceInUSDByAddress = async (tokenAddress) => {
  try {
    if (tokenAddress) {
      const bnbPriceInUSD = await getBnbPrice(tokenAddress)
      const tokenPrice = await getTokenPriceInUSDByTokenAddress(tokenAddress)
      const tokenPrinceInUSD = bnbPriceInUSD * tokenPrice
      console.log('bnbPriceInUSD', bnbPriceInUSD);
      return tokenPrinceInUSD
    } else {
      return 0.0
    }
  } catch (error) {
    return 0.0
  }
}

export const getBnbPrice = async (chainType = 'bsc', tokenAddress) => {
  const { web3Config } = multiChainConfigs
  const provider = getWeb3PRovider(chainType)
  const contractAbi = JSON.parse(web3Config.pancakeSwapContractAbi)

  const pcsContract = await new window.web3Instance.eth.Contract(contractAbi, web3Config.pricingAddress.pancakeSwapContractAddress)

  const bnbToSell = window.web3Instance.utils.toWei('1', 'ether')

  let amountOut

  try {
    amountOut = await pcsContract.methods.getAmountsOut(bnbToSell, [
      web3Config.pricingAddress.bnbTokenAddress,
      web3Config.pricingAddress.usdTokenAddress
    ])
      .call()

    amountOut = window.web3Instance.utils.fromWei(amountOut[1])
    console.log('amountOut', amountOut);
  } catch (error) {
    amountOut = '0'
  }
  return parseFloat(amountOut)
}

export const calculateLiquidityInfoByContractAddress = async (contractAddress, chainType = 'bsc') => {

  const { web3Config } = multiChainConfigs
  let nativeCurrencyTokenAddress = null
  let dexFactoryAddress = null
  let dexFactoryBI = null
  const erc20ContractABI = JSON.parse(web3Config.mainContractAbi)

  const provider = getWeb3PRovider(chainType)
  let tokenPrice = 0
  const nativeCurrencyPrice = await getNativeCurrencyPriceInUSD(chainType)
  try {
    switch (chainType) {
      case 'bsc':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.bnbTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.pancakeSwapContractAddress
        dexFactoryBI = web3Config.pancakeSwapFactoryContractAbi
        break;
      case 'eth':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.ethTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.uniswapContractAddress
        dexFactoryBI = web3Config.uniswapContractAbi
        break;
      case 'matic':
        nativeCurrencyTokenAddress = web3Config.pricingAddress.maticTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.quickSwapContractAddress
        dexFactoryBI = web3Config.quickSwapContractAbi
        break;
      default:
        nativeCurrencyTokenAddress = web3Config.pricingAddress.bnbTokenAddress
        dexFactoryAddress = web3Config.pricingAddress.pancakeSwapContractAddress
        dexFactoryBI = web3Config.pancakeSwapFactoryContractAbi
        break;
    }
    const erc20ContractInstance = new ethers.Contract(contractAddress, erc20ContractABI, provider)

    const tokenDecimals = await erc20ContractInstance.decimals()
    // try {

    //find the liquidity pair pairs
    const liquidityPairAddress = await getPairAddressByContractAddress(contractAddress)
    const liquidityContractAbi = JSON.parse(web3Config.lpTokenContractAbi)
    const liquidityPoolDataList = []
    let totalLiquidityPoolTokenBalanceInUSD = 0
    let totalLiquidityPoolBalanceInUSDOtherPair = 0
    let totalLiquidityCumulativeBalanceInUSD = 0
    //check liquidity pool availability and get the balance of each pool
    if (liquidityPairAddress.BUSDPairAddress !== '') {

      const busdLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.BUSDPairAddress)
      const reserveData = await busdLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let busdBalance = 0
      let busdTokenAddress = ''
      const tokenOne = await busdLiquidityInstance.methods.token0().call()
      const tokenTwo = await busdLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        busdBalance = reserveData[1]
        busdTokenAddress = tokenTwo
      } else {
        busdTokenAddress = tokenOne
        tokenBalance = reserveData[1]
        busdBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const busdTokenDecimals = await getTokenDecimals(busdTokenAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const busdPriceInUSD = await getERC20TokenPriceInUSDByAddress(busdTokenAddress)
      const actualBusdPoolBalance = parseInt(busdBalance) / (10 ** busdTokenDecimals)
      const busdPoolBalanceInUSD = actualBusdPoolBalance * busdPriceInUSD

      const payload = {
        pairName: 'BUSDPair',
        pairAddress: liquidityPairAddress.BUSDPairAddress,
        tokenAmount: actualTokenBalance,
        busdAmount: actualBusdPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        busdBalanceInUSD: busdPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + busdPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }


    if (liquidityPairAddress.WBNBPairAddress !== '') {

      const wBnbLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.WBNBPairAddress)
      const reserveData = await wBnbLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let wBnbBalance = 0
      let wBnbAddress = ''
      const tokenOne = await wBnbLiquidityInstance.methods.token0().call()
      const tokenTwo = await wBnbLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        wBnbBalance = reserveData[1]
        wBnbAddress = tokenTwo
      } else {
        wBnbAddress = tokenOne
        tokenBalance = reserveData[1]
        wBnbBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const wBnbTokenDecimals = await getTokenDecimals(wBnbAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const wBnbPriceInUSD = await getBnbPrice()
      const actualWbnbPoolBalance = parseInt(wBnbBalance) / (10 ** wBnbTokenDecimals)
      const wBnbPoolBalanceInUSD = actualWbnbPoolBalance * wBnbPriceInUSD

      const payload = {
        pairName: 'WBNBPair',
        pairAddress: liquidityPairAddress.WBNBPairAddress,
        tokenAmount: actualTokenBalance,
        wbnbAmount: actualWbnbPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        wBnbBalanceInUSD: wBnbPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + wBnbPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }

    if (liquidityPairAddress.USDTPairAddress !== '') {

      const usdtLiquidityInstance = await new window.web3Instance.eth.Contract(liquidityContractAbi, liquidityPairAddress.USDTPairAddress)
      const reserveData = await usdtLiquidityInstance.methods.getReserves().call()
      let tokenBalance = 0
      let usdtBalance = 0
      let usdtAddress = ''
      const tokenOne = await usdtLiquidityInstance.methods.token0().call()
      const tokenTwo = await usdtLiquidityInstance.methods.token1().call()
      if (tokenOne.toLowerCase() === web3Config.contractWallets.main.toLowerCase()) {
        tokenBalance = reserveData[0]
        usdtBalance = reserveData[1]
        usdtAddress = tokenTwo
      } else {
        usdtAddress = tokenOne
        tokenBalance = reserveData[1]
        usdtBalance = reserveData[0]
      }

      const tokenDecimals = await getTokenDecimals(web3Config.contractWallets.main)
      const usdtTokenDecimals = await getTokenDecimals(usdtAddress)
      const tokenPriceInUSD = await getERC20TokenPriceInUSDByAddress(web3Config.contractWallets.main)


      const actualTokenBalance = parseInt(tokenBalance) / (10 ** tokenDecimals)
      const tokenPoolDataBalanceInUSD = actualTokenBalance * tokenPriceInUSD

      const usdtPriceInUSD = await getERC20TokenPriceInUSDByAddress(usdtAddress)
      const actualUsdtPoolBalance = parseInt(usdtBalance) / (10 ** usdtTokenDecimals)
      const usdtPoolBalanceInUSD = actualUsdtPoolBalance * usdtPriceInUSD

      const payload = {
        pairName: 'USDTPair',
        pairAddress: liquidityPairAddress.USDTPairAddress,
        tokenAmount: actualTokenBalance,
        usdAmount: actualUsdtPoolBalance,
        tokenBalanceInUSD: tokenPoolDataBalanceInUSD,
        wBnbBalanceInUSD: usdtPoolBalanceInUSD

      }

      totalLiquidityPoolTokenBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + tokenPoolDataBalanceInUSD
      totalLiquidityPoolBalanceInUSDOtherPair = totalLiquidityPoolBalanceInUSDOtherPair + usdtPoolBalanceInUSD
      liquidityPoolDataList.push(payload)

    }

    totalLiquidityCumulativeBalanceInUSD = totalLiquidityPoolTokenBalanceInUSD + totalLiquidityPoolBalanceInUSDOtherPair

    return {
      liquidityPoolDataList,
      totalLiquidityPoolTokenBalanceInUSD,
      totalLiquidityPoolBalanceInUSDOtherPair,
      totalLiquidityCumulativeBalanceInUSD: totalLiquidityCumulativeBalanceInUSD,

    }

  } catch (error) {
    console.error("ERROR : while calculating liquidity data ", error)
    return {
      liquidityPoolDataList: [],
      totalLiquidityPoolTokenBalanceInUSD: 0,
      totalLiquidityPoolBalanceInUSDOtherPair: 0,
      totalLiquidityCumulativeBalanceInUSD: 0,
    }
  }
}

export const getPairAddressByContractAddress = async (contractAddress) => {

  const { web3Config } = multiChainConfigs

  let BUSDPairAddress = ''
  let WBNBPairAddress = ''
  let USDTPairAddress = ''

  try {
    if (contractAddress) {
      const pcsFactoryContractABI = JSON.parse(web3Config.pancakeSwapFactoryContractAbi)
      const pcsFactoryAddress = web3Config.pricingAddress.pancakeSwapFactoryAddress
      const pcsFactoryContractInstance = await new window.web3Instance.eth.Contract(pcsFactoryContractABI, pcsFactoryAddress)

      //get the numbers of pairs 
      const BUSDAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
      const WBNBAdrress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
      const USDTAddress = '0x55d398326f99059fF775485246999027B3197955'

      const busdPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, BUSDAddress).call()
      const wbnbPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, WBNBAdrress).call()
      const usdtPairResponse = await pcsFactoryContractInstance.methods.getPair(contractAddress, USDTAddress).call()

      if (busdPairResponse !== '0x0000000000000000000000000000000000000000') {
        BUSDPairAddress = busdPairResponse
      }

      if (wbnbPairResponse !== '0x0000000000000000000000000000000000000000') {
        WBNBPairAddress = wbnbPairResponse
      }

      if (usdtPairResponse !== '0x0000000000000000000000000000000000000000') {
        USDTPairAddress = usdtPairResponse
      }

      return {
        BUSDPairAddress,
        WBNBPairAddress,
        USDTPairAddress
      }
    } else {
      return {
        BUSDPairAddress,
        WBNBPairAddress,
        USDTPairAddress
      }
    }

  } catch (error) {
    console.error("ERROR while fetching token circulation supply : ", error)
    return {
      BUSDPairAddress,
      WBNBPairAddress,
      USDTPairAddress
    }
  }
}

export const getTokenDecimals = async (contractAddress) => {
  const { web3Config } = multiChainConfigs
  try {
    const erc20ContractAbi = JSON.parse(web3Config.mainContractAbi)
    const erc20ContractInstance = await new window.web3Instance.eth.Contract(erc20ContractAbi, contractAddress)
    const tokenDecimals = await erc20ContractInstance.methods.decimals().call()
    return parseInt(tokenDecimals)
  } catch (error) {
    console.error("ERROR : while fetching token decimals ", error)
    return 1
  }
}

export const getTotalDividendPaidByAddress = async (chainType = 'bsc') => {

  const { web3Config } = multiChainConfigs

  let nativeCurrencyTokenAddress = null
  let contractABI = null
  let dividendTokenAddress = null
  const provider = getWeb3PRovider(chainType)

  switch (chainType) {
    case 'bsc':
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.mainContractAbi)
      break
    case 'eth':
      nativeCurrencyTokenAddress = web3Config.ETHcontractWallets.main
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.ETHContractAbi)
      break
    default:
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      contractABI = JSON.parse(web3Config.mainContractAbi)
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      break
  }

  try {

    const erc20ContractInstance = new ethers.Contract(nativeCurrencyTokenAddress, contractABI, provider)
    const totalDividendsAmount = await erc20ContractInstance.totalDistributedRewards()

    const dividendTokenContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)
    const dividendTokenDecimals = await dividendTokenContractInstance.decimals()
    const dividendTokenSymbol = await dividendTokenContractInstance.symbol()
    const dividendTokenName = await dividendTokenContractInstance.name()

    const actualDividendAmount = utils.formatUnits(totalDividendsAmount.toString(), parseInt(dividendTokenDecimals.toString()))
    //get dividend token price
    const dividendTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(chainType, dividendTokenAddress)
    const totalDistributedDividendAmountInUSD = dividendTokenPriceInUSD * parseFloat(actualDividendAmount.toString())

    return {
      dividendTokenSymbol,
      dividendTokenName,
      dividendTokenPriceInUSD: dividendTokenPriceInUSD ? dividendTokenPriceInUSD : 0.0,
      totalDistributedDividendAmountInUSD: totalDistributedDividendAmountInUSD ? totalDistributedDividendAmountInUSD : 0.0,
      totalDividendsAmount: totalDividendsAmount ? totalDividendsAmount.toString() : 0.0
    }
  } catch (error) {
    console.log("ERROR while fetching dividend token details and distribution amount ", error)
    return {
      dividendTokenSymbol: '',
      dividendTokenName: '',
      dividendTokenPriceInUSD: 0.0,
      totalDistributedDividendAmountInUSD: 0.0,
      totalDividendsAmount: 0.0
    }
  }
}


export const getTokenBalanceByUserWalletAddress = async (chainType = 'bsc', userWalletAddress) => {

  const { web3Config } = multiChainConfigs

  let nativeCurrencyTokenAddress = null
  let contractABI = null
  const provider = getWeb3PRovider(chainType)

  switch (chainType) {
    case 'bsc':
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      contractABI = JSON.parse(web3Config.mainContractAbi)
      break
    case 'eth':
      nativeCurrencyTokenAddress = web3Config.ETHcontractWallets.main
      contractABI = JSON.parse(web3Config.ETHContractAbi)
      break
    default:
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      contractABI = JSON.parse(web3Config.mainContractAbi)
      break
  }

  try {
    if (userWalletAddress) {
      const erc20ContractInstance = new ethers.Contract(nativeCurrencyTokenAddress, contractABI, provider)
      const tokenBalance = await erc20ContractInstance.balanceOf(userWalletAddress)
      const tokenDecimals = await erc20ContractInstance.decimals()
      const actualTokenBalance = utils.formatUnits(tokenBalance.toString(), parseInt(tokenDecimals))
      return actualTokenBalance
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR while fetching user token balance ", error)
    return 0.0
  }
}

export const getUserDividendsEarningByWalletAddress = async (chainType = 'bsc', walletAddress) => {
  const { web3Config } = multiChainConfigs

  let dividendTrackerAddress = null
  let contractABI = null
  let dividendTrackerABI = null
  let dividendTokenAddress = null
  const provider = getWeb3PRovider(chainType)

  switch (chainType) {
    case 'bsc':
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.mainContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    case 'eth':
      dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.ETHContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    default:
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.mainContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
  }

  try {

    if (walletAddress) {
      const dividendTrackerInstance = new ethers.Contract(dividendTrackerAddress, dividendTrackerABI, provider)
      const sharesDetails = await dividendTrackerInstance.shares(walletAddress)
      const earnedValue = sharesDetails[2]
      //create instance of dividend token 
      const erc20ContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)
      //get the decimal value of the token
      const tokenDecimals = await erc20ContractInstance.decimals()
      const actualEarnTokenValue = utils.formatUnits(earnedValue.toString(), parseInt(tokenDecimals))
      return actualEarnTokenValue.toString()
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR: while fetching user dividend earning ", error)
    return 0.0
  }
}

export const getUserUnpaidDividendsEarningByWalletAddress = async (chainType = 'bsc', walletAddress) => {
  const { web3Config } = multiChainConfigs

  let dividendTrackerAddress = null
  let contractABI = null
  let dividendTrackerABI = null
  let dividendTokenAddress = null
  const provider = getWeb3PRovider(chainType)

  switch (chainType) {
    case 'bsc':
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.mainContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    case 'eth':
      dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.ETHContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    default:
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      contractABI = JSON.parse(web3Config.mainContractAbi)
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
  }

  try {

    if (walletAddress) {
      const dividendTrackerInstance = new ethers.Contract(dividendTrackerAddress, dividendTrackerABI, provider)
      const unpaidEarning = await dividendTrackerInstance.getUnpaidEarnings(walletAddress)

      const erc20ContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)
      const tokenDecimals = await erc20ContractInstance.decimals()
      const actualUnpaidEarning = utils.formatUnits(unpaidEarning.toString(), parseInt(tokenDecimals))
      return actualUnpaidEarning.toString()
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR: while fetching user dividend unpaid earning ", error)
    return 0.0
  }
}

export const getTokenDividendInfoByUserWalletAddress = async (chainType = 'bsc', walletAddress) => {
  const { web3Config } = multiChainConfigs
  let dividendTrackerAddress = null
  let dividendTrackerABI = null
  let dividendTokenAddress = null

  const contractABI = JSON.parse(web3Config.mainContractAbi)
  const provider = getWeb3PRovider(chainType)
  switch (chainType) {
    case 'bsc':
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    case 'eth':
      dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    default:
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
  }

  try {

    if (walletAddress) {
      const dividendTrackerInstance = new ethers.Contract(dividendTrackerAddress, dividendTrackerABI, provider)
      const tokenDividendInfo = await dividendTrackerInstance.getHolderDetails(walletAddress)
      const paidAmount = tokenDividendInfo[2]
      const erc20ContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)
      const tokenDecimals = await erc20ContractInstance.decimals()
      const totalPaidAmount = utils.formatUnits(paidAmount.toString(), parseFloat(tokenDecimals))
      const lastPaidAtTimestamp = parseInt(tokenDividendInfo[0])

      return {
        totalPaidAmount,
        lastPaidAtTimestamp
      }
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR: while fetching user dividend holder info ", error)
    return 0.0
  }
}

export const getMainTokenMetaDataInfo = async (chainType = 'bsc') => {
  const { web3Config } = multiChainConfigs

  let nativeCurrencyTokenAddress = null
  let contractABI = null
  const provider = getWeb3PRovider(chainType)

  switch (chainType) {
    case 'bsc':
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      contractABI = JSON.parse(web3Config.mainContractAbi)
      break;
    case 'eth':
      nativeCurrencyTokenAddress = web3Config.ETHcontractWallets.main
      contractABI = JSON.parse(web3Config.ETHContractAbi)
      break;
    default:
      nativeCurrencyTokenAddress = web3Config.contractWallets.main
      contractABI = JSON.parse(web3Config.mainContractAbi)
      break;
  }

  let mainTokenAddress = ''
  let dividendTrackerAddress = ''
  let dividendTokenAddress = ''
  let tokenName = ''
  let tokenSymbol = ''
  let tokenTotalSupply = 0.0
  let tokenDecimals = 0
  let tokenBurnedAmount = 0.0
  let tokenBurnedPercentage = 0.0
  let tokenCirculationSupply = 0.0
  let tokenMarketCap = 0.0
  let tokenPrice = 0.0
  try {

    mainTokenAddress = nativeCurrencyTokenAddress
    dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
    dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress

    const erc20ContractInstance = new ethers.Contract(nativeCurrencyTokenAddress, contractABI, provider)
    tokenName = await erc20ContractInstance.name()
    tokenSymbol = await erc20ContractInstance.symbol()
    const totalSupplyUnformatted = await erc20ContractInstance.totalSupply()
    tokenDecimals = await erc20ContractInstance.decimals()
    tokenPrice = await getTokenPriceInUSDByTokenAddress(chainType)
    tokenTotalSupply = utils.formatUnits(totalSupplyUnformatted.toString(), parseInt(tokenDecimals))


    const burnAddressBalance1 = await erc20ContractInstance
      .balanceOf('0x000000000000000000000000000000000000dead')
    const burnAddressBalance2 = await erc20ContractInstance
      .balanceOf('0x0000000000000000000000000000000000000000')

    const totalBurnedTokenBalance = burnAddressBalance1.add(burnAddressBalance2)
    const actualBurnedTokenBalance = utils.formatUnits(totalBurnedTokenBalance.toString(), parseInt(tokenDecimals))

    tokenBurnedAmount = actualBurnedTokenBalance
    tokenBurnedPercentage = (tokenBurnedAmount / tokenTotalSupply) * 100

    tokenCirculationSupply = tokenTotalSupply - tokenBurnedAmount
    tokenMarketCap = tokenCirculationSupply * tokenPrice

    return {
      mainTokenAddress,
      dividendTrackerAddress,
      dividendTokenAddress,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenDecimals,
      tokenBurnedAmount,
      tokenBurnedPercentage,
      tokenCirculationSupply,
      tokenMarketCap,
      tokenPrice
    }
  } catch (error) {
    return {
      mainTokenAddress,
      dividendTrackerAddress,
      dividendTokenAddress,
      tokenName,
      tokenSymbol,
      tokenTotalSupply,
      tokenDecimals,
      tokenBurnedAmount,
      tokenBurnedPercentage,
      tokenCirculationSupply,
      tokenMarketCap,
      tokenPrice
    }
  }
}

export const getRewardsInfoDetails = async (chainType = 'bsc') => {

  let rewardsTrackerContractAddress = ''
  let totalRewardsDistributed = 0.0
  let totalRewardsDistributedInUSD = 0.0
  let rewardsCycleInHours = 0
  let totalRewardsHolders = 0
  let currentProcessingIndex = 0
  let pendingRewardsHoldersCount = 0
  let completionRatioPercentage = 0
  const lastProcessTimestamp = 0

  const { web3Config } = multiChainConfigs

  let dividendTokenAddress = null
  let mainTokenAddress = null
  const contractABI = JSON.parse(web3Config.mainContractAbi)
  const provider = getWeb3PRovider(chainType)
  switch (chainType) {
    case 'bsc':
      mainTokenAddress = web3Config.contractWallets.main
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      break
    case 'eth':
      mainTokenAddress = web3Config.ETHcontractWallets.main
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      break
    default:
      mainTokenAddress = web3Config.contractWallets.main
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      break
  }

  try {
    rewardsTrackerContractAddress = dividendTokenAddress
    const erc20ContractInstance = new ethers.Contract(mainTokenAddress, contractABI, provider)
    const dividendTokenContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)
    const dividedTokenDecimals = await dividendTokenContractInstance.decimals()

    const totalDividendDistributedUnformatted = await erc20ContractInstance.totalDistributedRewards()

    const totalRewardsDistributedResponse = utils.formatUnits(totalDividendDistributedUnformatted.toString(), parseInt(dividedTokenDecimals))
    totalRewardsDistributed = totalRewardsDistributedResponse.toString()

    const dividendTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(chainType, dividendTokenAddress)
    totalRewardsDistributedInUSD = parseFloat(totalRewardsDistributed) * parseFloat(dividendTokenPriceInUSD)

    rewardsCycleInHours = 1

    const totalRewardsHoldersResponse = await erc20ContractInstance.getNumberOfTokenHolders()
    totalRewardsHolders = totalRewardsHoldersResponse.toString()

    const currentProcessingIndexResponse = await erc20ContractInstance.getLastProcessedIndex()
    currentProcessingIndex = currentProcessingIndexResponse.toString()

    pendingRewardsHoldersCount = parseFloat(totalRewardsHolders) - parseFloat(currentProcessingIndex)
    completionRatioPercentage = (parseFloat(currentProcessingIndex) / parseFloat(totalRewardsHolders)) * 100

    return {
      rewardsTrackerContractAddress,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      rewardsCycleInHours,
      totalRewardsHolders,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      lastProcessTimestamp
    }
  } catch (error) {
    return {
      rewardsTrackerContractAddress,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      rewardsCycleInHours,
      totalRewardsHolders,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      lastProcessTimestamp
    }
  }
}

export const getUserRewardsDetails = async (chainType = 'bsc', userWalletAddress = null) => {

  let rewardsTrackerContractAddress = ''
  let rewardsTokenSymbol = ''
  let rewardTokenPendingBalance = 0.0
  let rewardTokenPendingBalanceInUSD = 0.0
  let totalRewardsDistributed = 0
  let totalRewardsDistributedInUSD = 0
  let currentProcessingIndex = 0
  let pendingRewardsHoldersCount = 0
  let completionRatioPercentage = 0
  let userLastPayoutTime = 0
  let userRewardsTotal = 0.0
  let userRewardsTotalInUSD = 0.0
  let userClaimableTokenAmount = 0.0
  let userClaimableTokenAmountInUSD = 0.0
  let totalRewardsHolders = 0
  let userIndex = 0

  const { web3Config } = multiChainConfigs

  let dividendTokenAddress = null
  let mainTokenAddress = null
  let dividendTrackerAddress = null
  let dividendTrackerABI = null
  const contractABI = JSON.parse(web3Config.mainContractAbi)
  const provider = getWeb3PRovider(chainType)
  switch (chainType) {
    case 'bsc':
      mainTokenAddress = web3Config.contractWallets.main
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    case 'eth':
      mainTokenAddress = web3Config.ETHcontractWallets.main
      dividendTokenAddress = web3Config.ETHcontractWallets.dividendTokenAddress
      dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
    default:
      mainTokenAddress = web3Config.contractWallets.main
      dividendTokenAddress = web3Config.contractWallets.dividendTokenAddress
      dividendTrackerAddress = web3Config.contractWallets.dividendTrackerAddress
      dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      break
  }

  try {

    rewardsTrackerContractAddress = dividendTrackerAddress
    const erc20ContractInstance = new ethers.Contract(mainTokenAddress, contractABI, provider)
    const dividendTokenContractInstance = new ethers.Contract(dividendTokenAddress, contractABI, provider)

    const dividedTokenDecimals = await dividendTokenContractInstance.decimals()
    rewardsTokenSymbol = await dividendTokenContractInstance.symbol()

    const totalDividendDistributedUnformatted = await erc20ContractInstance.totalDistributedRewards()
    const totalRewardsDistributedResponse = utils.formatUnits(totalDividendDistributedUnformatted.toString(), parseInt(dividedTokenDecimals))
    totalRewardsDistributed = totalRewardsDistributedResponse.toString()

    const dividendTokenPriceInUSD = await getTokenPriceInUSDByTokenAddress(chainType, dividendTokenAddress)
    totalRewardsDistributedInUSD = parseFloat(totalRewardsDistributed) * parseFloat(dividendTokenPriceInUSD)

    //get the balance of dividend tracker
    const pendingTokenBalanceUnformatted = await dividendTokenContractInstance.balanceOf(rewardsTrackerContractAddress)
    const rewardTokenPendingBalanceResponse = utils.formatUnits(pendingTokenBalanceUnformatted.toString(), parseInt(dividedTokenDecimals))
    rewardTokenPendingBalance = rewardTokenPendingBalanceResponse.toString()

    rewardTokenPendingBalanceInUSD = parseFloat(rewardTokenPendingBalance) * parseFloat(dividendTokenPriceInUSD)

    if (userWalletAddress) {
      const userDividendTokenInfo = await erc20ContractInstance.getHolderDetails(userWalletAddress)
      userIndex = parseInt(userDividendTokenInfo[3])
      if (userDividendTokenInfo[1] === 0) {
        userClaimableTokenAmount = 0
      } else {
        const userClaimableTokenAmountResponse = utils.formatUnits(userDividendTokenInfo[1].toString(), parseInt(dividedTokenDecimals))
        userClaimableTokenAmount = userClaimableTokenAmountResponse.toString()
      }

      userClaimableTokenAmountInUSD = parseFloat(userClaimableTokenAmount) * parseFloat(dividendTokenPriceInUSD)
      const userRewardsTotalResponse = utils.formatUnits(userDividendTokenInfo[2].toString(), parseInt(dividedTokenDecimals))
      userRewardsTotal = userRewardsTotalResponse.toString()

      userRewardsTotalInUSD = parseFloat(userRewardsTotal) * parseFloat(dividendTokenPriceInUSD)
      userLastPayoutTime = parseInt(userDividendTokenInfo[0])
    }

    const totalRewardsHoldersResponse = await erc20ContractInstance.getNumberOfTokenHolders()
    totalRewardsHolders = totalRewardsHoldersResponse.toString()

    const currentProcessingIndexResponse = await erc20ContractInstance.getLastProcessedIndex()
    currentProcessingIndex = currentProcessingIndexResponse.toString()

    currentProcessingIndex = parseInt(currentProcessingIndex)
    pendingRewardsHoldersCount = parseFloat(totalRewardsHolders) - currentProcessingIndex
    completionRatioPercentage = (currentProcessingIndex / totalRewardsHolders) * 100

    return {
      rewardsTokenSymbol,
      rewardTokenPendingBalance,
      rewardTokenPendingBalanceInUSD,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      userLastPayoutTime,
      userRewardsTotal,
      userRewardsTotalInUSD,
      userClaimableTokenAmount,
      userClaimableTokenAmountInUSD,
      userIndex
    }
  } catch (error) {

    console.log('ERROR while calculating user rewards details ', error)
    return {
      rewardsTokenSymbol,
      rewardTokenPendingBalance,
      rewardTokenPendingBalanceInUSD,
      totalRewardsDistributed,
      totalRewardsDistributedInUSD,
      currentProcessingIndex,
      pendingRewardsHoldersCount,
      completionRatioPercentage,
      userLastPayoutTime,
      userRewardsTotal,
      userRewardsTotalInUSD,
      userClaimableTokenAmount,
      userClaimableTokenAmountInUSD,
      userIndex
    }
  }
}

export const getAnyTokenBalanceForGivenUser = async (chainType = 'bsc', tokenAddress = null, userWalletAddress = null) => {

  const { web3Config } = multiChainConfigs
  const provider = getWeb3PRovider(chainType)
  const contractABI = JSON.parse(web3Config.mainContractAbi)
  let userTokenBalance = 0.0
  try {

    if (tokenAddress && userWalletAddress) {
      const erc20ContractInstance = new ethers.Contract(tokenAddress, contractABI, provider)
      const tokenDecimals = await erc20ContractInstance.decimals()
      const tokenBalanceResponse = await erc20ContractInstance.balanceOf(userWalletAddress)
      const formattedUserTokenBalance = utils.formatUnits(tokenBalanceResponse, parseInt(tokenDecimals))
      return formattedUserTokenBalance.toString()
    } else {
      return userTokenBalance
    }

  } catch (error) {
    console.log("ERROR while fetching user token balance for given address ", error)
    return userTokenBalance
  }

}