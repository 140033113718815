import React, { useState, useEffect } from 'react'
import './HeaderComponent.css'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Button, Drawer, Modal, Select, Switch } from 'antd'
import MenuComponent from '../Menu/MenuComponent'
import Metamask from '../../images/MetaMask_Fox.png'
import WalletConnect from '../../images/walletconnect.png'
import { Copy, CheckCircle, ExternalLink } from 'react-feather'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import Logo from '../../images/everearn-logo.png'
import BSCLogo from '../../images/blockchains/binance.png'
import ETHLogo from '../../images/blockchains/etherum.png'
import { configs } from '../../blockchain/web3.config'
import { getChainNetworkByChainId } from '../../blockchain/utils/chainList'
import { injected } from '../../blockchain/connectors/metaMask'
import { wcConnector } from '../../blockchain/connectors/walletConnect'
import { useWeb3React } from '@web3-react/core'
import { MenuOutlined, DoubleLeftOutlined, WalletFilled } from '@ant-design/icons'
import { useSelector, useDispatch } from "react-redux";
import { change } from '../../Redux/SidemenuMargin';
import { open, close } from '../../Redux/WalletConnect'
import MainLogo from '../../images/everearn-logo.png'
import WebsiteSettings from '../WebsiteSettings/WebsiteSettings'
import ChangeBlockChain from './ChangeBlockChain'
import { useTranslation } from 'react-i18next';
import { handleChainSelect, changedChain } from '../../Redux/ChainSelector'
import { lightTheme, darkTheme } from '../../Redux/ThemeState';
import { useThemeSwitcher } from "react-css-theme-switcher";

function Header() {

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { margin } = useSelector((state) => state.margin)
  const { modal_opened } = useSelector((state) => state.connectModalState)
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hamburgerClass, setHamburgerClass] = useState('hamburger')

  const { switcher, currentTheme, themes } = useThemeSwitcher();

  const web3React = useWeb3React()
  const { account, deactivate } = web3React

  const [isDisconnectVisible, setIsDisconnectVisible] = useState(false)
  const [currentNetworkID, setCurrentNetworkID] = useState(0)
  const [networkName, setNetworkName] = useState('Unknown Network')
  const [addressCopy, setAddressCopy] = useState(false)
  const [dashboard, setDashboard] = useState('bsc')
  const [draweMargin, setDrawerMargin] = useState('0px')

  const handleLightTheme = () => {
    switcher({ theme: themes.light })
    dispatch(lightTheme())
}

const handleDarkTheme = () => {
    switcher({ theme: themes.dark })
    dispatch(darkTheme())
}

  const toggleTheme = () => {
    if (currentTheme === 'dark') {
        handleLightTheme()
    } else {
        handleDarkTheme()
    }
}

  const { Option } = Select;
  useEffect(() => {
    console.info("Network change ", networkName)
  }, [networkName])

  useEffect(() => {
    console.log('showMobileMenu: ', showMobileMenu)
  }, [showMobileMenu])

  useEffect(() => {
    if (!showMobileMenu) {
      setDrawerMargin('-250px')
    } else {
      setDrawerMargin('0px')
    }
  }, [showMobileMenu])

  //check current network if metamask installed and perform the switch network task
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', event => {
        const chainID = parseInt(event.toString(), 16)
        setCurrentNetworkID(chainID)
      });

      window.ethereum.request({ method: "eth_chainId" })
        .then(chainId => {
          setCurrentNetworkID(parseInt(chainId, 16))
        })

    }

  }, [])

  useEffect(() => {
    const network = getChainNetworkByChainId(currentNetworkID)
    console.log('currentNetworkID', currentNetworkID)
    setNetworkName(network ? network.chainName : 'Unknown Network')
    if (currentNetworkID !== 0) {
      const defaultChainId = configs.chainId
      if (defaultChainId !== currentNetworkID && account) {
        changeNetwork(defaultChainId)
      }
    } else {
      setNetworkName('Unknown Network')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNetworkID, account])


  const changeNetwork = async (chainID) => {
    const result = getChainNetworkByChainId(chainID)
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            ...result
          }
        ]
      });
    } catch (err) {

    }
  };

  const handleMetamaskConnect = async () => {
    try {
      await web3React.activate(injected)
      const loginPayload = {
        provider: 'injected',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {

      const loginPayload = {
        provider: 'injected',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } finally {
      dispatch(close())
    }
  }

  const handleWalletConnectConnect = async () => {
    try {
      await web3React.activate(wcConnector)
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.error('error connecting to wallet-connect ', error)
    } finally {
      dispatch(close())
    }
  }

  const handleDisconnect = () => {
    try {
      window.localStorage.removeItem("userData")
      deactivate()
      handleCancel()
    } catch (error) {
      console.error("error while logout from user ", error)
    }
  }

  const handleOk = () => {
    console.log('handleOk')
  };

  const handleCancel = () => {
    setIsDisconnectVisible(false)
  };

  const showDisconnectModal = () => {
    setIsDisconnectVisible(true)
  }


  const copyAddress = () => {
    setAddressCopy(true)
    navigator.clipboard.writeText(account)
  }

  const handleSideMenu = () => {
    if (hamburgerClass === "hamburger") {
      setHamburgerClass("hamburger is-active")
    } else {
      setHamburgerClass("hamburger")
    }
    dispatch(change())
  }

  const handleChange = (value) => {
    
    toggleTheme()
    dispatch(handleChainSelect(value))
    dispatch(changedChain(true))
    setDashboard(value)
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000)
  };

  return (
    <div className="fixed-top bg-blue">
      <Row>
        <div className='d-flex justify-content-between'>
          <div>

            {/* {
              margin ? <MenuFoldOutlined style={{ fontSize: '20px', marginRight: '8px' }} className="hide-on-mobile" onClick={() => dispatch(change())} /> : <MenuOutlined style={{ fontSize: '20px', marginRight: '8px' }} className="hide-on-mobile" onClick={() => dispatch(change())} />
            } */}

            <div className='d-flex'>
              <MenuOutlined style={{ fontSize: '18px', marginRight: '5px' }} className='mobile-menu my-auto' onClick={() => setShowMobileMenu(true)} />

              <div className="hamburger-menu-margin three hamburger-col hide-on-mobile my-auto">
                <div className={hamburgerClass} id="hamburger-10" onClick={() => handleSideMenu()}>
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </div>

              <div>
                <Link to="/" >
                  <div className='my-auto d-flex'>
                    <img src={MainLogo} alt="everEarnLogo" className='noselect everEarn_main_logo' />
                    <span className='ever-earn-header-name'>EverEarn</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className='my-auto d-flex'>
            {/* <Row> */}
              {/* <Col sm="6" md="6" lg="6"> */}
              <div className='mx-2'>
                <Button
                  className='ever-earn-primary-button fw-bold'
                  // style={{ marginRight: '5px' }}
                  // block
                >
                  <Select
                    size='large'
                    bordered={false}
                    showArrow={false}
                    placement="topLeft"
                    style={{
                      width: '100%',
                    }}
                    placeholder="select chain"
                    value={currentTheme === 'dark' ? 'bsc' : 'eth'}
                    onChange={handleChange}
                    getPopupContainer={trigger => trigger.parentNode}
                    // optionLabelProp="label"
                  >
                    <Option value="bsc" label={<span className='fw-bold'><img src={BSCLogo} alt="bsc-logo" style={{ width: '20px' }} /> BSC</span>}>
                     
                        <div className="demo-option-label-item ">
                          <span role="img" aria-label="bsc">
                            <img src={BSCLogo} alt="bsc-logo" style={{ width: '20px', marginRight: '5px' }} />
                          </span>
                          <span className='hide-on-small-devices'>
                             BSC
                          </span>
                        </div>
                    </Option>
                    <Option value="eth" label={<span className='fw-bold'><img src={ETHLogo} alt="eth-logo" style={{ width: '15px' }} /> ETH</span>}>
                      
                        <div className="demo-option-label-item">
                          <span role="img" aria-label="bsc">
                            <img src={ETHLogo} alt="eth-logo" style={{ width: '20px', marginRight: '5px' }} />
                          </span>
                          <span className='hide-on-small-devices'>
                            ETH
                          </span>
                          
                        </div>
                    </Option>
                  </Select>
                </Button>
              </div>
              {/* </Col>
              <Col sm="6" md="6" lg="6"> */}
                {
                  account ?
                    <>
                      <Button className='ever-earn-primary-button hide-on-mobile fw-bold' onClick={() => showDisconnectModal(true)}><div className='d-flex'>{getEllipsisTxt(account, 5)} <div style={{ marginTop: '-3px', marginLeft: '2px' }}><WalletFilled /></div></div></Button>
                      <Button className='ever-earn-primary-button hide-on-pc fw-bold' onClick={() => showDisconnectModal(true)}><div style={{ marginTop: '-2px' }}><WalletFilled style={{ fontSize: '22px' }} /></div></Button>
                    </>
                    :
                    <>
                      <Button className='ever-earn-primary-button fw-bold hide-on-mobile' onClick={() => dispatch(open())}>Connect</Button>
                      <Button className='ever-earn-primary-button fw-bold hide-on-pc' onClick={() => dispatch(open())}><div className='small'>Connect</div></Button>
                    </>
                }
              {/* </Col> */}
            {/* </Row> */}




          </div>
        </div>
      </Row>

      <Drawer
        placement="left"
        visible={showMobileMenu}
        onClose={() =>setShowMobileMenu(false) }
        closable={false}
        width="250px"
        style={{ zIndex: '9999', marginLeft: draweMargin }}
      >
        <div style={{ marginLeft: '-8px' }}>

          <div className='pb-1' style={{ marginTop: '-17px' }}>
            <DoubleLeftOutlined style={{ fontSize: '18px', marginRight: '5px' }} className='mobile-menu-svg' onClick={() => setShowMobileMenu(false)} />

            <img src={MainLogo} alt="everEarn_main_logo" className='everEarn_main_logo' />
            <span className='ever-earn-header-name'>EverEarn</span>
          </div>

          <MenuComponent
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        </div>
      </Drawer>

      {/* Wallet connect modal will show up when wallet connect button clicked */}
      <Modal title='Connect Wallet' visible={modal_opened} onOk={() => dispatch(close())} onCancel={() => dispatch(close())} footer={false}>
        <Button
          className='col-12 ever-earn-primary-button'
          size="large"
          onClick={handleMetamaskConnect}>
          <img src={Metamask} alt="metamask_logo" style={{ width: '30px', marginRight: '10px' }} />
          Connect with Metamask
        </Button>

        <Button
          className='col-12 ever-earn-primary-button mt-3'
          size="large"
          onClick={handleWalletConnectConnect}>
          <img src={WalletConnect} alt="wallet connect logo" style={{ width: '25px', marginRight: '10px' }} />
            Connect with WalletConnect
        </Button>
      </Modal>

      <Modal title={t('Connected Wallet')} visible={isDisconnectVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>

        <div className="row">
          <p className="text-muted fw-bold col-lg-8 col-sm-12 hide-on-mobile">Connected</p>
          <p className="text-lg-end col-lg-4 col-md-12 col-sm-12 fw-bold cursor" onClick={handleDisconnect}>
            <Button type='primary' className="btn blue-button col-12" style={{ marginRight: '15px' }} onClick={handleDisconnect}>{t('Disconnect')}</Button>
          </p>
        </div>

        <div className='user-account-address-container'>
          <Button
            className="btn blue-button-bordered hide-on-small-devices d-flex primary-text col-12"
            style={{ marginRight: '15px' }}>
            <img src={Logo} style={{ width: '20px', marginRight: '15px' }} alt='' />
            <span className='small'>{getEllipsisTxt(account, 10)}</span>
          </Button>
        </div>

        <div className="row mt-3">
          {
            !addressCopy &&
            <p className="fw-bold col-8 hide-on-mobile cursor" onClick={copyAddress} style={{ cursor: "pointer" }}>
              <Copy size={18} className='staking-payout-icon' />
              <span style={{ marginLeft: '5px' }} className="primary-text">Copy address</span>
            </p>
          }
          {
            addressCopy &&
            <p className="fw-bold col-8 hide-on-mobile">
              <CheckCircle size={18} className='staking-payout-icon' />
              <span style={{ marginLeft: '5px' }}>Copied</span>
            </p>
          }

          <p className="text-lg-end col-md-4 col-sm-6 fw-bold">
            <a
              href={`${process.env.REACT_APP_BLOCK_EXPLORER}/address/${account}`}
              target="_blank"
              className="col-lg-4 col-md-6 col-sm-6  fw-bold"
              rel="noreferrer">
              <ExternalLink size={18} className='staking-payout-icon' />
              <span
                style={{ marginLeft: '5px', textDecoration: "none", cursor: "pointer" }}>
                View on explorer
              </span>
            </a>
          </p>
        </div>

      </Modal> 

      {/* modal to change block chain */}
      <ChangeBlockChain />
    </div>
  )
}

export default Header