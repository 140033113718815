import axios from "axios";

export const getFileTypeFromURI = async (uri) => {
  try {
    const response = await axios.get(uri)
    const header = response.headers
    return header['content-type'] || ''
  } catch (error) {
    console.error("ERROR : can not decode file type")
    return ''
  }
}