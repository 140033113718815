import React, { useState, useEffect } from 'react';
import { Card, Button, Spin } from 'antd';
import EventEmitter from '../../../utils/EventEmitter'
import * as web3Service from '../services/staking.service'
import { LoadingOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import MainLogo from '../../../images/everearn-logo.png'
import busdLogo from '../../../images/busd-logo.png'
import { useSelector } from "react-redux"

export default function TokenDetailsSection(props) {

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const { walletAddress, contractAddress } = props

    const [userEverEarnTokenBalance, setUserEverEarnTokenBalance] = useState(0.0)
    const [isUserEverEarnTokenBalanceLoading, setIsUserEverEarnTokenBalanceLoading] = useState(false)

    const [userStakeAmount, setUserStakeAmount] = useState(0.0)
    const [isUserStakeAmountLoading, setIsUserStakeAmountLoading] = useState(false)

    const [totalStakeAmount, setTotalStakeAmount] = useState(0.0)
    const [isTotalStakeAmountLoading, setIsTotalStakeAmountLoading] = useState(false)

    const [userBusdAmount, setUserBusdAmount] = useState(0.0)
    const [isUserBusdAmountLoading, setIsUserBusdAmountLoading] = useState(false)
    const [logs, setLogs] = useState(null)

    const { currentChain } = useSelector((state) => state.chainSelector)
    const [rewardSymbol, setRewardSymbol] = useState('')

    useEffect(() => {
        if (currentChain) {
            if (currentChain === 'bsc') {
                setRewardSymbol('BUSD')
            } else {
                setRewardSymbol('USDC')
            }
        }
    }, [currentChain])

    useEffect(() => {
        const onNewLogs = (eventData) => {
            setLogs(eventData)
        }

        const listener = EventEmitter.addListener('refreshEvent', onNewLogs)

        return () => {
            listener.remove()
        }
    }, [])

    const loadEverEarnBalance = async () => {
        setIsUserEverEarnTokenBalanceLoading(true)
        const tokenBalance = await web3Service.getEverEarnBalanceByWalletAddress(walletAddress)
        setIsUserEverEarnTokenBalanceLoading(false)
        setUserEverEarnTokenBalance(tokenBalance)
    }

    const getUserStakeAmount = async () => {
        setIsUserStakeAmountLoading(true)
        const stakedAmount = await web3Service.getUserStakeAmountByWalletAddress(contractAddress, walletAddress)
        setUserStakeAmount(stakedAmount)
        setIsUserStakeAmountLoading(false)
    }

    const getTotalStakeAmount = async () => {
        setIsTotalStakeAmountLoading(true)
        const totalStakedAmount = await web3Service.getTotalStakeValueByStakingContractAddress(contractAddress)
        setTotalStakeAmount(totalStakedAmount)
        setIsTotalStakeAmountLoading(false)
    }

    const getUserBusdValue = async () => {
        setIsUserBusdAmountLoading(true)
        const busdBalance = await web3Service.getUserBUSDValueByTokenWalletAddress(walletAddress)
        setUserBusdAmount(busdBalance)
        setIsUserBusdAmountLoading(false)
    }

    useEffect(() => {
        loadEverEarnBalance()
        getUserStakeAmount()
        getTotalStakeAmount()
        getUserBusdValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress])

    useEffect(() => {
        loadEverEarnBalance()
        getUserStakeAmount()
        getTotalStakeAmount()
        getUserBusdValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logs])

    return (
        <div>
            <div className="d-flex">
                <img src={MainLogo} alt="everEarnLogo" className='staking-page-logo' />
                <span className="staking-pool-header">EVEREARN BALANCE: </span>
                <>
                    {
                        isUserEverEarnTokenBalanceLoading ? (<div  className="staking-pool-value">
                            <Spin size="small" />
                        </div>)
                            :
                            (
                                <span className="staking-pool-value">
                                    <NumberFormat
                                        value={userEverEarnTokenBalance ? userEverEarnTokenBalance : 0.00}
                                        displayType="text"
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        suffix=' $EVEREARN'
                                    />

                                </span>

                            )
                    }
                </>
            </div>
            <div className="d-flex">
                <img src={busdLogo} alt="everEarnLogo" className='staking-page-logo' />
                <span className="staking-pool-header">YOUR TOTAL {rewardSymbol} STAKED: </span>
                <>
                    {
                        isUserStakeAmountLoading ? (<div  className="staking-pool-value">
                            <Spin size="small" />
                        </div>)
                            :
                            (
                                <span className="staking-pool-value">
                                    <NumberFormat
                                        value={userStakeAmount ? userStakeAmount : 0.0000}
                                        displayType="text"
                                        decimalScale={3}
                                        thousandSeparator={true}
                                        suffix={rewardSymbol}
                                    />

                                </span>

                            )
                    }
                </>
            </div>
            <div className="d-flex">
                <img src={busdLogo} alt="everEarnLogo" className='staking-page-logo' />
                <span className="staking-pool-header">TOTAL {rewardSymbol} STAKED: </span>
                <>
                    {
                        isTotalStakeAmountLoading ? (<div  className="staking-pool-value">
                            <Spin size="small" />
                        </div>)
                            :
                            (
                                <span className="staking-pool-value">
                                    <NumberFormat
                                        value={totalStakeAmount ? totalStakeAmount : 0.0000}
                                        displayType="text"
                                        decimalScale={3}
                                        thousandSeparator={true}
                                        suffix={rewardSymbol}
                                    />

                                </span>

                            )
                    }
                </>
            </div>
            <div className="d-flex">
                <img src={busdLogo} alt="everEarnLogo" className='staking-page-logo' />
                <span className="staking-pool-header">{rewardSymbol} BALANCE: </span>
                <>
                    {
                        isUserBusdAmountLoading ? (<div  className="staking-pool-value">
                            <Spin size="small" />
                        </div>)
                            :
                            (
                                <span className="staking-pool-value">
                                    <NumberFormat
                                        value={userBusdAmount ? userBusdAmount : 0.0000}
                                        displayType="text"
                                        decimalScale={3}
                                        thousandSeparator={true}
                                        suffix={rewardSymbol}
                                    />

                                </span>

                            )
                    }
                </>
            </div>
        </div>
    );
}
