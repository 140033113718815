/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../blockchain/web3.service'

export const useTotalBurnedData = (props) => {

  const { contractAddress } = props
  const [tokenBurnedData, setTokenBurnedData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect( () => {

    async function fetchData() {
      
      try {
        setLoading(true)
        const burnedTokenResponse = await web3Service.getTokenBurnedData(contractAddress)
        setTokenBurnedData(burnedTokenResponse)
      } catch (error) {
        setTokenBurnedData(null)
        console.error('error while calculation token liquidity supply ', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData();

  }, [])
  return { tokenBurnedData, isLoading }
}

export default useTotalBurnedData

