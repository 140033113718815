import React, { useState, useEffect } from 'react'
import * as web3Service from '../services/staking.service'
import { DateTime } from 'luxon'
import Countdown from 'react-countdown';
import EventEmitter from '../../../utils/EventEmitter';
import { useSelector } from "react-redux"

export default function UserStakingDetails(props) {

  const { contractAddress, walletAddress } = props

  const [isStakeDetailsAvailable, setIsStakeDetailsAvailable] = useState(false)
  const [stakeAmountBUSD, setStakeAmountBUSD] = useState(0.0)
  const [userStakeDetails, setUserStakeDetails] = useState(null)
  const [userStakeStartTime, setUserStakeStartTime] = useState(0)
  const [userStakeEndTime, setUserStakeEndTime] = useState(0)
  const [userStakeDuration, setUserStakeDuration] = useState(0)
  const [logs, setLogs] = useState(null)
  const { currentChain } = useSelector((state) => state.chainSelector)
  const [rewardSymbol, setRewardSymbol] = useState('')

  useEffect(() => {
      if (currentChain) {
          if (currentChain === 'bsc') {
              setRewardSymbol('BUSD')
          } else {
              setRewardSymbol('USDC')
          }
      }
  }, [currentChain])

  const stakeTimer = ({ days, hours, minutes, seconds }) => {
    return <span>{days} days :  {hours} hours : {minutes} mins : {seconds} secs</span>;
  };

  useEffect(() => {
    const onNewLogs = (eventData) => {
      setLogs(eventData)
    }

    const listener = EventEmitter.addListener('refreshEvent', onNewLogs)

    return () => {
      listener.remove()
    }
  }, [])

  useEffect(() => {
    const getUserStakeDetails = async () => {
      const userStakeDetailsResponse = await web3Service.getUserCompleteStakeDetailsByWalletAddress(contractAddress, walletAddress)
      if (userStakeDetailsResponse) {
        setIsStakeDetailsAvailable(true)
      } else {
        setIsStakeDetailsAvailable(false)
      }
      setUserStakeDetails(userStakeDetailsResponse || null)

    }
    getUserStakeDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, logs])

  useEffect(() => {
    if (userStakeDetails) {
      const stakedTokenAmountInWei = userStakeDetails[0].toString()
      const actualStakeAmount = parseInt(stakedTokenAmountInWei) / (10 ** 18)
      setStakeAmountBUSD(actualStakeAmount)

      //set staking start time 
      const stakeStartTimeStr = userStakeDetails[1].toString()
      const stakeStartTimeInt = parseInt(stakeStartTimeStr)
      setUserStakeStartTime(stakeStartTimeInt || 0)

      //set staking end time 
      const stakeEndTimeStr = userStakeDetails[2].toString()
      const stakeEndTimeInt = parseInt(stakeEndTimeStr)
      setUserStakeEndTime(stakeEndTimeInt || 0)

      const durationStr = userStakeDetails[3].toString()
      const durationInt = parseInt(durationStr)
      setUserStakeDuration(durationInt || 0)
    }

  }, [userStakeDetails])

  const getTheFormattedTimeUsingTimeStamp = (timestamp) => {

    if (timestamp) {
      const dateTimeObject = DateTime.fromSeconds(timestamp)
      const formattedTime = dateTimeObject.toLocal().toFormat('MM-dd-yyyy hh:mm:ss a')
      return formattedTime
    } else {
      return ''
    }
  }

  return (
    <div>
      {
        isStakeDetailsAvailable ? (
          <div className="row mb-3">
            <div className="col-12">
              <div className="stake-details-container">
                <div className="stake-details-row">
                  <span className="stake-details-label">
                    Your total stake amount in {rewardSymbol} : 
                  </span>
                  <span className="stake-details-value">
                    {stakeAmountBUSD} {rewardSymbol}
                  </span>
                </div>
                <div className="stake-details-row">
                  <span className="stake-details-label">
                    Your have staked the token at :
                  </span>
                  <span className="stake-details-value">
                    {getTheFormattedTimeUsingTimeStamp(userStakeStartTime)}
                  </span>
                </div>
                <div className="stake-details-row">
                  <span className="stake-details-label">
                    Your stake period will end at :
                  </span>
                  <span className="stake-details-value">
                    {getTheFormattedTimeUsingTimeStamp(userStakeEndTime)}
                  </span>
                </div>
                <div className="stake-details-row">
                  <span className="stake-details-label">
                    Your stake time period :
                  </span>
                  <span className="stake-details-value">
                    {userStakeDuration} day(s)
                  </span>
                </div>
                <div className="stake-details-row">
                  <span className="stake-details-label">
                    Your stake period will over in  :
                  </span>
                  <span className="stake-details-value">
                    <Countdown
                        date={DateTime.fromSeconds(userStakeEndTime)}
                        renderer={stakeTimer}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      } 

    </div>
  )
}
