import { configureStore } from "@reduxjs/toolkit";
import SidemenuMarginReducer from "./SidemenuMargin";
import WalletConnectReducer from "./WalletConnect";
import RoiModalReducer from "./RoiModal";
import ThemeReducer from './ThemeState'
import BlockChainChangeReducer from "./BlockChainChange";
import LanguageReducer from "./Language";
import ChainSelector from "./ChainSelector";

export const Store = configureStore({
  reducer: {
    margin: SidemenuMarginReducer,
    connectModalState: WalletConnectReducer,
    roiModalState: RoiModalReducer,
    themeState: ThemeReducer,
    blockchainModalState: BlockChainChangeReducer,
    languageState: LanguageReducer,
    chainSelector: ChainSelector
  }
});