import React, { useState } from 'react'
import { stakingConfigs } from '../services/staking.config'
import { ethers } from "ethers";
import 'react-toastify/dist/ReactToastify.css';
import EventEmitter from '../../../utils/EventEmitter';
import { Button, notification } from 'antd';
import { useWeb3React } from '@web3-react/core';

export default function WithdrawAndExitButton(props) {

  const { contractAddress, walletAddress } = props
  const [isWithdrawAndExitLoading, setIsWithdrawAndExitLoading] = useState(false)
  const { library } = useWeb3React()
  const handleWithdrawAndExit = async () => {
    try {
      setIsWithdrawAndExitLoading(true)
      //create the contract instance
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);

      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);

      const stakingContractInstanceWithSigner = stakingContractInstance.connect(library.getSigner());

      //call the contract function
      const withdrawAndExitReceipt = await stakingContractInstanceWithSigner.withdrawAndExit()
      const result = await withdrawAndExitReceipt.wait()

      EventEmitter.emit('refreshEvent', { isRefresh: true })
      notification['success']({
        message: 'Withdraw Success',
        description: 'Token Withdraw has been completed',
      });
      console.log('withdrawAndExitReceipt', withdrawAndExitReceipt)
      console.log('result', result)
      setIsWithdrawAndExitLoading(false)


    } catch (error) {
      let errorMessage = 'something went wrong while trying to withdraw your tokens'

      if (error.message) {
        errorMessage = error.message
      }
      if (error.data && error.data.message) {
        errorMessage = error.data.message
      }
      notification['error']({
        message: 'Error',
        description: errorMessage,
      });
      console.error("ERROR: something went wrong while processing withdraw and exit ", error)
      setIsWithdrawAndExitLoading(false)
    }
  }


  return (
    <div>
      <Button
        block={true}
        onClick={handleWithdrawAndExit}
        size="large"
        className='col-12 ever-earn-primary-button mt-3'
        loading={isWithdrawAndExitLoading}
      >
        Withdraw Exit
      </Button>
    </div>

  )
}

