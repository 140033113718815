import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Store } from './Redux/Store'
import { Provider } from "react-redux"
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import Web3LoginProvider from './providers/Web3LoginProvider'
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import './i18n/config';
import { MoralisProvider } from 'react-moralis';
import { moralisConfigs } from './blockchain/configs/moralis.config';

const nftContractConfig = moralisConfigs.nftContractConfig
const moralisServerURL = nftContractConfig.moralisServerUrl
const moralisAppId = nftContractConfig.moralisApplicationId

const getLibrary = (provider) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

const themeState = localStorage.getItem('ever-earn-theme')
  ? localStorage.getItem('ever-earn-theme') : "dark"

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
  semi: `${process.env.PUBLIC_URL}/semi-theme.css`
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3LoginProvider>
        <MoralisProvider appId={moralisAppId} serverUrl={moralisServerURL}>
          <Provider store={Store}>
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={themeState}>
              <App />
            </ThemeSwitcherProvider>
          </Provider>
        </MoralisProvider>
      </Web3LoginProvider>
    </Web3ReactProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
