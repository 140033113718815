import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import './MenuComponent.css'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import dashboardIcon from './../../images/Dashboard.svg'
import calculatorIcon from './../../images/Calculator.svg'
import NFTMintingIcon from './../../images/NFT Minting.svg'
import stakingIcon from './../../images/Staking.svg'
import NFTTrackerIcon from './../../images/NFT Tracker.svg'
import walletTrackerIcon from './../../images/Wallet Tracker.svg'
import toDoIcon from './../../images/ToDo.svg'
import FAQIcon from './../../images/FAQ.svg'

import dashboardIconETH from './../../images/DashboardETH.svg'
import calculatorIconETH from './../../images/CalculatorETH.svg'
import NFTMintingIconETH from './../../images/NFTMintingETH.svg'
import stakingIconETH from './../../images/StakingETH.svg'
import NFTTrackerIconETH from './../../images/NFTTrackerETH.svg'
import walletTrackerIconETH from './../../images/WalletTrackerETH.svg'
import toDoIconETH from './../../images/ToDoETH.svg'
import FAQIconETH from './../../images/FAQETH.svg'

import dashboardIconPOLYGON from './../../images/polygon-menu-icon/DashboardPOLYGON.svg'
import calculatorIconPOLYGON from './../../images/polygon-menu-icon/CalculatorPOLYGON.svg'
import NFTMintingIconPOLYGON from './../../images/polygon-menu-icon/NFTMintingPOLYGON.svg'
import stakingIconPOLYGON from './../../images/polygon-menu-icon/StakingPOLYGON.svg'
import NFTTrackerIconPOLYGON from './../../images/polygon-menu-icon/NFTTrackerPOLYGON.svg'
import walletTrackerIconPOLYGON from './../../images/polygon-menu-icon/WalletTrackerPOLYGON.svg'
import toDoIconPOLYGON from './../../images/polygon-menu-icon/ToDoPOLYGON.svg'
import FAQIconPOLYGON from './../../images/polygon-menu-icon/FAQPOLYGON.svg'

import { isChangedChain } from '../../Redux/ChainSelector';

function MenuComponent(props) {

  const { showMobileMenu, setShowMobileMenu } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation();
  const { margin } = useSelector((state) => state.margin);
  const { isChangedChain } = useSelector((state) => state.chainSelector);
  const [hideChildren, setHideChildren] = useState(false)

  const [openKeys, setOpenKeys] = useState([location.pathname]);
  const [isBsc, setIsBsc] = useState(false);
  const [isPolygon, setIsPolygon] = useState(false);

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {
    if (!margin) {
      setHideChildren(true)

      setTimeout(function () {
        setHideChildren(false)
      }, 500)
    } else {
      setHideChildren(false)
    }
  }, [margin])

  useEffect(() => {
    if (isChangedChain) {
      // console.log('isChangedChain', isChangedChain);
    }
  }, [isChangedChain])

  useEffect(() => {
    // console.log('currentChain1', currentChain);
    if (currentChain === 'bsc') {
      setIsBsc(true)
      setIsPolygon(false)
    } else if (currentChain === 'polygon') {
      setIsBsc(false)
      setIsPolygon(true)
    } else {
      setIsBsc(false)
      setIsPolygon(false)
    }
  }
    , [currentChain])

  // menu item structure
  const getItem = (label, key, icon, children, theme) => {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }

  // const rootSubmenuKeys = ['/', '/launchpad', '/kings-locker', '/kingstaking', '/partnership', 'bot', 'kyc', 'info', 'funding', 'telegram', 'twitter' ];
  const rootSubmenuKeys = ['/launchpad', '/kings-locker', '/kingstaking'];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // menu items
  const items = [
    getItem('Dashboard', '/', <img src={dashboardIcon} alt="dashboardIcon" style={{ width: "15px" }} />),
    getItem('Calculator', '/calculator', <img src={calculatorIcon} alt="calculatorIcon" style={{ width: "15px" }} />),
    getItem('NFT Minting', '/nft-mint', <img src={NFTMintingIcon} alt="NFTMintingIcon" style={{ width: "15px" }} />),
    getItem('Staking', '/staking', <img src={stakingIcon} alt="stakingIcon" style={{ width: "15px" }} />),
    getItem('NFT Tracker', '/nft-tracker', <img src={NFTTrackerIcon} alt="NFTTrackerIcon" style={{ width: "15px" }} />),
    getItem('Wallet Tracker', '/wallet-tracker', <img src={walletTrackerIcon} alt="walletTrackerIcon" style={{ width: "15px" }} />),
    getItem('To Do', '/to-do', <img src={toDoIcon} alt="toDoIcon" style={{ width: "15px" }} />),
    getItem('FAQ', '/faq', <img src={FAQIcon} alt="FAQIcon" style={{ width: "15px" }} />),
    getItem('Ever Swap', '/ever-swap', <img src={calculatorIcon} alt="calculatorIcon" style={{ width: "15px" }} />)
  ];

  const itemsEth = [
    getItem('Dashboard', '/', <img src={dashboardIconETH} alt="dashboardIcon" style={{ width: "15px" }} />),
    getItem('Calculator', '/calculator', <img src={calculatorIconETH} alt="calculatorIcon" style={{ width: "15px" }} />),
    getItem('Staking', '/staking', <img src={stakingIconETH} alt="stakingIcon" style={{ width: "15px" }} />),
    getItem('NFT Tracker', '/nft-tracker', <img src={NFTTrackerIconETH} alt="NFTTrackerIcon" style={{ width: "15px" }} />),
    getItem('Wallet Tracker', '/wallet-tracker', <img src={walletTrackerIconETH} alt="walletTrackerIcon" style={{ width: "15px" }} />),
    getItem('To Do', '/to-do', <img src={toDoIconETH} alt="toDoIcon" style={{ width: "15px" }} />),
    getItem('FAQ', '/faq', <img src={FAQIconETH} alt="FAQIcon" style={{ width: "15px" }} />),
    getItem('Ever Swap', '/ever-swap', <img src={calculatorIconETH} alt="calculatorIcon" style={{ width: "15px" }} />)
  ];

  const itemsPolygon = [
    getItem('Dashboard', '/', <img src={dashboardIconPOLYGON} alt="dashboardIcon" style={{ width: "15px" }} />),
    getItem('Calculator', '/calculator', <img src={calculatorIconPOLYGON} alt="calculatorIcon" style={{ width: "15px" }} />),
    getItem('Staking', '/staking', <img src={stakingIconPOLYGON} alt="stakingIcon" style={{ width: "15px" }} />),
    getItem('NFT Tracker', '/nft-tracker', <img src={NFTTrackerIconPOLYGON} alt="NFTTrackerIcon" style={{ width: "15px" }} />),
    getItem('Wallet Tracker', '/wallet-tracker', <img src={walletTrackerIconPOLYGON} alt="walletTrackerIcon" style={{ width: "15px" }} />),
    getItem('To Do', '/to-do', <img src={toDoIconPOLYGON} alt="toDoIcon" style={{ width: "15px" }} />),
    getItem('FAQ', '/faq', <img src={FAQIconPOLYGON} alt="FAQIcon" style={{ width: "15px" }} />),
    getItem('Ever Swap', '/ever-swap', <img src={calculatorIconPOLYGON} alt="calculatorIcon" style={{ width: "15px" }} />)

  ]
  // onclick event on a menu item, navigates ti relevant route
  const onClick = (e) => {
    // console.log('showMobileMenu', showMobileMenu);
    if (showMobileMenu) {
      navigate(e.key);
      setShowMobileMenu(false)
    }
    else {
      navigate(e.key);
    }
  };


  return (
    <>
      {/* menu section */}
      <Menu
        openKeys={hideChildren ? null : openKeys}
        onOpenChange={onOpenChange}
        mode="inline"
        selectedKeys={location.pathname}
        theme=""
        onClick={onClick}
        style={{ padding: '1px' }}
        items={isBsc ? items : isPolygon ? itemsPolygon : itemsEth}
        className="menu-background"
      />
    </>
  )
}

export default MenuComponent
