/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Card } from 'reactstrap';
import { useEffect, useState } from 'react';
import { Image, Spin } from 'antd';
import './NFTTracker.css'
import { useMoralis } from 'react-moralis';
import { configs } from '../../blockchain/web3.config';
import UserIdenticon from './UserIdenticon';
import { getEllipsisTxt } from '../../Helpers/Formatters';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons'
import defaultPreviewImage from '../../images/nft-preview-placeholder.png'
import { getFileTypeFromURI } from '../../Helpers/fileTypeDecoder'
import playButtonImg from '../../images/play-button.png'
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const NFTDetailsPage = () => {

  const location = useLocation();

  const { data } = location.state
  const { Moralis, isInitialized } = useMoralis()
  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [isNftImageLoading, setIsNftImageLoading] = useState(false)
  const [nftTokenId, setNftTokenId] = useState('~')
  const [nftTitle, setNftTitle] = useState('~')
  const [nftImage, setNftImage] = useState('')
  const [fileType, setFileType] = useState('')
  const [nftImageWithFiletype, setNftImageWithFiletype] = useState('')
  const [imagePreview, setImagePreview] = useState(true)
  const [imageHeight, setImageHeight] = useState(300)
  const [nftDescription, setNftDescription] = useState('')

  const provideAssetContainer = (assetURI, assetType) => {
    if (fileType.includes('image')) {
      return (<Image
        src={nftImageWithFiletype}
        preview={imagePreview}
        fallback={defaultPreviewImage}
      />)
    }
    if (fileType.includes('video')) {
      return (
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            playing
            width='100%'
            height='100%'
            controls
            url={[
              { src: assetURI, type: assetType },
            ]}
          />
        </div>)
    }
    if (fileType.includes('audio')) {
      return (<AudioPlayer
        autoPlay
        src={assetURI}
      />)
    }
  }

  useEffect( () => {
    async function fetchData() {
      const { nftContractConfig } = configs
      if (isInitialized) {
        setIsNftImageLoading(true)
        const options = { address: data.token_address, token_id: data.token_id, chain: nftContractConfig.moralisChainId }
        const tokenIdMetadata = await Moralis.Web3API.token.getTokenIdMetadata(options)
        setNftTokenId(tokenIdMetadata.token_id)
        if (tokenIdMetadata.metadata) {
          const metadata = JSON.parse(tokenIdMetadata.metadata)
          let processedImageUrl = ''
          if (metadata.image && metadata.image.includes('ipfs://')) {
            const imageUrl = metadata.image.split('ipfs://')
            processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`
          } else {
            processedImageUrl = metadata.image
          }
          setNftImage(processedImageUrl)
          setNftTitle(metadata.name)
          setNftTitle(metadata.description)
          setIsNftImageLoading(false)
        } else {
          setIsNftImageLoading(true)
          if (data.token_uri) {
            const correctedEndpoint = data.token_uri.replace('https://ipfs.moralis.io:2053/', 'https://gateway.moralisipfs.com/')
            const response = await axios.get(correctedEndpoint)
            if (response.status === 200) {
              const metadata = response.data
              let processedImageUrl = ''
              if (metadata.image && metadata.image.includes('ipfs://')) {
                const imageUrl = metadata.image.split('ipfs://')
                processedImageUrl = `https://h4g.mypinata.cloud/ipfs/${imageUrl[1]}`
              } else {
                processedImageUrl = metadata.image
              }
              setNftImage(processedImageUrl)
              setNftTitle(metadata.name)
              setNftTitle(metadata.description)
            }
          } else {
            setNftImage('')
            setNftTitle('~')

          }
          setIsNftImageLoading(false)
        }
      }

    }
    fetchData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized])

  useEffect( () => {
    async function fetchData() {
      if (nftImage) {
        const filetype = await getFileTypeFromURI(nftImage)
        setFileType(filetype)
      } else {
        setFileType('')
      }
    }
    fetchData();
    
  }, [nftImage])

  useEffect( () => {
    async function fetchData() {
      if (fileType) {
        if (fileType.includes('image')) {
          setNftImageWithFiletype(nftImage)
          setImagePreview(true)
          setImageHeight(300)
        }
        if (fileType.includes('video')) {
          setNftImageWithFiletype(playButtonImg)
          setImagePreview(false)
          setImageHeight(100)
        }
      }
    }
    fetchData();
    
  }, [fileType])

  return (

    <div className="container mt-4 p-4 nft-minting-container">
      <Row>
        <Col>
          <Card style={{ border: 'none' }} className="customcard p-4">
            <Row>
              <Col sm="12" md="6" lg="6">
                <div className="nft-asset-container-section">
                  {provideAssetContainer(nftImage, fileType)}
                </div>
              </Col>
              <Col sm="12" md="6" lg="6">
                <div className="nft-details-owner-label">
                  Owner Details
                </div>
                <div className="nft-details-owner-section">
                  <div className="nft-details-profile">
                    {
                      isNftImageLoading ? (
                        <><Spin indicator={spinnerIcon} /></>
                      ) : (
                        <UserIdenticon
                          size={50}
                          walletAddress={data ? data.owner_of : ''}
                          customCss={{ width: '50px', height: '50px' }}
                        />
                      )
                    }
                  </div>
                  {
                    isNftImageLoading ? (
                      <><Spin indicator={spinnerIcon} /></>
                    ) : (
                      <div className="nft-details-wallet-address">
                        {getEllipsisTxt(data ? data.owner_of : '', 7)}
                      </div>
                    )
                  }

                </div>

                <div className="nft-details-metadata-section">
                  <div className="nft-details-contract-type">

                  </div>

                  <div className="nft-details-title">
                    {nftTitle}
                  </div>
                  <div className="nft-details-description"></div>
                  <div className="nft-details-token-id">
                    TOKEN ID : # {
                      isNftImageLoading ? (
                        <><Spin indicator={spinnerIcon} /></>
                      ) : (
                        <>{nftTokenId}</>
                      )
                    }

                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>



  );
};

export default NFTDetailsPage;
