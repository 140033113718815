// ** Reactstrap Imports
import { Card, Alert, Row, Col, Button } from 'reactstrap'
import { tokenValueTxt, n12 } from '../../Helpers/Formatters'
import { AlertCircle } from 'react-feather'
import { useEffect, useState } from 'react'
import * as multiChainService from '../../blockchain/services/multichain.service'
import { useWeb3React } from "@web3-react/core"
import { multiChainConfigs } from '../../blockchain/configs/multichain.configs'
import { Contract } from "@ethersproject/contracts"
import { LoadingOutlined } from '@ant-design/icons';


export default function UserRewardsInfo(props) {

  const { tokenAddress, isValidAccountAddress } = props

  const [userRewardsInfo, setUserRewardsInfo] = useState({})
  const [userTokenAddress, setUserTokenAddress] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { account, active, library } = useWeb3React()

  const [isClaimTokenLoading, setIsClaimTokenLoading] = useState(false)

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {
    async function fetchData() {
      if (active && account) {
        setUserTokenAddress(account)
      } else {
        setUserTokenAddress(tokenAddress)
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active, library, tokenAddress])

  const claimTokens = async () => {


    try {
      if (!account) {
        alert("Please connect your wallet")
        setIsClaimTokenLoading(false)
        return
      }

      const { web3Config } = multiChainConfigs
      const dividendTrackerAddress = web3Config.ETHcontractWallets.dividendTrackerAddress
      const dividendTrackerABI = JSON.parse(web3Config.dividendTrackerABI)
      const dividendTrackerInstance = new Contract(dividendTrackerAddress, dividendTrackerABI, library.getSigner())
      const txReceipt = await dividendTrackerInstance.claimDividend()
      const result = await txReceipt.wait()
      if (result) {
        console.log("RESULT Claim success ", result)
        window.location.reload()
      }
      setIsClaimTokenLoading(false)
    } catch (error) {
      console.log("ERROR while trying to claim your dividend tokens ", error)
      setIsClaimTokenLoading(false)
    }
  }


  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)
        //get the token main token price in usd
        const userRewards = await multiChainService.getUserRewardsDetails(currentChain, userTokenAddress)
        setLoading(false)
        if (userRewards) {
          setUserRewardsInfo(userRewards)
        } else {
          setUserRewardsInfo({})
        }
      } catch (error) {
        setUserRewardsInfo({})
        console.error('error while fetching rewards info ', error.message || '')
      } finally {
        setLoading(false)
      }
    }
    fetchData();

  }, [userTokenAddress, isValidAccountAddress, currentChain])

  return (
    <Card className="ever-earn-card-bg mt-4 p-4">
      {
        isLoading ? (
          <div className="reward-info-widget">
            <div className='text-center'>
              <LoadingOutlined className="spin-color" />
            </div>
          </div>

        ) : (
          <div className="reward-info-widget">
            <Row className='total-user-rewards'>
              <Col sm="12" md="6" lg="3">
                <div className='user-dividend-data-container'>
                  <div className='user-divided-data-title'>
                    Total Rewards Distributed So Far
                  </div>
                  <div className='user-divided-data-value text-light-green'>
                    {tokenValueTxt(userRewardsInfo ? userRewardsInfo.totalRewardsDistributed : 0.0, 0, '')} USDC
                    {/* { userRewardsInfo ? userRewardsInfo.rewardsTokenSymbol : ''} */}
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6" lg="3">
                <div className='user-dividend-data-container'>
                  <div className='user-divided-data-title'>
                    Total Rewards Distributed So Far
                  </div>
                  <div className='user-divided-data-value text-light-green'>
                    ~ {tokenValueTxt(userRewardsInfo ? userRewardsInfo.totalRewardsDistributedInUSD : 0.0, 0, '')} USD
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6" lg="3">
                <div className='user-dividend-data-container'>
                  <div className='user-divided-data-title'>
                    Total Pending Rewards
                  </div>
                  <div className='user-divided-data-value text-light-green'>
                    {tokenValueTxt(userRewardsInfo ? userRewardsInfo.rewardTokenPendingBalance : 0.0, 0, '')} USDC
                    {/* { userRewardsInfo ? userRewardsInfo.rewardsTokenSymbol : ''} */}
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6" lg="3">
                <div className='user-dividend-data-container'>
                  <div className='user-divided-data-title'>
                    Total Pending Rewards
                  </div>
                  <div className='user-divided-data-value text-light-green'>
                    ~ {tokenValueTxt(userRewardsInfo ? userRewardsInfo.rewardTokenPendingBalanceInUSD : 0.0, 0, '')} USD
                  </div>
                </div>
              </Col>
            </Row>
            {
              isValidAccountAddress ? (<Row className='total-user-rewards desktop-margin-top'>
                <Col sm="12" md="6" lg="3">
                  <div className='user-dividend-data-container'>
                    <div className='user-divided-data-title'>
                      Total User Rewards So Far
                    </div>
                    <div className='user-divided-data-value text-light-green'>
                      {tokenValueTxt(userRewardsInfo ? userRewardsInfo.userRewardsTotal : 0.0, 0, '')} USDC
                      {/* { userRewardsInfo ? userRewardsInfo.rewardsTokenSymbol : ''} */}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                  <div className='user-dividend-data-container'>
                    <div className='user-divided-data-title'>
                      Total User Rewards So Far
                    </div>
                    <div className='user-divided-data-value text-light-green'>
                      ~ {tokenValueTxt(userRewardsInfo ? userRewardsInfo.userRewardsTotalInUSD : 0.0, 0, '')} USD
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                  <div className='user-dividend-data-container'>
                    <div className='user-divided-data-title'>
                      User Dividend Claimable
                    </div>
                    <div className='user-divided-data-value text-light-green'>
                      {n12.format(userRewardsInfo ? userRewardsInfo.userClaimableTokenAmount : 0.0)} USDC
                      {/* { userRewardsInfo ? userRewardsInfo.rewardsTokenSymbol : ''} */}
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                  <div className='user-dividend-data-container'>
                    <div className='user-divided-data-title'>
                      User Dividend Claimable
                    </div>
                    <div className='user-divided-data-value text-light-green'>
                      ~ {tokenValueTxt(userRewardsInfo ? userRewardsInfo.userClaimableTokenAmountInUSD : 0.0, 0, '')} USD
                    </div>
                  </div>
                </Col>
              </Row>

              ) : (
                <div style={{ marginTop: '20px' }}>
                  <Alert color='primary' isOpen={true}>
                    <div className='alert-body'>
                      <AlertCircle size={15} />{' '}
                      <span className='ms-1'>
                        Please connect your wallet or enter the valid wallet address to see user specific dividends data.
                      </span>
                    </div>
                  </Alert>
                </div>
              )
            }
            
            {
              (userRewardsInfo && userRewardsInfo.userClaimableTokenAmount > 0) ? (<Row style={{ marginTop: '50px' }}>
                <Col sm="12" md="12" lg="12">

                  {
                    active ? (<div className='claim-your-token-container'>
                      <div className='claim-your-token-title'>
                        Claim your Rewards
                      </div>
                      <div className='claim-your-token-value'>
                        {
                          isClaimTokenLoading ? (
                            <>Loading</>
                          ) : (
                            <Button color='primary' onClick={claimTokens}>Claim your Rewards</Button>
                          )
                        }

                      </div>
                    </div>) : (<div style={{ marginTop: '20px' }}>
                      <Alert color='danger' isOpen={true}>
                        <div className='alert-body'>
                          <AlertCircle size={15} />{' '}
                          <span className='ms-1'>
                            Please connect your wallet to proceed manual claim
                          </span>
                        </div>
                      </Alert>
                    </div>)
                  }

                </Col>

              </Row>

              ) : (

                userTokenAddress && userTokenAddress.length > 0 ? (<div style={{ marginTop: '20px' }}>
                  <Alert color='danger' isOpen={true}>
                    <div className='alert-body'>
                      <AlertCircle size={15} />{' '}
                      <span className='ms-1'>
                        Sorry you don't have sufficient rewards balance to claim the rewards
                      </span>
                    </div>
                  </Alert>
                </div>) : (<div></div>)
              )
            }
          </div>
        )
      }
    </Card>

  )
}
