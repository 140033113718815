// ** Reactstrap Imports
import { Card, UncontrolledTooltip } from 'reactstrap'
import useRewardsInfo from '../../hooks/useRewardsInfo'
import { tokenValueTxt, n2, getEllipsisTxt } from '../../Helpers/Formatters'
import { Info } from 'react-feather'
import { Progress } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

export default function RewardsInfo() {

  const { rewardsInfo, isLoading } = useRewardsInfo()

  return (
    <Card className="ever-earn-card-bg mt-4 p-4">
      {
        isLoading ? (
          <div className="data-info-widget">
            <div className='text-center'>
              <LoadingOutlined className="spin-color" />
            </div>
          </div>

        ) : (
          <div className="data-info-widget">
            <div className='data-info-widget-title'>
              Dividend Details
            </div>
            <div className="data-info-widget-container">

              <div className="data-info-row">
                <div className="data-info-type">
                  Rewards Contract Address:
                </div>
                <a href={`https://etherscan.io/address/${rewardsInfo ? rewardsInfo.rewardsTrackerContractAddress : ''}`} target='_blank' className='blockchain-link' rel="noreferrer">
                  <div className="data-info-value">{getEllipsisTxt(rewardsInfo ? rewardsInfo.rewardsTrackerContractAddress : '', 7)}</div>
                </a>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">
                  Total Rewards Distributed:

                </div>

                <div className="data-info-value">
                  {tokenValueTxt(rewardsInfo ? rewardsInfo.totalRewardsDistributed : 0.0, 0, '')} USDC
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Total Rewards Distributed USD:</div>
                <div className="data-info-value">
                  ~$ {tokenValueTxt(rewardsInfo ? rewardsInfo.totalRewardsDistributedInUSD : 0.0, 0, '')}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type" id='reward-cycle'>Reward Cycle
                  <Info size={10} style={{ marginLeft: '3px' }} />
                </div>
                <UncontrolledTooltip placement='top' target='reward-cycle'>
                  The time duration that rewards are getting distributed per holder. This differs from the current volume.
                </UncontrolledTooltip>
                <div className="data-info-value">
                  Every {rewardsInfo ? rewardsInfo.rewardsCycleInHours : 0} hours
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type" id='dividend-holders'>Total Dividend Holders
                  <Info size={10} style={{ marginLeft: '3px' }} />
                </div>
                <UncontrolledTooltip placement='top' target='dividend-holders'>
                  Eligible dividend holders amount
                </UncontrolledTooltip>
                <div className="data-info-value">
                  {rewardsInfo ? rewardsInfo.totalRewardsHolders : 0}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type" id='current-dividend'>Current Dividend Processing Index
                  <Info size={10} style={{ marginLeft: '3px' }} />
                </div>
                <UncontrolledTooltip placement='top' target='current-dividend'>
                  The current index number of the holder that rewards are being distributed from the total dividend holders.
                </UncontrolledTooltip>
                <div className="data-info-value">
                  {rewardsInfo ? rewardsInfo.currentProcessingIndex : 0}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type" id='pending-dividend-holders'>Pending Dividend Holders Count
                  <Info size={10} style={{ marginLeft: '3px' }} />
                </div>
                <UncontrolledTooltip placement='top' target='pending-dividend-holders'>
                  Pending dividend holders amount yet be process to share the rewards.
                </UncontrolledTooltip>
                <div className="data-info-value">
                  {rewardsInfo ? rewardsInfo.pendingRewardsHoldersCount : 0}
                </div>
              </div>


              <div className="data-info-row">
                <div className="data-info-type">Rewards Distribution Completion: </div>
                <div className="data-info-value" style={{ marginBottom: '10px' }}>
                  {n2.format(rewardsInfo ? rewardsInfo.completionRatioPercentage : 0)} %
                </div>
              </div>

              <div className="data-info-progress-tracker">
                <div className='data-info-progress-tracker-title'>
                  Dividend Distribution Progress
                </div>
                <div className='progress-bar-dividend'>
                  <Progress
                    percent={n2.format(rewardsInfo ? rewardsInfo.completionRatioPercentage : 0)}
                    showInfo={false}
                    strokeColor="##647BE7"
                    status="active"
                    trailColor='#d9d9d9'
                  />
                  {/* <Progress className='progress-bar-primary' value={ n2.format(rewardsInfo ? rewardsInfo.completionRatioPercentage : 0) }>
                { n2.format(rewardsInfo ? rewardsInfo.completionRatioPercentage : 0) } %
                </Progress> */}
                </div>
              </div>


            </div>
          </div>
        )
      }

    </Card>
  )
}
