/* eslint-disable react-hooks/exhaustive-deps */
// ** Reactstrap Imports
import { Row, Col, Card } from 'reactstrap';
import { useEffect, useState } from 'react';
import { Spin, Button, Input } from 'antd';
import './NFTTracker.css'
import { useWeb3React } from '@web3-react/core';
import { useMoralis } from 'react-moralis';
import { configs } from '../../blockchain/web3.config';
import WAValidator from 'wallet-address-validator'
import { LoadingOutlined } from '@ant-design/icons'
import NFTContainer from './NFTContainer';

const NFTTracker = () => {

  const limit = 12
  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [isLoading, setIsLoading] = useState(false)
  const [yourNftList, setYourNftList] = useState([])
  const [walletAddress, setWalletAddress] = useState(null)
  const { nftContractConfig } = configs
  const [isLoadMoreAvailable, setIsLoadMoreAvailable] = useState(true)
  const [isLoadingMoreNFTs, setIsLoadingMoreNFTs] = useState(false)
  const [cursor, setCursor] = useState('')
  const { account } = useWeb3React()
  const { Moralis, isInitialized } = useMoralis()

  const { Search } = Input;

  const handleWalletAddressInput = (value) => {
    if (value) {
      let valid = WAValidator.validate(value, 'ETH');
      if (valid) {
        setWalletAddress(value)
      } else {
        setWalletAddress(null)
      }
    }
  }

  useEffect(() => {
    if (!account) {
      setYourNftList([])
      setWalletAddress(null)
    } else {
      setCursor(0)
      setYourNftList([])
      setWalletAddress(account)
    }
  }, [account])

  useEffect( () => {
    async function fetchData() {
      if (isInitialized && walletAddress) {
        setYourNftList([])
        await fetchNFTData(walletAddress, limit)
      }
    }
    fetchData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, walletAddress])

  const fetchNFTData = async (walletAddress, limit) => {
    try {

      setIsLoading(true)
      const options = { chain: nftContractConfig.moralisChainId, address: walletAddress, limit: limit }
      const userNFTs = await Moralis.Web3API.account.getNFTs(options)
      setCursor(userNFTs.cursor || '')
      if (userNFTs.result && userNFTs.result.length > 0) {
        setYourNftList(yourNftList.concat(userNFTs.result))
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setYourNftList([])
      console.error("MORALIS ERROR error while fetching your NFTS ", error)
    }
  }


  const handleLoadMore = async () => {
    if (cursor !== '') {
      setIsLoadMoreAvailable(true)
      setIsLoadingMoreNFTs(true)
      const options = { chain: nftContractConfig.moralisChainId, address: walletAddress, limit: limit, cursor }
      const loadMoreNFTs = await Moralis.Web3API.account.getNFTs(options)
      setCursor(loadMoreNFTs.cursor || '')
      if (loadMoreNFTs.result && loadMoreNFTs.result.length > 0) {
        setYourNftList(yourNftList.concat(loadMoreNFTs.result))
      }
      setIsLoadingMoreNFTs(false)
      console.log('loadMoreNFTs', loadMoreNFTs)
    } else {
      setIsLoadingMoreNFTs(false)
      setIsLoadMoreAvailable(false)
    }
  }

  return (

    <div className="container mt-4 p-4 nft-minting-container">
      <Row>
        <Col>
          <Card className="ever-earn-card-bg p-4">

            <Row>
              <Col sm="12" md="12" lg="12">
                <div className="search-container">
                  <Search
                    placeholder="Enter your wallet address here"
                    className="nft-search-bar"
                    onChange={e => handleWalletAddressInput(e.target.value)}
                    enterButton="Search NFT"
                    size="large"
                    loading={isLoading} />
                </div>
              </Col>
            </Row>


            {
              isLoading ?
                (
                  <div className="nft-loader-container">
                    <Spin indicator={spinnerIcon} />
                  </div>
                ) :
                (

                  <div className="nft-list-container">
                    <Row>
                      {
                        yourNftList.map((nft, index) => (
                          <Col sm="12" md="6" lg="3" key={index}>
                            <NFTContainer
                              data={nft}
                              key={index}
                            />
                          </Col>
                        ))
                      }
                    </Row>
                  </div>

                )
            }

            <div className="load-more-btn">
              <Button
                loading={isLoading || isLoadingMoreNFTs}
                onClick={handleLoadMore}
                disabled={!isLoadMoreAvailable}
                style={{ marginTop: '20px' }}
                type="primary"
                className='mint-button'
              >
                Load More
              </Button>
            </div>

          </Card>
        </Col>
      </Row>
    </div>

  );
};

export default NFTTracker;
