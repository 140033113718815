import { createSlice } from "@reduxjs/toolkit";

// initialize userToken from local storage
const themeState = localStorage.getItem('ever-earn-theme')
  ? localStorage.getItem('ever-earn-theme') : "dark"

const initialState = {
  theme: themeState
}

export const themeSlice = createSlice({
  name: "ever-earn-theme",
  initialState,
  reducers: {
    darkTheme: (state) => {
      localStorage.setItem('ever-earn-theme', "dark")
      state.theme = "dark"
    },
    lightTheme: (state) => {
      localStorage.setItem('ever-earn-theme', "light")
      state.theme = "light"
    },
    semiTheme: (state) => {
      localStorage.setItem('ever-earn-theme', "semi")
      state.theme = "semi"
    }
  }
});

// Action creators are generated for each case reducer function
export const { darkTheme, lightTheme, semiTheme } = themeSlice.actions;

export default themeSlice.reducer;