import React, { useEffect, useState } from 'react'
import './Dashboard.css'
import { Button, Spin } from 'antd';
import { useWeb3React } from '@web3-react/core'
import { AreaChart, Area, XAxis, Tooltip, YAxis } from 'recharts';
import Profile from '../../images/everearn-logo.png'
import busdLogo from '../../images/busd-logo.png'
import {
    Row,
    Col
} from 'reactstrap'
import useUserTokenInfo from '../../hooks/useUserTokenInfo';
import useCommonTokenData from '../../hooks/useCommonTokenData';
import NumberFormat from 'react-number-format'
import * as web3Service from '../../blockchain/web3.service'
import useLast24HoursTokenPriceData from '../../hooks/useLast24HoursTokenPriceData';
import useTokenLiquidityData from '../../hooks/useTokenLiquidityData';
import useTotalBurnedData from '../../hooks/useTotalBurnedData';
import { configs } from '../../blockchain/web3.config'
import * as multiChainService from '../../blockchain/services/multichain.service'
import { multiChainConfigs } from '../../blockchain/configs/multichain.configs';

function DashboardBSC() {

    const { web3Config } = configs
    const { account, library } = useWeb3React()

    const {
        tokenHoldings,
        busdHoldings,
        busdEarning,
        claimableRewardsAmountInUSD,
        isLoading: userTokenInfoLoading,
    } = useUserTokenInfo({ walletAddress: account })

    const { tokenMetaData, isLoading: commonTokenDataIsLoading } = useCommonTokenData()
    const [isTokenClaimLoading, setIsTokenClaimLoading] = useState(false)
    const [totalTokenBalanceInUSD, setTotalTokenBalanceInUSD] = useState(0.0)
    const { isLoading: isTokenPriceChartLoading, last24hoursPricingData } = useLast24HoursTokenPriceData()
    const { isLoading: isTokenLiquidityDataLoading, liquidityInfo } = useTokenLiquidityData({ contractAddress: web3Config.contractWallets.main })
    const { isLoading: isTokenBurnedDataLoading, tokenBurnedData } = useTotalBurnedData({ contractAddress: web3Config.contractWallets.main })

    const currentCurrency = 'usd'

    const [tokenPrice, setTokenPrice] = useState(0.0)
    const [isTokenPriceLoading, setIsTokenPriceLoading] = useState(false)
    const [tokenMarketCap, setTokenMarketCap] = useState(0.0)
    const [isMarketCapLoading, setIsMarketCapLoading] = useState(false)

    useEffect(() => {

        const fetchTokenPrice = async () => {
            setIsTokenPriceLoading(true)
            const { web3Config } = multiChainConfigs
            const mainTokenAddress = web3Config.contractWallets.main
            const tokenPrice = await multiChainService.getTokenPriceInUSDByTokenAddress('bsc', mainTokenAddress)
            setTokenPrice(parseFloat(tokenPrice))
            setIsTokenPriceLoading(false)
        }

        fetchTokenPrice()

    }, [])

    useEffect(() => {

        const fetchMarketCap = async () => {
            setIsMarketCapLoading(true)
            const marketCapResponse = await multiChainService.getTokenMarketCapIUnUSDByTokenAddress('bsc')
            setTokenMarketCap(marketCapResponse)
            setIsMarketCapLoading(false)
        }

        fetchMarketCap()

    }, [])


    useEffect(() => {
        if (tokenMetaData && tokenHoldings) {
            const currentTokenPriceInUSD = tokenMetaData.market_data.current_price[currentCurrency]
            // setTokenPrice(parseFloat(currentTokenPriceInUSD))

            //calculate total token holding value in usd
            const totalTokenHoldingValueInUSD = parseFloat(currentTokenPriceInUSD) * parseFloat(tokenHoldings)
            setTotalTokenBalanceInUSD(totalTokenHoldingValueInUSD)
        }

        if (tokenMetaData) {
            const currentTokenPriceInUSD = tokenMetaData.market_data.current_price[currentCurrency]
            // setTokenPrice(parseFloat(currentTokenPriceInUSD))
        }
    }, [tokenMetaData, tokenHoldings])



    const handleClaimRewards = async () => {
        try {
            setIsTokenClaimLoading(true)
            if (account) {
                const response = await web3Service.executeClaimRewards(library.getSigner())
                setIsTokenClaimLoading(false)
                console.log('claim rewards success ', response)
            }

        } catch (error) {
            setIsTokenClaimLoading(false)
            console.error("ERROR while claim your pending rewards token amount ", error)
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            console.log('payload', payload)
            return (
                <div className="custom-tooltip">
                    <p className="label">
                        EARN TOKEN PRICE :
                        <NumberFormat
                            value={payload ? payload[0].value : 0.00}
                            displayType="text"
                            decimalScale={8}
                            thousandSeparator={true}
                            prefix="~$"

                        />
                    </p>
                    <p className="intro">DATE : {label ? label : ''}</p>
                </div>
            );
        }

        return null;
    };


    return (

        <div className="container">

            <Row>
                <Col>
                    <div className='ever-earn-card-bg' >
                        <div className="row text-center">
                            <Col sm="6" md="6" lg="3" className="mt-4 hide-on-mobile">
                                <div className="row">
                                    <h6 className="card-text">PORTFOLIO</h6>
                                </div>

                                <div className="row mt-3">
                                    <img alt="profile-image" src={Profile} style={{ width: '50px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                                </div>

                                <div className="mt-3">
                                    {
                                        account ? <span className='badge badge-green'>Connected</span> : <span className='badge badge-green'>Not connected</span>
                                    }

                                </div>
                            </Col>

                            <Col sm="12" md="12" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">MARKET CAP</h6>

                                    <div className="row">
                                        {
                                            isMarketCapLoading ?
                                                (<><Spin size="small" /></>)
                                                :
                                                (<h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={tokenMarketCap ? tokenMarketCap : 0.0}
                                                        displayType="text"
                                                        decimalScale={2}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>)
                                        }

                                    </div>
                                </div>
                            </Col>

                            <Col sm="12" md="12" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">TOTAL Liquidity</h6>

                                    {
                                        isTokenLiquidityDataLoading ?
                                            (<><Spin size="small" className='loading-spin' /></>)
                                            :
                                            (<h2 className="text-light-green">
                                                <NumberFormat
                                                    value={liquidityInfo ? liquidityInfo.totalLiquidityCumulativeBalanceInUSD : 0.0}
                                                    displayType="text"
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    prefix="~$"

                                                />
                                            </h2>)
                                    }


                                    <div className="row liquidity-info-container">
                                        <div className="col-6 text-start">
                                            <span className="card-text">
                                                BNB Pool
                                            </span>
                                        </div>

                                        <div className="col-6 text-end">
                                            <span className="text-light liquidity-values">

                                                {
                                                    isTokenLiquidityDataLoading ?
                                                        (<><Spin size="small" /></>)
                                                        :
                                                        (<>

                                                            <NumberFormat
                                                                value={liquidityInfo ? liquidityInfo.liquidityPoolDataList[1].wbnbAmount : 0.0}
                                                                displayType="text"
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix=""
                                                                suffix=' BNB'

                                                            />
                                                        </>)
                                                }

                                            </span>
                                        </div>

                                    </div>


                                    <div className="row liquidity-info-container">
                                        <div className="col-6 text-start">
                                            <span className="card-text">BNB Pool (USD)</span>
                                        </div>

                                        <div className="col-6 text-end">
                                            <span className="text-light liquidity-values">
                                                {
                                                    isTokenLiquidityDataLoading ?
                                                        (<><Spin size="small" /></>)
                                                        :
                                                        (<>

                                                            <NumberFormat
                                                                value={liquidityInfo ? liquidityInfo.liquidityPoolDataList[1].wBnbBalanceInUSD : 0.0}
                                                                displayType="text"
                                                                decimalScale={2}
                                                                thousandSeparator={true}
                                                                prefix="~$"

                                                            />
                                                        </>)
                                                }
                                            </span>
                                        </div>

                                    </div>

                                    <div className="row liquidity-info-container">
                                        <div className="col-4 text-start">
                                            <span className="card-text">
                                                TOKEN POOL
                                            </span>
                                        </div>


                                        <div className="col-8 text-end">
                                            <span className="text-light liquidity-values">{
                                                isTokenLiquidityDataLoading ?
                                                    (<><Spin size="small" /></>)
                                                    :
                                                    (<>

                                                        <NumberFormat
                                                            value={liquidityInfo ? liquidityInfo.liquidityPoolDataList[1].tokenAmount : 0.0}
                                                            displayType="text"
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            prefix=""
                                                            suffix=' EARN'

                                                        />
                                                    </>)
                                            }</span>
                                        </div>

                                    </div>

                                </div>
                            </Col>

                            <Col sm="12" md="12" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">TOTAL BURNED TO DATE</h6>
                                    {
                                        isTokenBurnedDataLoading ?
                                            (<><Spin size="small" /></>)
                                            :
                                            (<h2 className="text-light-green">

                                                <NumberFormat
                                                    value={tokenBurnedData ? tokenBurnedData.burnedTokenBalanceInUSD : 0.0}
                                                    displayType="text"
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    prefix="~$"

                                                />
                                            </h2>)
                                    }
                                </div>
                            </Col>

                        </div>

                        <Row className="mt-5 row text-center">

                            <Col sm="12" md="8" lg="8" className="my-5 chart-widget">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className="chart overflow-auto mobile-chart">
                                        <AreaChart
                                            width={300}
                                            height={150}
                                            loading={isTokenPriceChartLoading}
                                            data={last24hoursPricingData}
                                            margin={{
                                                top: 10,
                                                right: 0,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <defs>
                                                <linearGradient id="colorUvMobile" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#EFBC00" stopOpacity={0.8} />
                                                    <stop offset="95%" stopColor="#EFBC00" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis dataKey="name" />
                                            <YAxis type="number" domain={['auto', 'dataMax']} dataKey="uv" />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Area type="monotone" dataKey="uv" stroke="rgba(239,188,0,1)" fill="url(#colorUvMobile)" />
                                        </AreaChart>
                                    </div>
                                </div>
                                <div className="chart overflow-auto desktop-chart mx-5">
                                    <AreaChart
                                        width={700}
                                        height={200}
                                        loading={isTokenPriceChartLoading}
                                        data={last24hoursPricingData}
                                        margin={{
                                            top: 10,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="1%" stopColor="#EFBC00" stopOpacity={2} />
                                                <stop offset="4%" stopColor="#EFBC00" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#EFBC00" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>

                                        <XAxis dataKey="name" />
                                        <YAxis type="number" domain={['auto', 'dataMax']} dataKey="uv" />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Area type="monotone" dataKey="uv" stroke="rgba(239,188,0,1)" fill="url(#colorUv)" />
                                    </AreaChart>
                                </div>
                            </Col>

                            <Col sm="12" md="3" lg="3" className='my-5 chart-widget'>
                                <h6 className="card-text">REWARDS NOT CLAIMED (USD)</h6>

                                {
                                    userTokenInfoLoading || commonTokenDataIsLoading ?
                                        (
                                            <Spin size="small" />
                                        ) :
                                        (
                                            <> <div className="row">
                                                <h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={claimableRewardsAmountInUSD ? claimableRewardsAmountInUSD : 0.0}
                                                        displayType="text"
                                                        decimalScale={3}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>
                                            </div>

                                                <Button
                                                    className='mint-button'
                                                    type="primary"
                                                    loading={isTokenClaimLoading}
                                                    onClick={handleClaimRewards}
                                                    size="small"
                                                >
                                                    CLAIM REWARDS
                                                </Button></>
                                        )
                                }

                            </Col>
                        </Row>
                        <Row >
                            <Col sm="12" md="12" lg="2"></Col>
                            <Col sm="12" md="12" lg="3" className="project-contracts-section">
                                <div className="project-contracts-section">
                                    <div className="contract-details-container">
                                        <div className="contract-logo">
                                            <img className='sidebar-icon mt-1' src={Profile} alt='logo' height={'18px'} />
                                        </div>
                                        <div className="contract-name">
                                            EverEarn Contract
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="12" lg="6">
                                <div className="contract-address-section">
                                    0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C
                                </div>
                            </Col>
                        </Row>

                        <Row className='mb-4'>
                            <Col sm="12" md="12" lg="2"></Col>
                            <Col sm="12" md="12" lg="3" className="project-contracts-section">
                                <div className="project-contracts-section">
                                    <div className="contract-details-container">
                                        <div className="contract-logo">
                                            <img className='sidebar-icon mt-1' src={busdLogo} alt='logo' height={'18px'} />
                                        </div>
                                        <div className="contract-name">
                                            BUSD Contract
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col sm="12" md="12" lg="6">
                                <div className="contract-address-section">
                                    0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Col>
            </Row>

            <Row>
                <Col sm='12' md='12' lg='12'>
                    <div className="ever-earn-card-bg mt-4 p-4">
                        <Row className="text-center mb-3">
                            <Col sm="6" md="3" lg="3" className="mt-3">
                                <div className="row">
                                    <h6 className="card-text">EARN PRICE <span className="badge badge-green"></span></h6>

                                    <div className="row">

                                        {
                                            isTokenPriceLoading ?
                                                (
                                                    <Spin size="small" />
                                                ) :
                                                (<h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={tokenPrice ? tokenPrice : 0.0}
                                                        displayType="text"
                                                        decimalScale={10}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>)
                                        }

                                    </div>
                                </div>
                            </Col>

                            <Col sm="6" md="3" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">HOLDINGS</h6>
                                </div>
                                {
                                    userTokenInfoLoading || commonTokenDataIsLoading ?
                                        (
                                            <Spin size="small" />
                                        ) :
                                        (
                                            <> <div className="row">
                                                <h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={totalTokenBalanceInUSD ? totalTokenBalanceInUSD : 0.0}
                                                        displayType="text"
                                                        decimalScale={3}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>
                                            </div>


                                                <div>
                                                    <span className='badge badge-green'>
                                                        <NumberFormat
                                                            value={tokenHoldings ? tokenHoldings : 0.0}
                                                            displayType="text"
                                                            decimalScale={3}
                                                            thousandSeparator={true}
                                                            suffix=" EARN"

                                                        />
                                                    </span>
                                                </div></>
                                        )
                                }
                            </Col>

                            <Col sm="6" md="3" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">BUSD HOLDING</h6>
                                </div>

                                {
                                    userTokenInfoLoading || commonTokenDataIsLoading ?
                                        (
                                            <Spin size="small" />
                                        ) :
                                        (
                                            <> <div className="row">
                                                <h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={busdHoldings ? busdHoldings : 0.0}
                                                        displayType="text"
                                                        decimalScale={3}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>
                                            </div>


                                                <div>
                                                    <span className='badge badge-green'>
                                                        <NumberFormat
                                                            value={busdHoldings ? busdHoldings : 0.0}
                                                            displayType="text"
                                                            decimalScale={3}
                                                            thousandSeparator={true}
                                                            suffix=" BUSD"

                                                        />
                                                    </span>
                                                </div></>
                                        )
                                }
                            </Col>

                            <Col sm="6" md="3" lg="3" className="mt-4">
                                <div className="row">
                                    <h6 className="card-text">YOUR TOTAL BUSD EARNING</h6>
                                </div>
                                {
                                    userTokenInfoLoading || commonTokenDataIsLoading ?
                                        (
                                            <Spin size="small" />
                                        ) :
                                        (
                                            <> <div className="row">
                                                <h2 className="text-light-green">
                                                    <NumberFormat
                                                        value={busdEarning ? busdEarning : 0.0}
                                                        displayType="text"
                                                        decimalScale={3}
                                                        thousandSeparator={true}
                                                        prefix="~$"

                                                    />
                                                </h2>
                                            </div>


                                                <div>
                                                    <span className='badge badge-green'>
                                                        <NumberFormat
                                                            value={busdEarning ? busdEarning : 0.0}
                                                            displayType="text"
                                                            decimalScale={3}
                                                            thousandSeparator={true}
                                                            suffix=" BUSD"

                                                        />
                                                    </span>
                                                </div></>
                                        )
                                }

                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default DashboardBSC
