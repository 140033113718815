/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Radio, Table } from 'antd';
import { Row, Col, Card } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useContext } from 'react'
import { useMoralis } from 'react-moralis'
import { blackList } from '../../Helpers/backlist'
import { GlobalState } from '../../GlobalState';
import Avatar from 'react-avatar';
import * as web3Service from '../../blockchain/web3.service'
import { DateTime } from 'luxon'
import { useWeb3React } from '@web3-react/core';
import WAValidator from 'wallet-address-validator'
import NumberFormat from 'react-number-format'
import './WalletTracker.css'

function WalletTracker() {

  const [walletAddress, setWalletAddress] = useState(null)
  const { isInitialized, Moralis } = useMoralis()
  const [yourTokens, setYourTokens] = useState([])
  const [totalBalance, setTotalBalance] = useState(0.0)
  const [isTokenListLoading, setIsTokenListLoading] = useState(false)
  const [rawTokenResponse, setRawTokenResponse] = useState([])
  const [nativeCurrencyData, setNativeCurrencyData] = useState(null)
  const [currencyType, setCurrencyType] = useState('BNB')
  const [coinType, setCoinType] = useState('bsc')
  const { account } = useWeb3React()

  const { Search } = Input;

  const columns = [
    {
      title: 'Name',
      key: 'name',
      // width: "250px",
      render: (text, record) => (
        <div className='d-lg-flex flex-row token-metadata-container'>
          <Avatar
            className='hidden-on-mobile'
            size='40'
            round="20px"
            style={{ marginRight: '20px' }}
            src={text ? text.tokenData.thumbnail : null}
            name={text ? text.tokenData.name : ''} />
          <div className='token-name-container'>
            <div>{text ? text.tokenData.name : ''}</div>
            <div className="text-warning  ">  {text ? text.tokenData.symbol : ''}</div>
          </div>
        </div>
      ),
      sorter: (a, b) => a.tokenData.name.localeCompare(b.tokenData.name),
      sortDirections: ['descend']
    },
    {
      title: 'Amount',
      right: true,
      render: (text, record) => (
        <div className='row-token-balance table-mobile-font'>{
          <NumberFormat
            value={text ? text.actualTokenAmount : 0.00}
            displayType="text"
            decimalScale={2}
            thousandSeparator={true}
            prefix=""

          />

        } </div>
      ),
      sorter: (a, b) => parseInt(a.actualTokenAmount) - parseInt(b.actualTokenAmount),
    },
    {
      title: 'Price',
      render: (text) => (
        <div className='table-mobile-font'>{
          <NumberFormat
            value={text ? text.currentTokenPrice : 0.00}
            displayType="text"
            decimalScale={5}
            thousandSeparator={true}
            prefix="~$ "

          />

        } </div>
      ),
      sorter: (a, b) => a.currentTokenPrice - b.currentTokenPrice,
    },
    {
      title: 'Total',
      render: (text) => (
        <div className='table-mobile-font'>{
          <NumberFormat
            value={text ? text.totalTokenBalance : 0.00}
            displayType="text"
            decimalScale={5}
            thousandSeparator={true}
          />

        } </div>
      ),
      sorter: (a, b) => a.totalTokenBalance - b.totalTokenBalance,
    },
    {
      title: '24 Hr P/L',
      // width: "150px",
      render: (text) => (
        <div className='row-token-balance table-mobile-font'>
          <div className={text.percentageLast24Hours && text.percentageLast24Hours < 0 ? 'custom_red' : 'custom_green'}>
            {text.percentageLast24Hours.toFixed(2)} %
          </div>
        </div>

      ),
      sorter: (a, b) => a.percentageLast24Hours - b.percentageLast24Hours,
    },
    {
      title: '7 Days P/L',
      // width: "150px",
      render: (text) => (
        <div className='row-token-balance table-mobile-font'>
          <div className={text.percentageLast24Hours && text.percentageLast24Hours < 0 ? 'custom_red' : 'custom_green'}>
            {text.percentageLast7Days.toFixed(2)} %
          </div>
        </div>

      ),
      sorter: (a, b) => a.percentageLast7Days - b.percentageLast7Days,
    },
    {
      title: '30 Days P/L',
      // width: "150px",
      render: (text) => (
        <div className='row-token-balance table-mobile-font'>
          <div className={text.percentageLast24Hours && text.percentageLast24Hours < 0 ? 'custom_red' : 'custom_green'}>
            {text.percentageLast30Days.toFixed(2)} %
          </div>
        </div>

      ),
      sorter: (a, b) => a.percentageLast30Days - b.percentageLast30Days,
    },
  ];

  const networks = [
    { label: 'BSC', value: 'bsc' },
    { label: 'ETH', value: 'eth' },
    { label: 'Matic', value: 'matic' },
  ];


  const handleWalletAddressInput = (value) => {
    setWalletAddress(value)
  }

  const handleNetworkChange = (e) => {
    setCoinType(e.target.value)
    if (e.target.value === 'eth') {
      setCurrencyType("ETH")
    }
    if (e.target.value === 'bsc') {
      setCurrencyType("BNB")
    }
    if (e.target.value === 'matic') {
      setCurrencyType("Matic")
    }
  }

  useEffect(() => {
    window.localStorage.removeItem('tokenAmountList')
    window.localStorage.removeItem('tokenPriceList')
    window.localStorage.removeItem('tokenBalanceList')
  }, []);

  useEffect(() => {
    if (account) {
      setWalletAddress(account)
    }
  }, [account]);

  useEffect(() => {
    if (yourTokens && yourTokens.length > 0) {
      window.localStorage.removeItem('userTokenList')
      window.localStorage.setItem('userTokenList', JSON.stringify(yourTokens))
    } else {
      window.localStorage.setItem('userTokenList', [])
    }
  }, [yourTokens]);

  useEffect( () => {

    async function fetchData() {
      if (walletAddress && isInitialized) {
        try {
          setIsTokenListLoading(true)
          const past24HoursTime = DateTime.now().minus({ day: 1 }).toISODate()
          const past7DaysTime = DateTime.now().minus({ day: 7 }).toISODate()
          const past30DaysTime = DateTime.now().minus({ day: 30 }).toISODate()
  
          const last24HoursParam = { chain: coinType, date: past24HoursTime }
          const last24HoursResponse = await Moralis.Web3API.native.getDateToBlock(last24HoursParam)
  
          const last7DaysParam = { chain: coinType, date: past7DaysTime }
          const last7DaysResponse = await Moralis.Web3API.native.getDateToBlock(last7DaysParam)
  
          const last30DaysParam = { chain: coinType, date: past30DaysTime }
          const last30DaysResponse = await Moralis.Web3API.native.getDateToBlock(last30DaysParam)
  
  
          const blockData = {
            last24HourBlock: last24HoursResponse.block,
            last7DaysBlock: last7DaysResponse.block,
            last30DaysBlock: last30DaysResponse.block,
          }
          web3Service.calculateNativeCurrencyData(coinType, walletAddress, blockData)
            .then(response => {
              setNativeCurrencyData(response)
            }).catch(error => {
              console.log('error while fetching native price data', error)
              setNativeCurrencyData(null)
            })
  
          const options = { chain: coinType, address: walletAddress };
          const userTokenList = await Moralis.Web3API.account.getTokenBalances(options);
          const cleanlist = userTokenList.filter((listItem) => !blackList.find(({ token_address }) => listItem.token_address === token_address))
          const tokenDataList = await web3Service.calculateTokenData(coinType, cleanlist, blockData)
          const processedData = web3Service.processDataForRendering(tokenDataList)
  
          if (processedData) {
            setYourTokens(processedData.tableDataSet || [])
            setTotalBalance(processedData.tokenTotalAmount || 0.0)
          } else {
            setYourTokens([])
            setTotalBalance(0.0)
          }
          setIsTokenListLoading(false)
        } catch (error) {
          console.error("ERROR error while fetching main token list", error)
          setRawTokenResponse([])
          setIsTokenListLoading(false)
        }
      }
    }
    fetchData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, walletAddress, coinType])


  useEffect(() => {
    if (rawTokenResponse && rawTokenResponse.length > 0) {
      const tableDataSet = []
      let tokenTotalAmount = 0.0
      rawTokenResponse.forEach(tokenData => {

        if (tokenData.status === 'fulfilled') {
          //calculate actual token amount
          const tokenMetadata = tokenData.value.tokenData
          const tokenBalance = parseInt(tokenMetadata.balance || 0)
          const tokenDecimals = parseInt(tokenMetadata.decimals || 1)
          const actualTokenAmount = tokenBalance / (10 ** tokenDecimals)

          //get current token price
          const tokenPriceData = tokenData.value.priceData
          const currentPrice = tokenPriceData.tokenPriceCurrent.usdPrice || 0.00

          const totalTokenPrice = parseFloat(actualTokenAmount) * parseFloat(currentPrice)

          tokenTotalAmount = tokenTotalAmount + totalTokenPrice

          //last 24 hours pricing data
          const last24HoursPrice = tokenPriceData.tokenPrice24HorsAgo.usdPrice || 0.00
          const last7DaysPrice = tokenPriceData.tokenPrice7DaysAgo.usdPrice || 0.00
          const last30DaysPrice = tokenPriceData.tokenPrice30DaysAgo.usdPrice || 0.00

          //calculate price fractionation
          const deltaChangeLast24Hours = parseFloat(currentPrice) - parseFloat(last24HoursPrice)
          const fluctuationLst24Hours = deltaChangeLast24Hours / parseFloat(last24HoursPrice)
          const percentageLast24Hours = fluctuationLst24Hours * 100

          const deltaChangeLast7Days = parseFloat(currentPrice) - parseFloat(last7DaysPrice)
          const fluctuationLst7days = deltaChangeLast7Days / parseFloat(last7DaysPrice)
          const percentageLast7Days = fluctuationLst7days * 100

          const deltaChangeLast30Days = parseFloat(currentPrice) - parseFloat(last30DaysPrice)
          const fluctuationLst30days = deltaChangeLast30Days / parseFloat(last30DaysPrice)
          const percentageLast30Days = fluctuationLst30days * 100

          const payload = {
            tokenData: tokenMetadata,
            currentTokenPrice: currentPrice,
            actualTokenAmount: actualTokenAmount,
            totalTokenBalance: totalTokenPrice,
            percentageLast24Hours: percentageLast24Hours,
            percentageLast7Days: percentageLast7Days,
            percentageLast30Days: percentageLast30Days

          }
          tableDataSet.push(payload)
        }

      })
      setYourTokens(tableDataSet)
      setTotalBalance(tokenTotalAmount)

    } else {
      setYourTokens([])
      setTotalBalance(0.00)
    }
  }, [rawTokenResponse])



  useEffect(() => {
    console.log('NT yourTokens', yourTokens)
    console.log('NT totalBalance', totalBalance)
  }, [yourTokens, totalBalance])

  return (
    <div className="container mt-4 p-4 nft-minting-container">
      <Row>
        <Col>
          <Card className="ever-earn-card-bg p-4">
            <Row>
              <Col sm="12" md="12" lg="12">
                <div className="search-container">
                  <Search
                    placeholder="Enter your wallet address here"
                    className="nft-search-bar"
                    onChange={e => handleWalletAddressInput(e.target.value)}
                    enterButton="Search Your Tokens"
                    value={walletAddress}
                    size="large"
                    loading={isTokenListLoading} />
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6" lg="6">
                <div className="total-token-balance">
                  <NumberFormat
                    value={totalBalance ? totalBalance : 0.00}
                    displayType="text"
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix="~$ "

                  />
                </div>

                <div className="bnb-balance-details">
                  Your {currencyType} balance <NumberFormat
                    value={nativeCurrencyData ? nativeCurrencyData.accountBalance : 0.00}
                    displayType="text"
                    decimalScale={5}
                    thousandSeparator={true}
                    prefix=""

                  />  ~  <NumberFormat
                    value={nativeCurrencyData ? nativeCurrencyData.accountBalanceInUSD : 0.00}
                    displayType="text"
                    decimalScale={5}
                    thousandSeparator={true}
                    suffix=" USD"

                  />
                </div>

                <div className='native-fluctuation-container'>
                  <div className={nativeCurrencyData && nativeCurrencyData.percentageLast24Hours < 0 ? 'last-24-hours  fluctuation-red fl-container' : 'last-24-hours  fluctuation-green fl-container'}>
                    <NumberFormat
                      value={nativeCurrencyData ? nativeCurrencyData.percentageLast24Hours : 0.00}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator={true}
                      suffix=" %"
                    />
                    <span className='fl-text'> 24 Hr P/L</span>
                  </div>
                  <div className={nativeCurrencyData && nativeCurrencyData.percentageLast7Days < 0 ? 'last-7-days  fluctuation-red fl-container' : 'last-7-days  fluctuation-green fl-container'}>
                    <NumberFormat
                      value={nativeCurrencyData ? nativeCurrencyData.percentageLast7Days : 0.00}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator={true}
                      suffix=" %"
                    />
                    <span className='fl-text'>7 Days P/L</span>
                  </div>
                  <div className={nativeCurrencyData && nativeCurrencyData.percentageLast30Days < 0 ? 'last-30-days  fluctuation-red fl-container' : 'last-30-days  fluctuation-green fl-container'}>
                    <NumberFormat
                      value={nativeCurrencyData ? nativeCurrencyData.percentageLast30Days : 0.00}
                      displayType="text"
                      decimalScale={2}
                      thousandSeparator={true}
                      suffix=" %"
                    />
                    <span className='fl-text'>30 Days P/L</span>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6" lg="6">
                <div className="network-selector-section">
                  <Radio.Group
                    className="network-selector"
                    // style={{ backgroundColor: 'red' }}
                    options={networks}
                    onChange={handleNetworkChange}
                    value={coinType}
                    optionType="button"
                    size="large"
                  />
                </div>

              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="12">
                <div className="warning-text">
                  Note: If you didn't buy those tokens, you're not rich. There are many scam tokens we're working on filtering out for your own safety. For now, do not touch them!
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12" lg="12">
                <div className="token-list-table">
                  <Table
                    columns={columns}
                    dataSource={yourTokens}
                    loading={isTokenListLoading}
                    pagination={false}
                    className="virtual-grid"
                  />

                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>

  )
}

export default WalletTracker