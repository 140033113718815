// ** Reactstrap Imports
import { Card } from 'reactstrap'
import useTokenMetadataInfo from '../../hooks/useTokenMetadataInfo'
import { tokenValueTxt, n12, n2, getEllipsisTxt } from '../../Helpers/Formatters'
import { LoadingOutlined } from '@ant-design/icons';

export default function TokenMetadataInfo() {


  const { tokenMetaDataInfo, isLoading } = useTokenMetadataInfo()



  return (
    <Card className="ever-earn-card-bg mt-4 p-4">
      {
        isLoading ? (
          <div className="data-info-widget">
            <div className='text-center'>
              <LoadingOutlined className="spin-color" />
            </div>
          </div>

        ) : (
          <div className="data-info-widget">
            <div className='data-info-widget-title'>
              Token Metadata
            </div>
            <div className="data-info-widget-container">

              <div className="data-info-row">
                <div className="data-info-type">Contract Address:</div>
                <a href={`https://etherscan.io//address/${tokenMetaDataInfo ? tokenMetaDataInfo.mainTokenAddress : ''}`} target='_blank' className='blockchain-link' rel="noreferrer">
                  <div className="data-info-value">{getEllipsisTxt(tokenMetaDataInfo ? tokenMetaDataInfo.mainTokenAddress : '', 7)}</div>
                </a>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Token Name:</div>
                <div className="data-info-value">
                  {tokenMetaDataInfo ? tokenMetaDataInfo.tokenName : ''}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Token Symbol:</div>
                <div className="data-info-value">
                  {tokenMetaDataInfo ? tokenMetaDataInfo.tokenSymbol : ''}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Token Total Supply:</div>
                <div className="data-info-value">
                  {tokenValueTxt(tokenMetaDataInfo ? tokenMetaDataInfo.tokenTotalSupply : 0.0, 0, '')} {tokenMetaDataInfo ? tokenMetaDataInfo.tokenSymbol : ''}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Token Price:</div>
                <div className="data-info-value">
                  ~$ {n12.format(tokenMetaDataInfo ? tokenMetaDataInfo.tokenPrice : 0.0)}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Circulation Supply:</div>
                <div className="data-info-value">
                  {tokenValueTxt(tokenMetaDataInfo ? tokenMetaDataInfo.tokenCirculationSupply : 0.0, 0, '')} {tokenMetaDataInfo ? tokenMetaDataInfo.tokenSymbol : ''}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Total Burned Tokens:</div>
                <div className="data-info-value">
                  {tokenValueTxt(tokenMetaDataInfo ? tokenMetaDataInfo.tokenBurnedAmount : 0.0, 0, '')} {tokenMetaDataInfo ? tokenMetaDataInfo.tokenSymbol : ''}
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Total Burned Percentage:</div>
                <div className="data-info-value">
                  {n2.format(tokenMetaDataInfo ? tokenMetaDataInfo.tokenBurnedPercentage : 0.0)} %
                </div>
              </div>

              <div className="data-info-row">
                <div className="data-info-type">Market Cap:</div>
                <div className="data-info-value">
                  ~$ {tokenValueTxt(tokenMetaDataInfo ? tokenMetaDataInfo.tokenMarketCap : 0.0, 0, '')}
                </div>
              </div>

            </div>
          </div>
        )
      }

    </Card>
  )
}
