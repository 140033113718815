import React from 'react'
import { stakingConfigs } from './services/staking.config'
import TokenDetailsSection from './components/TokenDetailsSection'
import WithdrawAndExitButton from './components/WithdrawAndExitButton'
import EarlyWithdrawButton from './components/EarlyWithdrawButton'
import StakingApyWidget from './components/StakingApyWidget'
import StakingInput from './components/StakingInput'
import UserStakingDetails from './components/UserStakingDetails'

export default function Staking60DaysPool(props) {

  const { walletAddress, setStakingAmount, stakingAmount } = props

  return (
    <>
      <div className='ever-earn-card-bg'>
          <div className="staking-days-card-header">
              <span>60 Days Pool - 40%</span>
          </div>
          <div className="p-4 staking-days-card">
            {
              walletAddress ? (
                <TokenDetailsSection walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
              ) : (
                <></>
              )
            }
            {
              walletAddress ? (
                <div className='row'>
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-3">
                    <WithdrawAndExitButton walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-3">
                    <EarlyWithdrawButton walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
                  </div>
                </div>
              ) : (
                <></>
              )
            }
            <div>
              <UserStakingDetails
                  walletAddress={walletAddress}
                  contractAddress={stakingConfigs.staking60DaysContractAddress} />
            </div>
            <div>
              <div>
                <StakingApyWidget contractAddress={stakingConfigs.staking60DaysContractAddress} days={60} />
              </div>
            </div>
            <div className="mt-4">
              <StakingInput
                  setStakingAmount={setStakingAmount}
                  stakingAmount={stakingAmount}
                  stakingDuration={7}
                  walletAddress={walletAddress}
                  contractAddress={stakingConfigs.staking60DaysContractAddress}
                />
            </div>
              
          </div>
      </div>
    </>
  )
}
