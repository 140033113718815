import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Alert
} from 'reactstrap'

import { useWeb3React } from "@web3-react/core"
import { User, TrendingUp, Link, RefreshCw, AlertCircle } from 'react-feather'
import { useEffect, useState } from 'react'
import TokePrice from '../../Components/RewardDashboard/TokenPrice'
import TokenMarketCap from '../../Components/RewardDashboard/TokenMarketCap'
import TokenDividendPaid from '../../Components/RewardDashboard/TokenDividendPaid'
import UserTokenInfo from '../../Components/RewardDashboard/UserTokenInfo'
import WAValidator from 'wallet-address-validator'
import TokenMetadataInfo from '../../Components/RewardDashboard/TokenMetadataInfo'
import RewardsInfo from '../../Components/RewardDashboard/RewardsInfo'
import UserRewardsInfo from '../../Components/RewardDashboard/UserRewardsInfo'
import RecentRewardsPayoutsList from '../../Components/RewardDashboard/RecentRewardsPayoutsList'

function RewardDashboard() {

  const { account } = useWeb3React()
  const [accountAddress, setAccountAddress] = useState('')
  const [isValidAccountAddress, setIsValidAccountAddress] = useState(false)

  useEffect(() => {
    if (account) {
      setAccountAddress(account)
    } else {
      setAccountAddress('')
    }
  }, [account])

  useEffect(() => {
    const valid = WAValidator.validate(accountAddress, 'ETH')
    if (valid) {
      setIsValidAccountAddress(true)
    } else {
      setIsValidAccountAddress(false)
    }
  }, [accountAddress])

  const changeAccountAddress = (e) => {

    //check the enter value is valid
    const walletAddress = e.target.value || ''
    const valid = WAValidator.validate(walletAddress, 'ETH')
    setAccountAddress(e.target.value)
    if (valid) {
      setIsValidAccountAddress(true)
    } else {
      setIsValidAccountAddress(false)
    }

  }

  return (
    <div className="container mt-4 p-4">
      <Row style={{ marginBottom: '20px' }}>
        <Col sm='12' md='12' lg='12'>
          <InputGroup className='input-group-merge mb-1'>
            <InputGroupText>
              <User size={15} />
            </InputGroupText>
            <Input type='text' name='Account Address' placeholder='Connected wallet address' value={accountAddress} onChange={changeAccountAddress} />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col sm='12' md='6' lg='4' className='mobile-top-margin'>
          <TokePrice icon={<Link size={21} />} color='primary' statTitle='Token Price' className={'common-user-widget'} />
        </Col>
        <Col sm='12' md='6' lg='4' className='mobile-top-margin'>
          <TokenMarketCap icon={<TrendingUp size={21} />} color='primary' statTitle='Token Market Cap' className={'common-user-widget'} />
        </Col>
        <Col sm='12' md='6' lg='4' className='mobile-top-margin'>
          <TokenDividendPaid icon={<RefreshCw size={21} />} color='primary' statTitle='Total Dividend Distributed' className={'common-user-widget'} />
        </Col>
      </Row>

      {
        isValidAccountAddress ? (<UserTokenInfo tokenAddress={accountAddress} />) : (
          <div className='mt-5'>
            <Alert color='primary' isOpen={true}>
              <div className='alert-body'>
                <AlertCircle size={15} />{' '}
                <span className='ms-1'>
                  Please connect your wallet or enter the valid wallet address
                </span>
              </div>
            </Alert>
          </div>
        )
      }

      <Row>
        <Col sm='12' md='12' lg='6'>
          <TokenMetadataInfo />
        </Col>
        <Col sm='12' md='12' lg='6'>
          <RewardsInfo />
        </Col>
      </Row>

      <Row>
        <Col sm='12' md='12' lg='12'>
          <UserRewardsInfo tokenAddress={accountAddress} isValidAccountAddress={isValidAccountAddress} />
        </Col>
      </Row>

      <Row>
        <Col sm='12' md='12' lg='12'>
          {/* <RecentRewardsPayoutsList /> */}
        </Col>
      </Row>
    </div>
  )
}

export default RewardDashboard