import { useEffect } from "react"
import useTokenDividendInfo from "../../hooks/useTokenDividendInfo"
import { tokenValueTxt } from "../../Helpers/Formatters"
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
export default function TokenDividendPaid(props) {

  const { icon, color, statTitle, className, statsMargin } = props

  const { isLoading, tokenDividendInfo } = useTokenDividendInfo()

  useEffect(() => {
    if (tokenDividendInfo) {
      console.log('tokenDividendInfo', tokenDividendInfo);
    }
  }, [tokenDividendInfo])

  return (
    <Card className="ever-earn-card-bg">
      <div className={className}>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            {isLoading ? (
              <div className="widget-loader">
                <div className='text-center'>
                  <LoadingOutlined className="spin-color" />
                </div>
              </div>
            ) : (
              <h4
                className={classnames('fw-bolder text-light-green', {
                  'mb-0': !statsMargin,
                  [statsMargin]: statsMargin
                })}
              >
                $ ~{tokenValueTxt(tokenDividendInfo ? tokenDividendInfo.totalDistributedDividendAmountInUSD : 0.0, 0, '')}
              </h4>
            )}

            <p className='card-text'>{statTitle}</p>
          </div>
          <div className={`avatar avatar-stats p-50 m-0 ${color ? `bg-light-${color}` : 'bg-light-primary'}`}>
            <div className='avatar-content'>{icon}</div>
          </div>
        </div>
      </div>
    </Card>
  )
}

// ** PropTypes
TokenDividendPaid.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  statTitle: PropTypes.string.isRequired,
  statsMargin: PropTypes.oneOf(['mb-0', 'mb-25', 'mb-50', 'mb-75', 'mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5'])
}

