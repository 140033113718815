import CommonUserStatWidget from "./CommonUserStatWidget"
import { Pocket, TrendingUp, Layers, Clock } from 'react-feather'
import useUserTokenInfo from "../../hooks/ETHhooks/useUserTokenInfo"

// ** Reactstrap Imports
import { Row, Col } from 'reactstrap'

export default function UserTokenInfo(props) {

  const { tokenAddress } = props
  const { tokenHoldings, tokenRewardsAmount, tokenRewardsLastPayoutTime, isLoading } = useUserTokenInfo({ walletAddress: tokenAddress })

  return (

    <Row className="mt-3">
      <Col sm='12' md='6' lg='4'>
        <CommonUserStatWidget
          icon={<Pocket size={21} />}
          color='primary'
          statTitle='Token Balance'
          value={tokenHoldings}
          isLoading={isLoading}
          symbol="EARN"
          className={'common-user-widget'} />
      </Col>
      <Col sm='12' md='6' lg='4'>
        <CommonUserStatWidget
          icon={<Layers size={21} />}
          color='primary'
          isLoading={isLoading}
          statTitle='Total Rewards So Far'
          value={tokenRewardsAmount}
          symbol="USDC"
          className={'common-user-widget'} />
      </Col>
      <Col sm='12' md='6' lg='4'>
        <CommonUserStatWidget
          icon={<Clock size={21} />}
          color='primary'
          isLoading={isLoading}
          statTitle='Last Payout Time'
          value={tokenRewardsLastPayoutTime}
          symbol=""
          className={'common-user-widget'} />
      </Col>
    </Row>
  )
}
