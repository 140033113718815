/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { DateTime } from "luxon"
import * as multiChainService from '../../blockchain/services/multichain.service'

export const useUserTokenInfo = (props) => {

  const { walletAddress } = props
  const [tokenHoldings, setTokenHolding] = useState(0.0)
  const [tokenHoldingValueInUSD, setTokenHoldingValueInUSD] = useState(0.0)
  const [busdHoldings, setBusdHoldings] = useState(0.0)
  const [busdEarning, setBusdEarning] = useState(0.0)
  const [tokenRewardsAmount, setTokenRewardsAmount] = useState(0.0)
  const [tokenRewardsLastPayoutTime, setTokenRewardsLastPayoutTime] = useState('')
  const [claimableRewardsAmount, setClaimableRewardsAmount] = useState(0.0)
  const [claimableRewardsAmountInUSD, setClaimableRewardsAmountInUSD] = useState(0.0)
  const [isLoading, setLoading] = useState(false)

  const currentChain = localStorage.getItem('ever-earn-chain')
    ? localStorage.getItem('ever-earn-chain') : "bsc"

  useEffect(() => {
    async function fetchData() {
      try {
        if (walletAddress) {
          setLoading(true)


          const userTokenBalance = await multiChainService.getTokenBalanceByUserWalletAddress(currentChain, walletAddress)
          setTokenHolding(userTokenBalance)

          //get user token holdings by address
          const tokenPrice = await multiChainService.getTokenPriceInUSDByTokenAddress(currentChain)
          const userTokenValueInUSD = tokenPrice * userTokenBalance
          setTokenHoldingValueInUSD(userTokenValueInUSD)


          const dividendEarning = await multiChainService.getUserDividendsEarningByWalletAddress(currentChain, walletAddress)
          setBusdEarning(dividendEarning)

          const unpaidEarning = await multiChainService.getUserUnpaidDividendsEarningByWalletAddress(currentChain, walletAddress)
          setClaimableRewardsAmount(unpaidEarning)
          //since usd price of BUSD ~1 
          setClaimableRewardsAmountInUSD(unpaidEarning)

          //get user token holdings by address
          const { totalPaidAmount, lastPaidAtTimestamp } = await multiChainService.getTokenDividendInfoByUserWalletAddress(currentChain, walletAddress)
          if (lastPaidAtTimestamp) {
            const dateTime = DateTime.fromSeconds(lastPaidAtTimestamp)
            const formattedTime = dateTime.toFormat('yyyy LLL dd - h:mm a')
            console.log('lastPaidAtTimestamp', lastPaidAtTimestamp);
            setTokenRewardsLastPayoutTime(formattedTime)
          } else {
            setTokenRewardsLastPayoutTime('N/A')
          }
          setTokenRewardsAmount(totalPaidAmount)
          setLoading(false)
        }

      } catch (error) {
        setTokenHolding(0.0)
        setBusdEarning(0.0)
        setBusdHoldings(0.0)
        setTokenHoldingValueInUSD(0.0)
        setClaimableRewardsAmount(0.0)
        setClaimableRewardsAmountInUSD(0.0)
        setLoading(false)
        console.error('error while fetching user token info ', error.message || '')
      }
    }
    fetchData();

  }, [walletAddress, currentChain])


  return {
    tokenHoldings,
    tokenHoldingValueInUSD,
    busdHoldings,
    busdEarning,
    tokenRewardsAmount,
    tokenRewardsLastPayoutTime,
    claimableRewardsAmount,
    claimableRewardsAmountInUSD,
    isLoading
  }
}

export default useUserTokenInfo

